.video-js * {
  font-family: "Flat-UI-Pro-Icons";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-button > .vjs-icon-placeholder:before, .video-js .vjs-modal-dialog, .vjs-modal-dialog .vjs-modal-dialog-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-button > .vjs-icon-placeholder:before {
  text-align: center;
}

.vjs-icon-play:before {
  content: "\e616";
  color: $brand-secondary;
  font-size: 16px;
}

.video-js {
  .vjs-big-play-button .vjs-icon-placeholder:before,
  .vjs-play-control .vjs-icon-placeholder:before {
    content: "\e616";
    color: $brand-secondary;
    font-size: 16px;
    line-height: 47px;
  }
}

.vjs-icon-play-circle {
  &:before {
    content: "\f102";
  }
}

.vjs-icon-pause:before, .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  content: "\e615";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-volume-mute:before, .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  content: "\e618";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-volume-low:before, .video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
  content: "\f105";
}

.vjs-icon-volume-mid:before, .video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
  content: "\f106";
}

.vjs-icon-volume-high:before, .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  content: "\e617";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\e619";
  color: #475d72;
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-fullscreen-exit:before, .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\e619";
  color: #475d72;
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-square {
  &:before {
    content: "\f10a";
  }
}

.vjs-icon-spinner {
  &:before {
    content: "\f10b";
  }
}

.vjs-icon-subtitles:before {
  content: "\f10c";
}

.vjs-icon-captions:before {
  content: "\f10d";
}

.video-js {
  .vjs-captions-button .vjs-icon-placeholder:before, &:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder:before, &:lang(fr-CA) .vjs-subs-caps-button .vjs-icon-placeholder:before {
    content: "\f10d";
  }
}

.vjs-icon-chapters:before, .video-js .vjs-chapters-button .vjs-icon-placeholder:before {
  content: "\f10e";
}

.vjs-icon-share {
  &:before {
    content: "\f10f";
  }
}

.vjs-icon-cog {
  &:before {
    content: "\f110";
  }
}

.vjs-icon-circle:before {
  content: "\f111";
}

.video-js {
  .vjs-play-progress:before, .vjs-volume-level:before {
    content: "";
    background-color: #16a085;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

.vjs-icon-circle-outline {
  &:before {
    content: "\f112";
  }
}

.vjs-icon-circle-inner-circle {
  &:before {
    content: "\f113";
  }
}

.vjs-icon-hd {
  &:before {
    content: "\f114";
  }
}

.vjs-icon-cancel:before, .video-js .vjs-control.vjs-close-button .vjs-icon-placeholder:before {
  content: "\f115";
}

.vjs-icon-replay:before, .video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
  content: "\f116";
}

.vjs-icon-facebook {
  &:before {
    content: "\f117";
  }
}

.vjs-icon-gplus {
  &:before {
    content: "\f118";
  }
}

.vjs-icon-linkedin {
  &:before {
    content: "\f119";
  }
}

.vjs-icon-twitter {
  &:before {
    content: "\f11a";
  }
}

.vjs-icon-tumblr {
  &:before {
    content: "\f11b";
  }
}

.vjs-icon-pinterest {
  &:before {
    content: "\f11c";
  }
}

.vjs-icon-audio-description:before, .video-js .vjs-descriptions-button .vjs-icon-placeholder:before {
  content: "\f11d";
}

.vjs-icon-audio:before {
  content: "\f11e";
}

.video-js {
  .vjs-audio-button .vjs-icon-placeholder:before {
    content: "\f11e";
  }
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  &:-moz-full-screen {
    position: absolute;
  }
  &:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
  }
  &[tabindex="-1"] {
    outline: none;
  }
  * {
    box-sizing: inherit;
    &:before, &:after {
      box-sizing: inherit;
    }
  }
  ul {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    list-style-position: outside;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  &.vjs-fluid, &.vjs-16-9, &.vjs-4-3 {
    width: 100%;
    max-width: 100%;
    height: 0;
  }
  &.vjs-16-9 {
    padding-top: 56.25%;
  }
  &.vjs-4-3 {
    padding-top: 75%;
  }
  &.vjs-fill {
    width: 100%;
    height: 100%;
  }
  .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

body.vjs-full-window {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

.vjs-full-window .video-js.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.video-js.vjs-fullscreen {
  width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
  &.vjs-user-inactive {
    cursor: none;
  }
}

.vjs-hidden {
  display: none !important;
}

.vjs-disabled {
  opacity: 0.5;
  cursor: default;
}

.video-js .vjs-offscreen {
  height: 1px;
  left: -9999px;
  position: absolute;
  top: 0;
  width: 1px;
}

.vjs-lock-showing {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.vjs-no-js {
  padding: 20px;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 300px;
  height: 150px;
  margin: 0px auto;
  a {
    color: #66A8CC;
    &:visited {
      color: #66A8CC;
    }
  }
}

.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -1.5em;
}

.video-js {
  &:hover .vjs-big-play-button, .vjs-big-play-button:focus {
    border-color: #fff;
    background-color: #73859f;
    background-color: rgba(115, 133, 159, 0.5);
    transition: all 0s;
  }
}

.vjs-controls-disabled .vjs-big-play-button, .vjs-has-started .vjs-big-play-button, .vjs-using-native-controls .vjs-big-play-button, .vjs-error .vjs-big-play-button {
  display: none;
}

.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause .vjs-big-play-button {
  display: block;
}

.video-js button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  overflow: visible;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  appearance: none;
}

.vjs-control .vjs-button {
  width: 100%;
  height: 100%;
}

.video-js {
  .vjs-control.vjs-close-button {
    cursor: pointer;
    height: 3em;
    position: absolute;
    right: 0;
    top: 0.5em;
    z-index: 2;
  }
  .vjs-modal-dialog {
    background: rgba(0, 0, 0, 0.8);
    background: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
    overflow: auto;
    box-sizing: content-box;
    > * {
      box-sizing: border-box;
    }
  }
}

.vjs-modal-dialog .vjs-modal-dialog-content {
  font-size: 1.2em;
  line-height: 1.5;
  padding: 20px 24px;
  z-index: 1;
}

.vjs-menu-button {
  cursor: pointer;
  &.vjs-disabled {
    cursor: default;
  }
}

.vjs-workinghover .vjs-menu-button.vjs-disabled:hover .vjs-menu {
  display: none;
}

.vjs-menu .vjs-menu-content {
  display: block;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
  box-sizing: content-box;
  > * {
    box-sizing: border-box;
  }
}

.vjs-scrubbing .vjs-menu-button:hover .vjs-menu {
  display: none;
}

.vjs-menu li {
  list-style: none;
  margin: 0;
  padding: 0.2em 0;
  line-height: 1.4em;
  font-size: 1.2em;
  text-align: center;
  text-transform: lowercase;
  &.vjs-menu-item {
    &:focus, &:hover {
      background-color: #73859f;
      background-color: rgba(115, 133, 159, 0.5);
    }
  }
  &.vjs-selected {
    background-color: #fff;
    color: #2B333F;
    &:focus, &:hover {
      background-color: #fff;
      color: #2B333F;
    }
  }
  &.vjs-menu-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 2em;
    padding: 0;
    margin: 0 0 0.3em 0;
    font-weight: bold;
    cursor: default;
  }
}

.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0em;
  margin-bottom: 1.5em;
  border-top-color: rgba(43, 51, 63, 0.7);
  .vjs-menu-content {
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    position: absolute;
    width: 100%;
    bottom: 1.5em;
    max-height: 15em;
  }
}

.vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu, .vjs-menu-button-popup .vjs-menu.vjs-lock-showing {
  display: block;
}

.video-js {
  .vjs-menu-button-inline {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    overflow: hidden;
    &:before {
      width: 2.222222222em;
    }
    &:hover, &:focus, &.vjs-slider-active {
      width: 12em;
    }
  }
  &.vjs-no-flex .vjs-menu-button-inline {
    width: 12em;
  }
}

.vjs-menu-button-inline {
  .vjs-menu {
    opacity: 0;
    height: 100%;
    width: auto;
    position: absolute;
    left: 4em;
    top: 0;
    padding: 0;
    margin: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }
  &:hover .vjs-menu, &:focus .vjs-menu, &.vjs-slider-active .vjs-menu {
    display: block;
    opacity: 1;
  }
}

.vjs-no-flex .vjs-menu-button-inline {
  .vjs-menu {
    display: block;
    opacity: 1;
    position: relative;
    width: auto;
  }
  &:hover .vjs-menu, &:focus .vjs-menu, &.vjs-slider-active .vjs-menu {
    width: auto;
  }
}

.vjs-menu-button-inline .vjs-menu-content {
  width: auto;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 47px;
  color: #ffffff;
  background: #2c3e50;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.vjs-has-started {
  .vjs-control-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.1s, opacity 0.1s;
    -moz-transition: visibility 0.1s, opacity 0.1s;
    -ms-transition: visibility 0.1s, opacity 0.1s;
    -o-transition: visibility 0.1s, opacity 0.1s;
    transition: visibility 0.1s, opacity 0.1s;
  }
  &.vjs-user-inactive.vjs-playing .vjs-control-bar {
    visibility: visible;
    opacity: 0;
    -webkit-transition: visibility 1s, opacity 1s;
    -moz-transition: visibility 1s, opacity 1s;
    -ms-transition: visibility 1s, opacity 1s;
    -o-transition: visibility 1s, opacity 1s;
    transition: visibility 1s, opacity 1s;
  }
}

.vjs-controls-disabled .vjs-control-bar, .vjs-using-native-controls .vjs-control-bar, .vjs-error .vjs-control-bar {
  display: none !important;
}

.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible;
}

.vjs-has-started.vjs-no-flex .vjs-control-bar {
  display: table;
}

.video-js .vjs-control {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 18px;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 1.8em;
  line-height: 1.67;
}

.video-js {
  .vjs-control {
    &:focus:before, &:hover:before, &:focus {
      text-shadow: 0em 0em 1em white;
    }
  }
  .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.vjs-no-flex .vjs-control {
  display: table-cell;
  vertical-align: middle;
}

.video-js {
  .vjs-custom-control-spacer {
    display: none;
  }
  .vjs-progress-control {
    cursor: pointer;
    -webkit-box-flex: auto;
    -moz-box-flex: auto;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 4em;
  }
}

.vjs-live .vjs-progress-control {
  display: none;
}

.vjs-no-flex .vjs-progress-control {
  width: auto;
}

.video-js {
  .vjs-progress-holder {
    flex: auto;
    transition: all 0.2s;
    height: 12px;
  }
  .vjs-progress-control {
    .vjs-progress-holder {
      margin: 0 10px;
    }
  }
  .vjs-progress-holder {
    .vjs-play-progress {
      position: absolute;
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      width: 0;
      left: 0;
      top: 0;
    }
    .vjs-load-progress {
      position: absolute;
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      width: 0;
      left: 0;
      top: 0;
      div {
        position: absolute;
        display: block;
        height: 100%;
        margin: 0;
        padding: 0;
        width: 0;
        left: 0;
        top: 0;
      }
    }
  }
  .vjs-play-progress {
    background: $brand-secondary;
    border-radius: 32px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    &:before {
      font-size: 0.9em;
      position: absolute;
      top: -0.333333333333333em;
      z-index: 1;
    }
  }
  .vjs-load-progress {
    background: #d6dbdf;
    border-radius: 32px;
    div {
      background: #d6dbdf;
      border-radius: 32px;
    }
  }
  .vjs-time-tooltip {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.3em;
    color: #000;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: 6px 8px 8px 8px;
    pointer-events: none;
    position: relative;
    top: -3.4em;
    visibility: hidden;
    z-index: 1;
  }
  .vjs-progress-holder:focus .vjs-time-tooltip {
    display: none;
  }
  .vjs-progress-control {
    &:hover {
      .vjs-time-tooltip, .vjs-progress-holder:focus .vjs-time-tooltip {
        display: block;
        font-size: 0.6em;
        visibility: visible;
      }
    }
    .vjs-mouse-display {
      display: none;
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: #000;
      z-index: 1;
    }
  }
}

.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
  z-index: 0;
}

.video-js {
  .vjs-progress-control:hover .vjs-mouse-display {
    display: block;
  }
  &.vjs-user-inactive {
    .vjs-progress-control .vjs-mouse-display {
      visibility: hidden;
      opacity: 0;
      -webkit-transition: visibility 1s, opacity 1s;
      -moz-transition: visibility 1s, opacity 1s;
      -ms-transition: visibility 1s, opacity 1s;
      -o-transition: visibility 1s, opacity 1s;
      transition: visibility 1s, opacity 1s;
    }
    &.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
      display: none;
    }
  }
}

.vjs-mouse-display .vjs-time-tooltip {
  color: #fff;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
}

.video-js {
  .vjs-slider {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0 0.45em 0 0.45em;
    user-select: none;
    background-color: #425669;
    border-radius: 32px;
    &:focus {
      text-shadow: 0em 0em 1em white;
      box-shadow: 0 0 1em #fff;
    }
  }
  .vjs-mute-control {
    cursor: pointer;
    flex: none;
    height: 47px;
    color: #475d72;
  }
  .vjs-volume-control {
    cursor: pointer;
    margin-right: 1em;
    display: flex;
    &.vjs-volume-horizontal {
      width: 5em;
    }
  }
  .vjs-volume-panel .vjs-volume-control {
    display: none;
  }
}

.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  .vjs-volume-bar, .vjs-volume-level {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.video-js {
  .vjs-volume-panel {
    transition: width 1s;
    &:hover .vjs-volume-control, &:active .vjs-volume-control, &:focus .vjs-volume-control {
      visibility: visible;
      opacity: 1;
      position: relative;
      -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
    }
    .vjs-volume-control {
      &:hover, &:active, &:focus {
        visibility: visible;
        opacity: 1;
        position: relative;
        -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      }
    }
    .vjs-mute-control {
      &:hover ~ .vjs-volume-control, &:active ~ .vjs-volume-control, &:focus ~ .vjs-volume-control {
        visibility: visible;
        opacity: 1;
        position: relative;
        -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      }
    }
    .vjs-volume-control.vjs-slider-active {
      visibility: visible;
      opacity: 1;
      position: relative;
      -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
      transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
    }
    &:hover .vjs-volume-control.vjs-volume-horizontal, &:active .vjs-volume-control.vjs-volume-horizontal, &:focus .vjs-volume-control.vjs-volume-horizontal {
      width: 5em;
      height: 47px;
    }
    .vjs-volume-control {
      &:hover.vjs-volume-horizontal, &:active.vjs-volume-horizontal, &:focus.vjs-volume-horizontal {
        width: 5em;
        height: 47px;
      }
    }
    .vjs-mute-control {
      &:hover ~ .vjs-volume-control.vjs-volume-horizontal, &:active ~ .vjs-volume-control.vjs-volume-horizontal, &:focus ~ .vjs-volume-control.vjs-volume-horizontal {
        width: 5em;
        height: 47px;
      }
    }
    .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
      width: 5em;
      height: 47px;
    }
    &:hover .vjs-volume-control.vjs-volume-vertical, &:active .vjs-volume-control.vjs-volume-vertical, &:focus .vjs-volume-control.vjs-volume-vertical {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    .vjs-volume-control {
      &:hover.vjs-volume-vertical, &:active.vjs-volume-vertical, &:focus.vjs-volume-vertical {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    .vjs-mute-control {
      &:hover ~ .vjs-volume-control.vjs-volume-vertical, &:active ~ .vjs-volume-control.vjs-volume-vertical, &:focus ~ .vjs-volume-control.vjs-volume-vertical {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    &:hover .vjs-volume-control.vjs-volume-vertical {
      .vjs-volume-bar, .vjs-volume-level {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    &:active .vjs-volume-control.vjs-volume-vertical {
      .vjs-volume-bar, .vjs-volume-level {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    &:focus .vjs-volume-control.vjs-volume-vertical {
      .vjs-volume-bar, .vjs-volume-level {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    .vjs-volume-control {
      &:hover.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
      &:active.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
      &:focus.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
    }
    .vjs-mute-control {
      &:hover ~ .vjs-volume-control.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
      &:active ~ .vjs-volume-control.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
      &:focus ~ .vjs-volume-control.vjs-volume-vertical {
        .vjs-volume-bar, .vjs-volume-level {
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        }
      }
    }
    .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
      .vjs-volume-bar, .vjs-volume-level {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
    }
    // &.vjs-volume-panel-horizontal {
    //   &:hover, &:focus, &:active, &.vjs-slider-active {
    //     width: 9em;
    //     -webkit-transition: width 0.1s;
    //     -moz-transition: width 0.1s;
    //     -ms-transition: width 0.1s;
    //     -o-transition: width 0.1s;
    //     transition: width 0.1s;
    //   }
    // }
    .vjs-volume-control {
      &.vjs-volume-vertical {
        height: 8em;
        width: 3em;
        left: -3.5em;
        -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
        -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
        -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
        -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
        transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
      }
      &.vjs-volume-horizontal {
        -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
        -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
        -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
        -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
        transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
      }
    }
  }
  &.vjs-no-flex {
    .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
      width: 5em;
      height: 3em;
      visibility: visible;
      opacity: 1;
      position: relative;
      transition: none;
    }
    .vjs-volume-control.vjs-volume-vertical, .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
      position: absolute;
      bottom: 3em;
      left: 0.5em;
    }
  }
  .vjs-volume-panel {
    display: flex;
    order: 2;
  }
  .vjs-volume-bar {
    margin: 1.35em 0.45em;
  }
}

.vjs-volume-bar {
  &.vjs-slider-horizontal {
    width: 5em;
    height: 0.3em;
    position: absolute;
    top: 50%;
    margin: 0;
    transform: translatey(-50%);
  }
  &.vjs-slider-vertical {
    width: 0.3em;
    height: 5em;
    margin: 1.35em auto;
  }
}

.video-js .vjs-volume-level {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  &:before {
    position: absolute;
    font-size: 0.9em;
  }
}

.vjs-slider-vertical .vjs-volume-level {
  width: 0.3em;
  &:before {
    top: -0.5em;
    left: -0.3em;
  }
}

.vjs-slider-horizontal .vjs-volume-level {
  height: 0.3em;
  &:before {
    top: 50%;
    right: -0.5em;
    transform: translatey(-50%);
  }
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: 4em;
}

.vjs-volume-bar {
  &.vjs-slider-vertical .vjs-volume-level {
    height: 100%;
  }
  &.vjs-slider-horizontal .vjs-volume-level {
    width: 100%;
  }
}

.video-js {
  .vjs-volume-vertical {
    width: 3em;
    height: 8em;
    bottom: 8em;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
  }
  .vjs-volume-horizontal .vjs-menu {
    left: -2em;
  }
}

.vjs-poster {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  img {
    display: block;
    vertical-align: middle;
    margin: 0 auto;
    max-height: 100%;
    padding: 0;
    width: 100%;
  }
}

.vjs-has-started .vjs-poster {
  display: none;
}

.vjs-audio.vjs-has-started .vjs-poster {
  display: block;
}

.vjs-using-native-controls .vjs-poster {
  display: none;
}

.video-js .vjs-live-control {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 1em;
  line-height: 3em;
}

.vjs-no-flex .vjs-live-control {
  display: table-cell;
  width: auto;
  text-align: left;
}

.video-js .vjs-time-control {
  flex: none;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;
  order: 1;

  * {
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 47px;
  }
}

.vjs-live .vjs-time-control, .video-js .vjs-remaining-time {
  display: none;
}

.vjs-no-flex {
  .vjs-current-time {
    display: none;
  }
  .vjs-remaining-time.vjs-time-control.vjs-control {
    width: 0px !important;
    white-space: nowrap;
  }
}

.video-js {
  .vjs-time-divider, .vjs-duration {
    color: #5d6d7e;
  }

  .vjs-duration {
    margin-right: 20px;
  }
}

.vjs-live .vjs-time-divider {
  display: none;
}

.video-js .vjs-play-control {
  display: block;
  height: 47px;
  width: 58px;

  .vjs-icon-placeholder {
    cursor: pointer;
    flex: none;
  }
}

.vjs-text-track-display {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
}

.video-js {
  &.vjs-user-inactive.vjs-playing .vjs-text-track-display {
    bottom: 1em;
  }
  .vjs-text-track {
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 0.1em;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.vjs-subtitles {
  color: #fff;
}

.vjs-captions {
  color: #fc6;
}

.vjs-tt-cue {
  display: block;
}

video::-webkit-media-text-track-display {
  transform: translateY(-3em);
}

.video-js {
  &.vjs-user-inactive.vjs-playing video::-webkit-media-text-track-display {
    transform: translateY(-1.5em);
  }
  .vjs-fullscreen-control {
    cursor: pointer;
    flex: none;
    order: 2;
    width: 48px;
  }
}

.vjs-playback-rate {
  > .vjs-menu-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .vjs-playback-rate-value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    font-size: 1.5em;
    line-height: 2;
    text-align: center;
  }
  .vjs-menu {
    width: 4em;
    left: 0em;
  }
}

.vjs-error .vjs-error-display {
  .vjs-modal-dialog-content {
    font-size: 1.4em;
    text-align: center;
  }
  &:before {
    color: #fff;
    content: 'X';
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4em;
    left: 0;
    line-height: 1;
    margin-top: -0.5em;
    position: absolute;
    text-shadow: 0.05em 0.05em 0.1em #000;
    text-align: center;
    top: 50%;
    vertical-align: middle;
    width: 100%;
  }
}

.vjs-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid rgba(43, 51, 63, 0.7);
  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.vjs-seeking .vjs-loading-spinner, .vjs-waiting .vjs-loading-spinner {
  display: block;
}

.vjs-loading-spinner {
  &:before, &:after {
    content: "";
    position: absolute;
    margin: -6px;
    box-sizing: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    opacity: 1;
    border: inherit;
    border-color: transparent;
    border-top-color: white;
  }
}

.vjs-seeking .vjs-loading-spinner {
  &:before, &:after {
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
  }
}

.vjs-waiting .vjs-loading-spinner {
  &:before, &:after {
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
  }
}

.vjs-seeking .vjs-loading-spinner:before, .vjs-waiting .vjs-loading-spinner:before {
  border-top-color: white;
}

.vjs-seeking .vjs-loading-spinner:after, .vjs-waiting .vjs-loading-spinner:after {
  border-top-color: white;
  -webkit-animation-delay: 0.44s;
  animation-delay: 0.44s;
}

@keyframes vjs-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes vjs-spinner-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}


@keyframes vjs-spinner-fade {
  0% {
    border-top-color: #73859f;
  }

  20% {
    border-top-color: #73859f;
  }

  35% {
    border-top-color: white;
  }

  60% {
    border-top-color: #73859f;
  }

  100% {
    border-top-color: #73859f;
  }
}


@-webkit-keyframes vjs-spinner-fade {
  0% {
    border-top-color: #73859f;
  }

  20% {
    border-top-color: #73859f;
  }

  35% {
    border-top-color: white;
  }

  60% {
    border-top-color: #73859f;
  }

  100% {
    border-top-color: #73859f;
  }
}


.vjs-chapters-button .vjs-menu ul {
  width: 24em;
}

.video-js {
  .vjs-subs-caps-button + .vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
    position: absolute;
    &:before {
      content: "\f10d";
      font-size: 1.5em;
      line-height: inherit;
    }
  }
  &.vjs-layout-tiny:not(.vjs-fullscreen) {
    .vjs-custom-control-spacer {
      -webkit-box-flex: auto;
      -moz-box-flex: auto;
      -webkit-flex: auto;
      -ms-flex: auto;
      flex: auto;
    }
    &.vjs-no-flex .vjs-custom-control-spacer {
      width: auto;
    }
    .vjs-current-time, .vjs-time-divider, .vjs-duration, .vjs-remaining-time, .vjs-playback-rate, .vjs-progress-control, .vjs-mute-control, .vjs-volume-control, .vjs-chapters-button, .vjs-descriptions-button, .vjs-captions-button, .vjs-subtitles-button, .vjs-audio-button {
      display: none;
    }
  }
  &.vjs-layout-x-small:not(.vjs-fullscreen) {
    .vjs-current-time, .vjs-time-divider, .vjs-duration, .vjs-remaining-time, .vjs-playback-rate, .vjs-mute-control, .vjs-volume-control, .vjs-chapters-button, .vjs-descriptions-button, .vjs-captions-button, .vjs-subtitles-button, .vjs-audio-button {
      display: none;
    }
  }
  &.vjs-layout-small:not(.vjs-fullscreen) {
    .vjs-current-time, .vjs-time-divider, .vjs-duration, .vjs-remaining-time, .vjs-playback-rate, .vjs-mute-control, .vjs-volume-control, .vjs-chapters-button, .vjs-descriptions-button, .vjs-captions-button, .vjs-subtitles-button .vjs-audio-button {
      display: none;
    }
  }
}

.vjs-modal-dialog.vjs-text-track-settings {
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.75);
  color: #fff;
  height: 70%;
}

.vjs-text-track-settings {
  .vjs-modal-dialog-content {
    display: table;
  }
  .vjs-track-settings-colors, .vjs-track-settings-font {
    display: table-cell;
  }
  .vjs-track-settings-controls {
    display: table-cell;
    text-align: right;
    vertical-align: bottom;
  }
  fieldset {
    margin: 5px;
    padding: 3px;
    border: none;
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
  legend {
    color: #fff;
    margin: 0 0 5px 0;
  }
  .vjs-label {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    margin: 0 0 5px 0;
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

.vjs-track-settings-controls {
  button {
    &:focus, &:active {
      outline-style: solid;
      outline-width: medium;
      background-image: linear-gradient(0deg, #fff 88%, #73859f 100%);
    }
    &:hover {
      color: rgba(43, 51, 63, 0.75);
    }
    background-color: #fff;
    background-image: linear-gradient(-180deg, #fff 88%, #73859f 100%);
    color: #2B333F;
    cursor: pointer;
    border-radius: 2px;
  }
  .vjs-default-button {
    margin-right: 1em;
  }
}

@media print {
  .video-js > *:not(.vjs-tech):not(.vjs-poster) {
    visibility: hidden;
  }
}

@media \0screen {
  .vjs-user-inactive.vjs-playing .vjs-control-bar :before {
    content: "";
  }
}

@media \0screen {
  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    visibility: hidden;
  }
}
