//
// Nav
// --------------------------------------------------


// NAV PILLS
// -----------
.nav-pills {
  > li {
    &:first-child {
      > a {
        border-left: none;
        border-radius: $nav-border-radius 0 0 $nav-border-radius;
      }
    }
    &:last-child {
      > a {
        border-radius: 0 $nav-border-radius $nav-border-radius 0;
      }
    }
    &.active,
    &.show {
      > a,
      > a:hover {
        background-color: $btn-primary-active-bg;
      }
    }

    // Open dropdowns
    &.show > a {
      &,
      &:hover,
      &:focus {
        background-color: $btn-primary-active-bg;
        border-color: $btn-primary-active-bg;
      }
    }

    @media (max-width: $screen-xs-max) {
      float: none;
      display: block;

      &:first-child > a { border-radius: $nav-border-radius $nav-border-radius 0 0; }
      &:last-child > a  { border-radius: 0 0 $nav-border-radius $nav-border-radius; }
    }

    > a {
      background-color: $brand-secondary;
      border-left: 2px solid $btn-primary-active-bg;
      color: $inverse;
      font-size: floor(($component-font-size-base * 1.067)); // ~16px
      font-weight: 700;
      line-height: 1;
      margin: 0;
      outline: none;
      padding: 12px 21px 13px;
      border-radius: 0;
      display: block;

      @media (max-width: $screen-xs-max) {
        border-left: none;
        display: block;
      }

      &:hover,
      &:focus {
        background-color: $btn-primary-hover-bg;
      }
      &:active {
        background-color: $btn-primary-active-bg;
      }

      > .badge {
        margin: -4px 0 -4px 3px;
        position: relative;
        top: -1px;
        background-color: $inverse;
      }
    }
  }
  > li + li {
    margin-left: 0;
  }
}

// Stacked pills
.nav-stacked {
  > li,
  > li:first-child,
  > li:last-child {
    > a {
      border-left: none;
      border-radius: $nav-border-radius;

      > .badge {
        top: 2px;
      }
    }
  }
}

// NAV TABS
// -----------
.nav-tabs {
  border-bottom: none;

  // List item
  > li {
    margin-bottom: -$nav-tabs-border-width;

    > li:first-child {
      a.active:before {
        display: block;
        background-color: $inverse;
        border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
        content: "";
        height: $nav-border-radius;
        left: -2px;
        position: absolute;
        bottom: ($nav-tabs-border-width - $nav-border-radius);
        width: $nav-border-radius;
        z-index: 2;
      }

      // Dropdown
      &.dropdown {
        &.active {
          &,
          &.dropdown-toggle,
          &:hover {
            color: $nav-tabs-active-color;

            &:after {
              border-bottom-color: $nav-tabs-active-color;
              border-top-color: $nav-tabs-active-color;
            }
          }
        }
      }

    }

    // General link styling
    > a {
      font-size: ceil(($component-font-size-base * 0.933)); // ~14px
      font-weight: 700;
      margin-bottom: 0;
      padding: 7px 21px 8px;
      color: $nav-tabs-color;
      border: 2px solid transparent;
      border-radius: $nav-border-radius $nav-border-radius 0 0;
      transition: none;
      line-height: 1.42857143;
      position: relative;
      display: block;

      &:hover,
      &:focus {
        color: $nav-tabs-hover-color;
      }

      // Active link
      &.active {
        z-index: 2;

        &,
        &:hover,
        &:focus {
          background-color: inherit;
          border-color: $nav-tabs-border-color;
          border-bottom-color: $inverse;
          border-width: $nav-tabs-border-width;
          color: $nav-tabs-active-color;
        }
        &:before { display: none; }

        &.dropdown-toggle,
        &.dropdown-toggle:hover,
        &.dropdown-toggle:focus {
          &:after {
            border-bottom-color: $nav-tabs-active-color;
            border-top-color: $nav-tabs-active-color;
          }
        }
      }
    }

    // Dropdown
    &.dropdown.show {

      // Dropdown is open
      //&.show,
      a.active {
        z-index: ($nav-open-zindex + 3);

        &.dropdown-toggle,
        &.dropdown-toggle:hover {
          background-color: $dropdown-bg;
          border-color: $nav-tabs-border-color;
          color: $nav-tabs-active-color;

          @media (min-width: $screen-sm-min) {
            border-bottom-color: transparent;
          }

          &:after {
            border-bottom-color: $nav-tabs-active-color;
            border-top-color: $nav-tabs-active-color;
          }
        }
      }

      .dropdown-menu {
        border: $nav-tabs-border-width solid $nav-tabs-border-color;
        border-radius: 0 $nav-border-radius $nav-border-radius $nav-border-radius;
        margin-top: -2px;
        z-index: ($nav-open-zindex + 2);

        li {
          &:first-child > a {
            border-radius: 0 $nav-item-border-radius 0 0;
          }
          &:last-child > a {
            border-radius: 0 0 $nav-item-border-radius $nav-item-border-radius;
          }
          a {
            border-radius: 0;
          }
        }
      }

    }

    .dropdown-toggle {
      position: relative;
      z-index: ($nav-open-zindex + 1);

      &:hover,
      &:focus {
        &:after {
          border-bottom-color: $nav-tabs-hover-color;
          border-top-color: $nav-tabs-hover-color;
        }
      }
    }

    // .show .dropdown-toggle {
    //   background-color: $dropdown-bg;
    // }
  }
}

// Tabs left
.tab-content.tab-side-content {
  border: none;
}

.nav-tabs {
  &.tabs-left,
  &.tabs-right {
    background: #f5f7f8;
    padding-top: 10px;
    padding-bottom: 10px;

    > li {
      float: none;

      > a {
        padding: 13px 14px;
        background-color: transparent;
      }
      &.active {
        &,
        &:hover,
        &:focus {
          background-color: #fff;
          border-top: $nav-tabs-border-width solid $nav-side-tabs-border;
          border-bottom: $nav-tabs-border-width solid $nav-side-tabs-border;
        }
      }
    }
  }
  &.tabs-left {
    padding-left: 15px;
    border-right: $nav-tabs-border-width solid $nav-side-tabs-border;

    > li {
      > a {
        margin-right: -$nav-tabs-border-width;
      }
      &.active {
        &,
        &:hover,
        &:focus {
          border-radius: $nav-border-radius 0 0 $nav-border-radius;
          border-left: $nav-tabs-border-width solid $nav-side-tabs-border;
          border-right: none;
        }
      }
    }
  }
  &.tabs-right {
    padding-right: 15px;
    border-left: $nav-tabs-border-width solid $nav-side-tabs-border;

    > li {
      > a {
        margin-left: -$nav-tabs-border-width;
      }
      &.active {
        &,
        &:hover,
        &:focus {
          border-radius: 0 $nav-border-radius $nav-border-radius 0;
          border-right: $nav-tabs-border-width solid $nav-side-tabs-border;
          border-left: none;
        }
      }
    }
  }
}

// Tab content
.tab-content {
  border: 2px solid #ddd;
  border-radius: $nav-border-radius;
  margin-bottom: 20px;
  padding: 20px 20px 5px;
  position: relative;
  z-index: 1;
}

// NAV LIST
// -----------
.nav.flex-column {
  padding-left: $nav-list-gap;
  padding-right: $nav-list-gap;

  .nav-header {
    margin-left: -$nav-list-gap;
    margin-right: -$nav-list-gap;
    padding: 0 $nav-list-gap;
    font-weight: 700;
    line-height: 1.538; // ~20px
  }

  > li {
    margin-bottom: 3px;

    &.active {
      > a,
      > a:hover,
      > a:focus {
        background-color: $nav-list-bg;
        color: $nav-list-color;
      }
    }

    &.disabled {
      > a,
      > a:hover,
      > a:focus {
        opacity:0.6;
        cursor: $cursor-disabled;
      }
    }

    &.divider {
      height: 2px;
      border-bottom: none;
      margin: 13px (-$nav-list-gap) 17px 0;
      background-color: $nav-list-bg;
    }

    > a {
      border-radius: $nav-item-border-radius;
      font-size: $component-font-size-base;
      line-height: 1.333; // ~20px
      font-weight: 700;
      margin-left: -$nav-list-gap;
      margin-right: -$nav-list-gap;
      padding: 6px $nav-list-gap 8px;
      text-shadow: none;
      display: block;
    }
  }

  .badge.pull-right {
    margin: 1px -4px -1px 0;
  }

  // Nav list with a background color
  &.nav-list-vivid {
    background-color: $nav-list-bg;
    border-radius: $nav-item-border-radius;
    padding-bottom: 2px;

    > li {
      margin-bottom: 2px;

      &.divider {
        background-color: transparent;
        margin: 3px 0 0;
      }
      &.active {
        > a,
        > a:hover,
        > a:focus {
          background-color: $nav-list-active-bg;
          color: $inverse;
        }
      }
      > a {
        border-radius: 0;
        color: $nav-list-color;
        font-size: ceil(($component-font-size-base * 0.933)); // ~14px

        &:hover,
        &:focus {
          color: $nav-list-hover-color;
        }
      }
    }

    .nav-header {
      &:first-child {
        padding-top: 10px;
      }
    }
  }

  &.nav-list-bricks {

    > li {
      margin-bottom: 7px;

      &.active {
        > a,
        > a:hover,
        > a:focus {
          background-color: $nav-list-active-bg;
          color: $nav-list-color-inverse;
        }
      }
      &.disabled {
        > a,
        > a:hover,
        > a:focus {
          background-color: $nav-list-bg;
        }
      }
      > a {
        padding: 8px 10px 8px 14px;
        border-radius: $nav-item-border-radius;
        background-color: $nav-list-bg;
        color: $nav-list-color;
        font-size: ceil(($component-font-size-base * 0.933)); // ~14px
        line-height: 1.333; // ~20px
        font-weight: 700;

        &:hover,
        &:focus {
          color: $nav-list-hover-color;
        }
        [class^="fui-"], [class*=" fui-"] {
          color:$nav-list-color-inverse;
        }
      }
    }
  }
}

.nav-header {
  color: $nav-list-header-color;
  font-size: floor(($component-font-size-base * 0.867)); // ~13px
  text-transform: uppercase;
}
