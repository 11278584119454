// Alerts variant
//

@mixin alert-variant($bg-color, $border-color, $link-color) {
	background-color: $bg-color;
	border-color: $border-color;

	.alert-link {
	  color: $link-color;
	}
}
