@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-black.eot");
  src: url("../fonts/lato/lato-black.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-black.woff") format("woff"), url("../fonts/lato/lato-black.ttf") format("truetype"), url("../fonts/lato/lato-black.svg#latoblack") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-bold.eot");
  src: url("../fonts/lato/lato-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-bold.woff") format("woff"), url("../fonts/lato/lato-bold.ttf") format("truetype"), url("../fonts/lato/lato-bold.svg#latobold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-bolditalic.eot");
  src: url("../fonts/lato/lato-bolditalic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-bolditalic.woff") format("woff"), url("../fonts/lato/lato-bolditalic.ttf") format("truetype"), url("../fonts/lato/lato-bolditalic.svg#latobold-italic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-italic.eot");
  src: url("../fonts/lato/lato-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-italic.woff") format("woff"), url("../fonts/lato/lato-italic.ttf") format("truetype"), url("../fonts/lato/lato-italic.svg#latoitalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-light.eot");
  src: url("../fonts/lato/lato-light.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-light.woff") format("woff"), url("../fonts/lato/lato-light.ttf") format("truetype"), url("../fonts/lato/lato-light.svg#latolight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/lato/lato-regular.eot");
  src: url("../fonts/lato/lato-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato/lato-regular.woff") format("woff"), url("../fonts/lato/lato-regular.ttf") format("truetype"), url("../fonts/lato/lato-regular.svg#latoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flat-UI-Pro-Icons';
  src: url("../fonts/glyphicons/flat-ui-pro-icons-regular.eot");
  src: url("../fonts/glyphicons/flat-ui-pro-icons-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons/flat-ui-pro-icons-regular.woff") format("woff"), url("../fonts/glyphicons/flat-ui-pro-icons-regular.ttf") format("truetype"), url("../fonts/glyphicons/flat-ui-pro-icons-regular.svg#flat-ui-pro-icons-regular") format("svg");
}

[class^="fui-"],
[class*="fui-"] {
  font-family: 'Flat-UI-Pro-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fui-triangle-up:before {
  content: "\e600";
}

.fui-triangle-down:before {
  content: "\e601";
}

.fui-triangle-up-small:before {
  content: "\e602";
}

.fui-triangle-down-small:before {
  content: "\e603";
}

.fui-triangle-left-large:before {
  content: "\e604";
}

.fui-triangle-right-large:before {
  content: "\e605";
}

.fui-arrow-left:before {
  content: "\e606";
}

.fui-arrow-right:before {
  content: "\e607";
}

.fui-plus:before {
  content: "\e608";
}

.fui-cross:before {
  content: "\e609";
}

.fui-check:before {
  content: "\e60a";
}

.fui-radio-unchecked:before {
  content: "\e60b";
}

.fui-radio-checked:before {
  content: "\e60c";
}

.fui-checkbox-unchecked:before {
  content: "\e60d";
}

.fui-checkbox-checked:before {
  content: "\e60e";
}

.fui-info-circle:before {
  content: "\e60f";
}

.fui-alert-circle:before {
  content: "\e610";
}

.fui-question-circle:before {
  content: "\e611";
}

.fui-check-circle:before {
  content: "\e612";
}

.fui-cross-circle:before {
  content: "\e613";
}

.fui-plus-circle:before {
  content: "\e614";
}

.fui-pause:before {
  content: "\e615";
}

.fui-play:before {
  content: "\e616";
}

.fui-volume:before {
  content: "\e617";
}

.fui-mute:before {
  content: "\e618";
}

.fui-resize:before {
  content: "\e619";
}

.fui-list:before {
  content: "\e61a";
}

.fui-list-thumbnailed:before {
  content: "\e61b";
}

.fui-list-small-thumbnails:before {
  content: "\e61c";
}

.fui-list-large-thumbnails:before {
  content: "\e61d";
}

.fui-list-numbered:before {
  content: "\e61e";
}

.fui-list-columned:before {
  content: "\e61f";
}

.fui-list-bulleted:before {
  content: "\e620";
}

.fui-window:before {
  content: "\e621";
}

.fui-windows:before {
  content: "\e622";
}

.fui-loop:before {
  content: "\e623";
}

.fui-cmd:before {
  content: "\e624";
}

.fui-mic:before {
  content: "\e625";
}

.fui-heart:before {
  content: "\e626";
}

.fui-location:before {
  content: "\e627";
}

.fui-new:before {
  content: "\e628";
}

.fui-video:before {
  content: "\e629";
}

.fui-photo:before {
  content: "\e62a";
}

.fui-time:before {
  content: "\e62b";
}

.fui-eye:before {
  content: "\e62c";
}

.fui-chat:before {
  content: "\e62d";
}

.fui-home:before {
  content: "\e62e";
}

.fui-upload:before {
  content: "\e62f";
}

.fui-search:before {
  content: "\e630";
}

.fui-user:before {
  content: "\e631";
}

.fui-mail:before {
  content: "\e632";
}

.fui-lock:before {
  content: "\e633";
}

.fui-power:before {
  content: "\e634";
}

.fui-calendar:before {
  content: "\e635";
}

.fui-gear:before {
  content: "\e636";
}

.fui-bookmark:before {
  content: "\e637";
}

.fui-exit:before {
  content: "\e638";
}

.fui-trash:before {
  content: "\e639";
}

.fui-folder:before {
  content: "\e63a";
}

.fui-bubble:before {
  content: "\e63b";
}

.fui-export:before {
  content: "\e63c";
}

.fui-calendar-solid:before {
  content: "\e63d";
}

.fui-star:before {
  content: "\e63e";
}

.fui-star-2:before {
  content: "\e63f";
}

.fui-credit-card:before {
  content: "\e640";
}

.fui-clip:before {
  content: "\e641";
}

.fui-link:before {
  content: "\e642";
}

.fui-tag:before {
  content: "\e643";
}

.fui-document:before {
  content: "\e644";
}

.fui-image:before {
  content: "\e645";
}

.fui-facebook:before {
  content: "\e646";
}

.fui-youtube:before {
  content: "\e647";
}

.fui-vimeo:before {
  content: "\e648";
}

.fui-twitter:before {
  content: "\e649";
}

.fui-spotify:before {
  content: "\e64a";
}

.fui-skype:before {
  content: "\e64b";
}

.fui-pinterest:before {
  content: "\e64c";
}

.fui-path:before {
  content: "\e64d";
}

.fui-linkedin:before {
  content: "\e64e";
}

.fui-google-plus:before {
  content: "\e64f";
}

.fui-dribbble:before {
  content: "\e650";
}

.fui-behance:before {
  content: "\e651";
}

.fui-stumbleupon:before {
  content: "\e652";
}

.fui-yelp:before {
  content: "\e653";
}

.fui-wordpress:before {
  content: "\e654";
}

.fui-windows-8:before {
  content: "\e655";
}

.fui-vine:before {
  content: "\e656";
}

.fui-tumblr:before {
  content: "\e657";
}

.fui-paypal:before {
  content: "\e658";
}

.fui-lastfm:before {
  content: "\e659";
}

.fui-instagram:before {
  content: "\e65a";
}

.fui-html5:before {
  content: "\e65b";
}

.fui-github:before {
  content: "\e65c";
}

.fui-foursquare:before {
  content: "\e65d";
}

.fui-dropbox:before {
  content: "\e65e";
}

.fui-android:before {
  content: "\e65f";
}

.fui-apple:before {
  content: "\e660";
}

* {
  outline: none !important;
}

body {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.72222;
  color: #34495e;
  background-color: #fff;
}

a {
  color: #16a085;
  text-decoration: none;
  transition: .25s;
}

a:hover, a:focus {
  color: #1abc9c;
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.72222;
  background-color: #fff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: all .25s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-comment {
  font-size: 15px;
  line-height: 1.2;
  font-style: italic;
  margin: 24px 0;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
  color: #e7e9ec;
}

h1,
h2,
h3 {
  margin-top: 30px;
  margin-bottom: 15px;
}

h4,
h5,
h6 {
  margin-top: 15px;
  margin-bottom: 15px;
}

h6 {
  font-weight: normal;
}

h1, .h1 {
  font-size: 61px;
}

h2, .h2 {
  font-size: 53px;
}

h3, .h3 {
  font-size: 40px;
}

h4, .h4 {
  font-size: 29px;
}

h5, .h5 {
  font-size: 28px;
}

h6, .h6 {
  font-size: 24px;
}

p {
  font-size: 18px;
  line-height: 1.72222;
  margin: 0 0 15px;
}

.lead {
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 1.46428571;
  font-weight: 300;
}

@media (min-width: 768px) {
  .lead {
    font-size: 30.006px;
  }
}

small,
.small {
  font-size: 83%;
  line-height: 2.067;
}

.text-muted {
  color: #bdc3c7;
}

.text-inverse {
  color: white;
}

.text-primary {
  color: #1abc9c !important;
}

a.text-primary:hover {
  color: #148f77;
}

.text-warning {
  color: #f1c40f !important;
}

a.text-warning:hover {
  color: #c29d0b;
}

.text-danger {
  color: #e74c3c !important;
}

a.text-danger:hover {
  color: #d62c1a;
}

.text-success {
  color: #2ecc71 !important;
}

a.text-success:hover {
  color: #25a25a;
}

.text-info {
  color: #3498db !important;
}

a.text-info:hover {
  color: #217dbb;
}

.bg-primary {
  color: white;
  background-color: #34495e;
}

a.bg-primary:hover {
  background-color: #22303d;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 14px;
  margin: 60px 0 30px;
  border-bottom: 2px solid #e7e9ec;
}

ul,
ol {
  margin-bottom: 15px;
}

dl {
  margin-bottom: 30px;
}

dt,
dd {
  line-height: 1.72222;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    width: 160px;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #bdc3c7;
}

blockquote {
  border-left: 3px solid #e7e9ec;
  padding: 0 0 0 16px;
  margin: 0 0 30px;
}

blockquote p {
  font-size: 20px;
  line-height: 1.55;
  font-weight: normal;
  margin-bottom: .4em;
}

blockquote small,
blockquote .small {
  font-size: 18px;
  line-height: 1.72222;
  font-style: italic;
  color: inherit;
}

blockquote small:before,
blockquote .small:before {
  content: "";
}

blockquote.pull-right {
  padding-right: 16px;
  padding-left: 0;
  border-right: 3px solid #e7e9ec;
  border-left: 0;
}

blockquote.pull-right small:after {
  content: "";
}

address {
  margin-bottom: 30px;
  line-height: 1.72222;
}

sub,
sup {
  font-size: 70%;
}

code,
kbd,
pre,
samp {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}

code {
  padding: 2px 6px;
  font-size: 85%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

kbd {
  padding: 2px 6px;
  font-size: 85%;
  color: white;
  background-color: #34495e;
  border-radius: 4px;
  box-shadow: none;
}

pre {
  padding: 8px;
  margin: 0 0 15px;
  font-size: 13px;
  line-height: 1.72222;
  color: inherit;
  background-color: white;
  border: 2px solid #e7e9ec;
  border-radius: 6px;
  white-space: pre;
}

.pre-scrollable {
  max-height: 340px;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 5px;
  line-height: 1.72222;
  background-color: #fff;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: border .25s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail a:hover, .thumbnail a:focus, .thumbnail a.active {
  border-color: #16a085;
}

.thumbnail .caption {
  padding: 9px;
  color: #34495e;
}

.btn {
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.btn:hover, .btn:focus {
  outline: none;
  color: white;
}

.btn:active, .btn.active {
  outline: none;
  box-shadow: none;
}

.btn:focus:active {
  outline: none;
}

.btn.disabled, .btn[disabled],
fieldset[disabled] .btn {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.7;
  filter: "alpha(opacity=70)";
  cursor: not-allowed;
}

.btn [class^="fui-"] {
  margin: 0 1px;
  position: relative;
  line-height: 1;
  top: 1px;
}

.btn-xs.btn [class^="fui-"], .btn-group-xs > .btn [class^="fui-"] {
  font-size: 11px;
  top: 0;
}

.btn-hg.btn [class^="fui-"], .btn-group-hg > .btn [class^="fui-"] {
  top: 2px;
}

.btn-default {
  color: white;
  background-color: #bdc3c7;
}

.show > .dropdown-toggle.btn-default, .btn-default:hover, .btn-default.hover, .btn-default:focus, .btn-default:active, .btn-default.active {
  color: white;
  background-color: #cacfd2;
  border-color: #cacfd2;
}

.show > .dropdown-toggle.btn-default, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active {
  background: #a1a6a9;
  border-color: #a1a6a9;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled.hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled].hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default.hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.btn-default .badge {
  color: #bdc3c7;
  background-color: white;
}

.btn-primary {
  color: white;
  background-color: #1abc9c;
}

.show > .dropdown-toggle.btn-primary, .btn-primary:hover, .btn-primary.hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: white;
  background-color: #48c9b0;
  border-color: #48c9b0;
}

.show > .dropdown-toggle.btn-primary, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  background: #16a085;
  border-color: #16a085;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled.hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled].hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary.hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}

.btn-primary .badge {
  color: #1abc9c;
  background-color: white;
}

.btn-info {
  color: white;
  background-color: #3498db;
}

.show > .dropdown-toggle.btn-info, .btn-info:hover, .btn-info.hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: white;
  background-color: #5dade2;
  border-color: #5dade2;
}

.show > .dropdown-toggle.btn-info, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
  background: #2c81ba;
  border-color: #2c81ba;
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled.hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled].hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info.hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #bdc3c7;
  border-color: #3498db;
}

.btn-info .badge {
  color: #3498db;
  background-color: white;
}

.btn-danger {
  color: white;
  background-color: #e74c3c;
}

.show > .dropdown-toggle.btn-danger, .btn-danger:hover, .btn-danger.hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
  color: white;
  background-color: #ec7063;
  border-color: #ec7063;
}

.show > .dropdown-toggle.btn-danger, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active {
  background: #c44133;
  border-color: #c44133;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled.hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled].hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger.hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #bdc3c7;
  border-color: #e74c3c;
}

.btn-danger .badge {
  color: #e74c3c;
  background-color: white;
}

.btn-success {
  color: white;
  background-color: #2ecc71;
}

.show > .dropdown-toggle.btn-success, .btn-success:hover, .btn-success.hover, .btn-success:focus, .btn-success:active, .btn-success.active {
  color: white;
  background-color: #58d68d;
  border-color: #58d68d;
}

.show > .dropdown-toggle.btn-success, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
  background: #27ad60;
  border-color: #27ad60;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled.hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled].hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success.hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #bdc3c7;
  border-color: #2ecc71;
}

.btn-success .badge {
  color: #2ecc71;
  background-color: white;
}

.btn-warning {
  color: white;
  background-color: #f1c40f;
}

.show > .dropdown-toggle.btn-warning, .btn-warning:hover, .btn-warning.hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
  color: white;
  background-color: #f4d313;
  border-color: #f4d313;
}

.show > .dropdown-toggle.btn-warning, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active {
  background: #cda70d;
  border-color: #cda70d;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled.hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled].hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning.hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #bdc3c7;
  border-color: #f1c40f;
}

.btn-warning .badge {
  color: #f1c40f;
  background-color: white;
}

.btn-inverse {
  color: white;
  background-color: #34495e;
}

.show > .dropdown-toggle.btn-inverse, .btn-inverse:hover, .btn-inverse.hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active {
  color: white;
  background-color: #415b76;
  border-color: #415b76;
}

.show > .dropdown-toggle.btn-inverse, .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active {
  background: #2c3e50;
  border-color: #2c3e50;
}

.btn-inverse.disabled, .btn-inverse.disabled:hover, .btn-inverse.disabled.hover, .btn-inverse.disabled:focus, .btn-inverse.disabled:active, .btn-inverse.disabled.active, .btn-inverse[disabled], .btn-inverse[disabled]:hover, .btn-inverse[disabled].hover, .btn-inverse[disabled]:focus, .btn-inverse[disabled]:active, .btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse,
fieldset[disabled] .btn-inverse:hover,
fieldset[disabled] .btn-inverse.hover,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse:active,
fieldset[disabled] .btn-inverse.active {
  background-color: #bdc3c7;
  border-color: #34495e;
}

.btn-inverse .badge {
  color: #34495e;
  background-color: white;
}

.btn-embossed {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}

.btn-embossed.active, .btn-embossed:active {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}

.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-link {
  color: #16a085;
}

.btn-link:hover, .btn-link:focus {
  color: #1abc9c;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #bdc3c7;
  text-decoration: none;
}

.btn-hg, .btn-group-hg > .btn {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 6px 9px;
  font-size: 12px;
  line-height: 1.083;
  border-radius: 3px;
}

@media (max-width: 767.98px) {
  .btn-reduce-on-xs {
    padding: 9px 13px;
    font-size: 13px;
    line-height: 1.385;
    border-radius: 4px;
  }
}

.btn-tip {
  font-weight: 300;
  padding-left: 10px;
  font-size: 92%;
}

.btn-block {
  white-space: normal;
}

[class*="btn-social-"] {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.077;
  border-radius: 4px;
}

.btn-social-pinterest {
  color: white;
  background-color: #cb2028;
}

.btn-social-pinterest:hover, .btn-social-pinterest:focus {
  background-color: #d54d53;
}

.btn-social-pinterest:active, .btn-social-pinterest.active {
  background-color: #ad1b22;
}

.btn-social-linkedin {
  color: white;
  background-color: #0072b5;
}

.btn-social-linkedin:hover, .btn-social-linkedin:focus {
  background-color: #338ec4;
}

.btn-social-linkedin:active, .btn-social-linkedin.active {
  background-color: #00619a;
}

.btn-social-stumbleupon {
  color: white;
  background-color: #ed4a13;
}

.btn-social-stumbleupon:hover, .btn-social-stumbleupon:focus {
  background-color: #f16e42;
}

.btn-social-stumbleupon:active, .btn-social-stumbleupon.active {
  background-color: #c93f10;
}

.btn-social-googleplus {
  color: white;
  background-color: #2d2d2d;
}

.btn-social-googleplus:hover, .btn-social-googleplus:focus {
  background-color: #575757;
}

.btn-social-googleplus:active, .btn-social-googleplus.active {
  background-color: #262626;
}

.btn-social-facebook {
  color: white;
  background-color: #2f4b93;
}

.btn-social-facebook:hover, .btn-social-facebook:focus {
  background-color: #596fa9;
}

.btn-social-facebook:active, .btn-social-facebook.active {
  background-color: #28407d;
}

.btn-social-twitter {
  color: white;
  background-color: #00bdef;
}

.btn-social-twitter:hover, .btn-social-twitter:focus {
  background-color: #33caf2;
}

.btn-social-twitter:active, .btn-social-twitter.active {
  background-color: #00a1cb;
}

.btn-group > .btn + .btn {
  margin-left: 0;
}

.btn-group > .btn + .dropdown-toggle {
  border-left: 2px solid rgba(52, 73, 94, 0.15);
  padding: 10px 12px;
}

.btn-group > .btn + .dropdown-toggle:after {
  margin-left: 3px;
  margin-right: 3px;
}

.btn-group > .btn.btn-gh + .dropdown-toggle .caret {
  margin-left: 7px;
  margin-right: 7px;
}

.btn-group-xs > .btn + .dropdown-toggle {
  padding: 6px 9px;
}

.btn-group-sm > .btn + .dropdown-toggle {
  padding: 9px 13px;
}

.btn-group-lg > .btn + .dropdown-toggle {
  padding: 10px 19px;
}

.btn-group-hg > .btn + .dropdown-toggle {
  padding: 13px 20px;
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 8px 6px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 6px 8px;
}

.dropup .btn-xs .caret, .dropup .btn-group-xs > .btn .caret {
  border-width: 0 4px 6px;
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .select2-drop,
.btn-group > .popover {
  font-weight: 400;
}

.btn-group:focus .dropdown-toggle {
  outline: none;
  transition: .25s;
}

.btn-group.show .dropdown-toggle {
  color: rgba(255, 255, 255, 0.75);
  box-shadow: none;
}

.btn-toolbar .btn.active {
  color: white;
}

.btn-toolbar .btn > [class^="fui-"] {
  font-size: 16px;
  margin: 0 1px;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  border-bottom: none;
}

textarea {
  font-size: 20px;
  line-height: 24px;
  padding: 5px 11px;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none !important;
}

label {
  font-weight: normal;
  font-size: 15px;
  line-height: 2.3;
}

.form-control::-moz-placeholder, .select2-search input[type="text"]::-moz-placeholder {
  color: #b2bcc5;
  opacity: 1;
}

.form-control:-ms-input-placeholder, .select2-search input[type="text"]:-ms-input-placeholder {
  color: #b2bcc5;
}

.form-control::-webkit-input-placeholder, .select2-search input[type="text"]::-webkit-input-placeholder {
  color: #b2bcc5;
}

.form-control, .select2-search input[type="text"] {
  border: 2px solid #bdc3c7;
  color: #34495e;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.467;
  padding: 8px 12px;
  height: 42px;
  border-radius: 6px;
  box-shadow: none;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.form-group.focus .form-control, .form-group.focus .select2-search input[type="text"], .select2-search .form-group.focus input[type="text"], .form-control:focus, .select2-search input[type="text"]:focus {
  border-color: #1abc9c;
  outline: 0;
  box-shadow: none;
}

.form-control[disabled], .select2-search input[disabled][type="text"], .form-control[readonly], .select2-search input[readonly][type="text"],
fieldset[disabled] .form-control,
fieldset[disabled] .select2-search input[type="text"], .select2-search
fieldset[disabled] input[type="text"] {
  background-color: #f4f6f6;
  border-color: #d5dbdb;
  color: #d5dbdb;
  cursor: default;
  opacity: 0.7;
  filter: "alpha(opacity=70)";
}

.form-control.flat, .select2-search input.flat[type="text"] {
  border-color: transparent;
}

.form-control.flat:hover, .select2-search input.flat[type="text"]:hover {
  border-color: #bdc3c7;
}

.form-control.flat:focus, .select2-search input.flat[type="text"]:focus {
  border-color: #1abc9c;
}

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-text,
.input-group-sm > .input-group-btn > .btn, .input-group-sm .fileinput-filename, .select2-search input[type="text"],
.form-group-sm .form-control,
.form-group-sm .select2-search input[type="text"], .select2-search
.form-group-sm input[type="text"] {
  height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
}

.input-lg, .input-group-lg > .form-control, .select2-search .input-group-lg > input[type="text"],
.input-group-lg > .input-group-text,
.input-group-lg > .input-group-btn > .btn, .input-group-lg .fileinput-filename,
.form-group-lg .form-control,
.form-group-lg .select2-search input[type="text"], .select2-search
.form-group-lg input[type="text"] {
  height: 45px;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 1.235;
  border-radius: 6px;
}

.input-hg, .form-horizontal .form-group-hg .form-control, .form-horizontal .form-group-hg .select2-search input[type="text"], .select2-search .form-horizontal .form-group-hg input[type="text"], .input-group-hg > .form-control, .select2-search .input-group-hg > input[type="text"],
.input-group-hg > .input-group-text,
.input-group-hg > .input-group-btn > .btn, .input-group-hg .fileinput-filename,
.form-group-hg .form-control,
.form-group-hg .select2-search input[type="text"], .select2-search
.form-group-hg input[type="text"] {
  height: 53px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.318;
  border-radius: 6px;
}

.form-control-feedback {
  position: absolute;
  top: 2px;
  right: 2px;
  margin-top: 1px;
  line-height: 36px;
  font-size: 17px;
  color: #b2bcc5;
  background-color: transparent;
  padding: 0 12px 0 0;
  border-radius: 6px;
  pointer-events: none;
}

.input-hg + .form-control-feedback, .form-horizontal .form-group-hg .form-control + .form-control-feedback, .form-horizontal .form-group-hg .select2-search input[type="text"] + .form-control-feedback, .select2-search .form-horizontal .form-group-hg input[type="text"] + .form-control-feedback, .input-group-hg > .form-control + .form-control-feedback, .select2-search .input-group-hg > input[type="text"] + .form-control-feedback,
.input-group-hg > .input-group-text + .form-control-feedback,
.input-group-hg > .input-group-btn > .btn + .form-control-feedback, .input-group-hg .fileinput-filename + .form-control-feedback,
.control-feedback-hg {
  font-size: 20px;
  line-height: 48px;
  padding-right: 16px;
  width: auto;
  height: 48px;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .select2-search .input-group-lg > input[type="text"] + .form-control-feedback,
.input-group-lg > .input-group-text + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback, .input-group-lg .fileinput-filename + .form-control-feedback,
.control-feedback-lg {
  font-size: 18px;
  line-height: 40px;
  width: auto;
  height: 40px;
  padding-right: 15px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .select2-search .input-group-sm > input[type="text"] + .form-control-feedback,
.input-group-sm > .input-group-text + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback, .input-group-sm .fileinput-filename + .form-control-feedback, .select2-search input[type="text"] + .form-control-feedback,
.control-feedback-sm {
  line-height: 29px;
  height: 29px;
  width: auto;
  padding-right: 10px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #2ecc71;
}

.has-success .form-control, .has-success .select2-search input[type="text"], .select2-search .has-success input[type="text"] {
  color: #2ecc71;
  border-color: #2ecc71;
  box-shadow: none;
}

.has-success .form-control::-moz-placeholder, .has-success .select2-search input[type="text"]::-moz-placeholder, .select2-search .has-success input[type="text"]::-moz-placeholder {
  color: #2ecc71;
  opacity: 1;
}

.has-success .form-control:-ms-input-placeholder, .has-success .select2-search input[type="text"]:-ms-input-placeholder, .select2-search .has-success input[type="text"]:-ms-input-placeholder {
  color: #2ecc71;
}

.has-success .form-control::-webkit-input-placeholder, .has-success .select2-search input[type="text"]::-webkit-input-placeholder, .select2-search .has-success input[type="text"]::-webkit-input-placeholder {
  color: #2ecc71;
}

.has-success .form-control:focus, .has-success .select2-search input[type="text"]:focus, .select2-search .has-success input[type="text"]:focus {
  border-color: #2ecc71;
  box-shadow: none;
}

.has-success .input-group-text {
  color: #2ecc71;
  border-color: #2ecc71;
  background-color: white;
}

.has-success .form-control-feedback {
  color: #2ecc71;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #f1c40f;
}

.has-warning .form-control, .has-warning .select2-search input[type="text"], .select2-search .has-warning input[type="text"] {
  color: #f1c40f;
  border-color: #f1c40f;
  box-shadow: none;
}

.has-warning .form-control::-moz-placeholder, .has-warning .select2-search input[type="text"]::-moz-placeholder, .select2-search .has-warning input[type="text"]::-moz-placeholder {
  color: #f1c40f;
  opacity: 1;
}

.has-warning .form-control:-ms-input-placeholder, .has-warning .select2-search input[type="text"]:-ms-input-placeholder, .select2-search .has-warning input[type="text"]:-ms-input-placeholder {
  color: #f1c40f;
}

.has-warning .form-control::-webkit-input-placeholder, .has-warning .select2-search input[type="text"]::-webkit-input-placeholder, .select2-search .has-warning input[type="text"]::-webkit-input-placeholder {
  color: #f1c40f;
}

.has-warning .form-control:focus, .has-warning .select2-search input[type="text"]:focus, .select2-search .has-warning input[type="text"]:focus {
  border-color: #f1c40f;
  box-shadow: none;
}

.has-warning .input-group-text {
  color: #f1c40f;
  border-color: #f1c40f;
  background-color: white;
}

.has-warning .form-control-feedback {
  color: #f1c40f;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #e74c3c;
}

.has-error .form-control, .has-error .select2-search input[type="text"], .select2-search .has-error input[type="text"] {
  color: #e74c3c;
  border-color: #e74c3c;
  box-shadow: none;
}

.has-error .form-control::-moz-placeholder, .has-error .select2-search input[type="text"]::-moz-placeholder, .select2-search .has-error input[type="text"]::-moz-placeholder {
  color: #e74c3c;
  opacity: 1;
}

.has-error .form-control:-ms-input-placeholder, .has-error .select2-search input[type="text"]:-ms-input-placeholder, .select2-search .has-error input[type="text"]:-ms-input-placeholder {
  color: #e74c3c;
}

.has-error .form-control::-webkit-input-placeholder, .has-error .select2-search input[type="text"]::-webkit-input-placeholder, .select2-search .has-error input[type="text"]::-webkit-input-placeholder {
  color: #e74c3c;
}

.has-error .form-control:focus, .has-error .select2-search input[type="text"]:focus, .select2-search .has-error input[type="text"]:focus {
  border-color: #e74c3c;
  box-shadow: none;
}

.has-error .input-group-text {
  color: #e74c3c;
  border-color: #e74c3c;
  background-color: white;
}

.has-error .form-control-feedback {
  color: #e74c3c;
}

.form-control[disabled] + .form-control-feedback, .select2-search input[disabled][type="text"] + .form-control-feedback,
.form-control[readonly] + .form-control-feedback, .select2-search input[readonly][type="text"] + .form-control-feedback,
fieldset[disabled] .form-control + .form-control-feedback,
fieldset[disabled] .select2-search input[type="text"] + .form-control-feedback, .select2-search
fieldset[disabled] input[type="text"] + .form-control-feedback,
.form-control.disabled + .form-control-feedback, .select2-search input.disabled[type="text"] + .form-control-feedback {
  cursor: not-allowed;
  color: #d5dbdb;
  background-color: transparent;
  opacity: 0.7;
  filter: "alpha(opacity=70)";
}

.help-block {
  font-size: 14px;
  margin-bottom: 5px;
  color: #6b7a88;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

.form-horizontal .form-control-static {
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-hg .control-label {
    font-size: 22px;
    padding-top: 2px;
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    font-size: 17px;
    padding-top: 3px;
    padding-bottom: 2px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.input-group .form-control, .input-group .select2-search input[type="text"], .select2-search .input-group input[type="text"] {
  position: static;
}

.input-group-text {
  padding: 10px 12px;
  font-size: 15px;
  line-height: 1;
  color: white;
  text-align: center;
  background-color: #bdc3c7;
  border: 2px solid #bdc3c7;
  border-radius: 6px;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.input-group-text:first-child {
  border-right: 0;
}

.input-group-hg .input-group-text,
.input-group-lg .input-group-text,
.input-group-sm .input-group-text {
  line-height: 1;
}

.input-group-text .checkbox, .input-group-text .radio {
  margin: 0;
  padding-left: 19px;
}

.input-group .form-control:first-child, .input-group .select2-search input[type="text"]:first-child, .select2-search .input-group input[type="text"]:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group .form-control:last-child, .input-group .select2-search input[type="text"]:last-child, .select2-search .input-group input[type="text"]:last-child,
.input-group-text:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.focus .input-group-text,
.input-group.focus .input-group-text {
  background-color: #1abc9c;
  border-color: #1abc9c;
}

.form-group.focus .input-group-btn > .btn-default + .btn-default,
.input-group.focus .input-group-btn > .btn-default + .btn-default {
  border-left-color: #16a085;
}

.form-group.focus .input-group-btn .btn,
.input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  background-color: white;
  color: #1abc9c;
}

.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
  color: white;
  background-color: #1abc9c;
}

.show > .dropdown-toggle.form-group.focus .input-group-btn .btn-default, .input-group.focus .input-group-btn .btn-default, .form-group.focus .input-group-btn .btn-default:hover, .form-group.focus .input-group-btn .btn-default.hover, .form-group.focus .input-group-btn .btn-default:focus, .form-group.focus .input-group-btn .btn-default:active, .form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default:hover,
.input-group.focus .input-group-btn .btn-default.hover,
.input-group.focus .input-group-btn .btn-default:focus,
.input-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default.active {
  color: white;
  background-color: #48c9b0;
  border-color: #48c9b0;
}

.show > .dropdown-toggle.form-group.focus .input-group-btn .btn-default, .input-group.focus .input-group-btn .btn-default, .form-group.focus .input-group-btn .btn-default:not(:disabled):not(.disabled):active, .form-group.focus .input-group-btn .btn-default:not(:disabled):not(.disabled).active,
.input-group.focus .input-group-btn .btn-default:not(:disabled):not(.disabled):active,
.input-group.focus .input-group-btn .btn-default:not(:disabled):not(.disabled).active {
  background: #16a085;
  border-color: #16a085;
}

.form-group.focus .input-group-btn .btn-default.disabled, .form-group.focus .input-group-btn .btn-default.disabled:hover, .form-group.focus .input-group-btn .btn-default.disabled.hover, .form-group.focus .input-group-btn .btn-default.disabled:focus, .form-group.focus .input-group-btn .btn-default.disabled:active, .form-group.focus .input-group-btn .btn-default.disabled.active, .form-group.focus .input-group-btn .btn-default[disabled], .form-group.focus .input-group-btn .btn-default[disabled]:hover, .form-group.focus .input-group-btn .btn-default[disabled].hover, .form-group.focus .input-group-btn .btn-default[disabled]:focus, .form-group.focus .input-group-btn .btn-default[disabled]:active, .form-group.focus .input-group-btn .btn-default[disabled].active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:focus,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.disabled,
.input-group.focus .input-group-btn .btn-default.disabled:hover,
.input-group.focus .input-group-btn .btn-default.disabled.hover,
.input-group.focus .input-group-btn .btn-default.disabled:focus,
.input-group.focus .input-group-btn .btn-default.disabled:active,
.input-group.focus .input-group-btn .btn-default.disabled.active,
.input-group.focus .input-group-btn .btn-default[disabled],
.input-group.focus .input-group-btn .btn-default[disabled]:hover,
.input-group.focus .input-group-btn .btn-default[disabled].hover,
.input-group.focus .input-group-btn .btn-default[disabled]:focus,
.input-group.focus .input-group-btn .btn-default[disabled]:active,
.input-group.focus .input-group-btn .btn-default[disabled].active,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default:hover,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default.hover,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default:focus,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default:active,
fieldset[disabled]
.input-group.focus .input-group-btn .btn-default.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}

.form-group.focus .input-group-btn .btn-default .badge,
.input-group.focus .input-group-btn .btn-default .badge {
  color: #1abc9c;
  background-color: white;
}

.input-group-btn .btn {
  background-color: white;
  border: 2px solid #bdc3c7;
  color: #bdc3c7;
  line-height: 18px;
  height: 42px;
  position: relative;
}

.input-group-btn .btn-default {
  color: white;
  background-color: #bdc3c7;
}

.show > .dropdown-toggle.input-group-btn .btn-default, .input-group-btn .btn-default:hover, .input-group-btn .btn-default.hover, .input-group-btn .btn-default:focus, .input-group-btn .btn-default:active, .input-group-btn .btn-default.active {
  color: white;
  background-color: #cacfd2;
  border-color: #cacfd2;
}

.show > .dropdown-toggle.input-group-btn .btn-default, .input-group-btn .btn-default:not(:disabled):not(.disabled):active, .input-group-btn .btn-default:not(:disabled):not(.disabled).active {
  background: #a1a6a9;
  border-color: #a1a6a9;
}

.input-group-btn .btn-default.disabled, .input-group-btn .btn-default.disabled:hover, .input-group-btn .btn-default.disabled.hover, .input-group-btn .btn-default.disabled:focus, .input-group-btn .btn-default.disabled:active, .input-group-btn .btn-default.disabled.active, .input-group-btn .btn-default[disabled], .input-group-btn .btn-default[disabled]:hover, .input-group-btn .btn-default[disabled].hover, .input-group-btn .btn-default[disabled]:focus, .input-group-btn .btn-default[disabled]:active, .input-group-btn .btn-default[disabled].active,
fieldset[disabled] .input-group-btn .btn-default,
fieldset[disabled] .input-group-btn .btn-default:hover,
fieldset[disabled] .input-group-btn .btn-default.hover,
fieldset[disabled] .input-group-btn .btn-default:focus,
fieldset[disabled] .input-group-btn .btn-default:active,
fieldset[disabled] .input-group-btn .btn-default.active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.input-group-btn .btn-default .badge {
  color: #bdc3c7;
  background-color: white;
}

.input-group-hg .input-group-btn .btn {
  line-height: 31px;
}

.input-group-lg .input-group-btn .btn {
  line-height: 21px;
}

.input-group-sm .input-group-btn .btn {
  line-height: 19px;
}

.input-group-btn:first-child > .btn {
  border-right-width: 0;
  margin-right: -3px;
}

.input-group-btn:last-child > .btn {
  border-left-width: 0;
  margin-left: -3px;
}

.input-group-btn > .btn-default + .btn-default {
  border-left: 2px solid #bdc3c7;
}

.input-group-btn > .btn:first-child + .btn .caret {
  margin-left: 0;
}

.input-group-rounded .input-group-btn + .form-control, .input-group-rounded .select2-search .input-group-btn + input[type="text"], .select2-search .input-group-rounded .input-group-btn + input[type="text"],
.input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.input-group-hg.input-group-rounded .input-group-btn + .form-control, .input-group-hg.input-group-rounded .select2-search .input-group-btn + input[type="text"], .select2-search .input-group-hg.input-group-rounded .input-group-btn + input[type="text"], .input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 27px;
  border-top-right-radius: 27px;
}

.input-group-lg.input-group-rounded .input-group-btn + .form-control, .input-group-lg.input-group-rounded .select2-search .input-group-btn + input[type="text"], .select2-search .input-group-lg.input-group-rounded .input-group-btn + input[type="text"], .input-group-rounded .input-group-btn:last-child .btn {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.input-group-rounded .form-control:first-child, .input-group-rounded .select2-search input[type="text"]:first-child, .select2-search .input-group-rounded input[type="text"]:first-child,
.input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.input-group-hg.input-group-rounded .form-control:first-child, .input-group-hg.input-group-rounded .select2-search input[type="text"]:first-child, .select2-search .input-group-hg.input-group-rounded input[type="text"]:first-child, .input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 27px;
  border-top-left-radius: 27px;
}

.input-group-lg.input-group-rounded .form-control:first-child, .input-group-lg.input-group-rounded .select2-search input[type="text"]:first-child, .select2-search .input-group-lg.input-group-rounded input[type="text"]:first-child, .input-group-rounded .input-group-btn:first-child .btn {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}

.input-group-rounded .input-group-btn + .form-control, .input-group-rounded .select2-search .input-group-btn + input[type="text"], .select2-search .input-group-rounded .input-group-btn + input[type="text"] {
  padding-left: 0;
}

.btn-file {
  overflow: hidden;
  position: relative;
}

.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
  width: 100%;
  height: 100%;
  outline: 0;
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.fileinput {
  margin-bottom: 9px;
  display: block;
  overflow: hidden;
}

.fileinput .form-control, .fileinput .select2-search input[type="text"], .select2-search .fileinput input[type="text"] {
  display: inline-block;
  margin-bottom: 0;
  cursor: text;
}

.fileinput .input-group-text {
  padding-top: 8px;
  padding-bottom: 8px;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .uneditable-input [class^="fui-"] {
  margin-right: .3em;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  padding-right: 35px;
  vertical-align: middle;
  overflow: hidden;
  font-size: 15px;
  line-height: 1.67238;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-group-hg .fileinput-filename {
  left: 33px;
  padding-right: 33px !important;
}

.input-group-lg .fileinput-filename {
  left: 27px;
  padding-right: 27px !important;
}

.input-group-sm .fileinput-filename {
  left: 20px;
  padding-right: 20px !important;
}

.form-control .fileinput-filename, .select2-search input[type="text"] .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group {
  display: table;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}

.fileinput-new.input-group .btn-file.btn-xs, .fileinput-new.input-group .btn-group-xs > .btn-file.btn, .fileinput-new.input-group .btn-file.btn-sm, .fileinput-new.input-group .btn-group-sm > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-group-xs > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-group-sm > .btn-file.btn {
  border-radius: 0 3px 3px 0;
}

.fileinput-new.input-group .btn-file.btn-lg, .fileinput-new.input-group .btn-group-lg > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-group-lg > .btn-file.btn {
  border-radius: 0 6px 6px 0;
}

.form-group.has-warning .fileinput .fileinput-preview {
  color: #f1c40f;
}

.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: #e74c3c;
}

.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1;
}

.form-group.has-success .fileinput .fileinput-preview {
  color: #2ecc71;
}

.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6;
}

.input-group-text:not(:first-child) {
  border-left: 0;
}

.checkbox,
.radio {
  display: block;
  margin-top: 10px;
  margin-bottom: 12px;
  padding-left: 32px;
  position: relative;
  transition: color .25s linear;
  font-size: 14px;
  min-height: 20px;
  line-height: 1.5;
}

.checkbox .icons,
.radio .icons {
  color: #bdc3c7;
  display: block;
  height: 20px;
  top: 0;
  left: 0;
  position: absolute;
  width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
  transition: color .25s linear;
}

.checkbox .icons .icon-checked,
.radio .icons .icon-checked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox .icon-checked,
.checkbox .icon-unchecked,
.radio .icon-checked,
.radio .icon-unchecked {
  display: inline-table;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  margin: 0;
  opacity: 1;
  filter: none;
}

.checkbox .icon-checked:before,
.checkbox .icon-unchecked:before,
.radio .icon-checked:before,
.radio .icon-unchecked:before {
  font-family: 'Flat-UI-Pro-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.checkbox .icon-checked:before {
  content: "\e60e";
}

.checkbox .icon-unchecked:before {
  content: "\e60d";
}

.radio .icon-checked:before {
  content: "\e60c";
}

.radio .icon-unchecked:before {
  content: "\e60b";
}

.checkbox input[type="checkbox"].custom-checkbox,
.checkbox input[type="radio"].custom-radio,
.radio input[type="checkbox"].custom-checkbox,
.radio input[type="radio"].custom-radio {
  outline: none !important;
  opacity: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

.checkbox input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons,
.checkbox input[type="radio"].custom-radio:hover:not(.nohover) + .icons,
.radio input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons,
.radio input[type="radio"].custom-radio:hover:not(.nohover) + .icons {
  transition: color .25s linear;
}

.checkbox input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:hover:not(.nohover) + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:hover:not(.nohover) + .icons .icon-unchecked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:hover:not(.nohover) + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:hover:not(.nohover) + .icons .icon-checked,
.radio input[type="radio"].custom-radio:hover:not(.nohover) + .icons .icon-checked {
  opacity: 1;
  filter: none;
}

.checkbox input[type="checkbox"].custom-checkbox:checked + .icons,
.checkbox input[type="radio"].custom-radio:checked + .icons,
.radio input[type="checkbox"].custom-checkbox:checked + .icons,
.radio input[type="radio"].custom-radio:checked + .icons {
  color: #1abc9c;
}

.checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:checked + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:checked + .icons .icon-unchecked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:checked + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:checked + .icons .icon-checked,
.radio input[type="radio"].custom-radio:checked + .icons .icon-checked {
  opacity: 1;
  filter: none;
  color: #1abc9c;
  transition: color .25s linear;
}

.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons,
.checkbox input[type="radio"].custom-radio:disabled + .icons,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons,
.radio input[type="radio"].custom-radio:disabled + .icons {
  cursor: default;
  color: #e6e8ea;
}

.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons .icons,
.checkbox input[type="radio"].custom-radio:disabled + .icons .icons,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons .icons,
.radio input[type="radio"].custom-radio:disabled + .icons .icons {
  color: #e6e8ea;
}

.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:disabled + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:disabled + .icons .icon-unchecked {
  opacity: 1;
  filter: none;
}

.checkbox input[type="checkbox"].custom-checkbox:disabled + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:disabled + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:disabled + .icons .icon-checked,
.radio input[type="radio"].custom-radio:disabled + .icons .icon-checked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icons,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icons,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icons,
.radio input[type="radio"].custom-radio:disabled:checked + .icons .icons {
  color: #e6e8ea;
}

.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:disabled:checked + .icons .icon-unchecked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:disabled:checked + .icons .icon-checked,
.radio input[type="radio"].custom-radio:disabled:checked + .icons .icon-checked {
  opacity: 1;
  filter: none;
  color: #e6e8ea;
}

.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.radio input[type="radio"].custom-radio:indeterminate + .icons {
  color: #bdc3c7;
}

.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-unchecked,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons .icon-unchecked,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-unchecked,
.radio input[type="radio"].custom-radio:indeterminate + .icons .icon-unchecked {
  opacity: 1;
  filter: none;
}

.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-checked,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons .icon-checked,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons .icon-checked,
.radio input[type="radio"].custom-radio:indeterminate + .icons .icon-checked {
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.checkbox input[type="checkbox"].custom-checkbox:indeterminate + .icons:before,
.checkbox input[type="radio"].custom-radio:indeterminate + .icons:before,
.radio input[type="checkbox"].custom-checkbox:indeterminate + .icons:before,
.radio input[type="radio"].custom-radio:indeterminate + .icons:before {
  content: "\2013";
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  width: 20px;
  text-align: center;
  color: white;
  font-size: 22px;
  z-index: 10;
}

.checkbox input[type="checkbox"].custom-checkbox:focus + .icons,
.checkbox input[type="radio"].custom-radio:focus + .icons,
.radio input[type="checkbox"].custom-checkbox:focus + .icons,
.radio input[type="radio"].custom-radio:focus + .icons {
  outline: 1px dotted #bdc3c7;
  outline-offset: 1px;
}

.checkbox.primary input[type="checkbox"].custom-checkbox + .icons,
.checkbox.primary input[type="radio"].custom-radio + .icons,
.radio.primary input[type="checkbox"].custom-checkbox + .icons,
.radio.primary input[type="radio"].custom-radio + .icons {
  color: #34495e;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:checked + .icons,
.checkbox.primary input[type="radio"].custom-radio:checked + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:checked + .icons,
.radio.primary input[type="radio"].custom-radio:checked + .icons {
  color: #1abc9c;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:checked + .icons .icons,
.checkbox.primary input[type="radio"].custom-radio:checked + .icons .icons,
.radio.primary input[type="checkbox"].custom-checkbox:checked + .icons .icons,
.radio.primary input[type="radio"].custom-radio:checked + .icons .icons {
  color: #1abc9c;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:disabled + .icons,
.checkbox.primary input[type="radio"].custom-radio:disabled + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:disabled + .icons,
.radio.primary input[type="radio"].custom-radio:disabled + .icons {
  cursor: default;
  color: #bdc3c7;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:disabled + .icons .icons,
.checkbox.primary input[type="radio"].custom-radio:disabled + .icons .icons,
.radio.primary input[type="checkbox"].custom-checkbox:disabled + .icons .icons,
.radio.primary input[type="radio"].custom-radio:disabled + .icons .icons {
  color: #bdc3c7;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:disabled + .icons.checked .icons,
.checkbox.primary input[type="radio"].custom-radio:disabled + .icons.checked .icons,
.radio.primary input[type="checkbox"].custom-checkbox:disabled + .icons.checked .icons,
.radio.primary input[type="radio"].custom-radio:disabled + .icons.checked .icons {
  color: #bdc3c7;
}

.checkbox.primary input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.checkbox.primary input[type="radio"].custom-radio:indeterminate + .icons,
.radio.primary input[type="checkbox"].custom-checkbox:indeterminate + .icons,
.radio.primary input[type="radio"].custom-radio:indeterminate + .icons {
  color: #34495e;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}

.form-inline .checkbox, .form-inline .radio {
  padding-left: 32px;
}

.bootstrap-tagsinput {
  background-color: white;
  border: 2px solid #ebedef;
  border-radius: 6px;
  margin-bottom: 18px;
  padding: 6px 1px 1px 6px;
  text-align: left;
  font-size: 0;
}

.bootstrap-tagsinput .badge {
  border-radius: 4px;
  background-color: #ebedef;
  color: #7b8996;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 5px 5px 0;
  line-height: 15px;
  height: 27px;
  padding: 6px 28px 6px 14px;
  transition: .25s linear;
}

.bootstrap-tagsinput .badge > span {
  color: white;
  padding: 0 10px 0 0;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0;
  text-align: right;
  text-decoration: none;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

.bootstrap-tagsinput .badge > span:after {
  content: "\e609";
  font-family: "Flat-UI-Pro-Icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 27px;
}

@media (hover: hover) {
  .bootstrap-tagsinput .badge {
    padding: 6px 21px;
  }
  .bootstrap-tagsinput .badge > span {
    opacity: 0;
    filter: "alpha(opacity=0)";
    transition: opacity .25s linear;
  }
  .bootstrap-tagsinput .badge:hover {
    background-color: #16a085;
    color: white;
    padding-right: 28px;
    padding-left: 14px;
  }
  .bootstrap-tagsinput .badge:hover > span {
    padding: 0 10px 0 0;
    opacity: 1;
    filter: none;
  }
}

@media (hover: none) {
  .bootstrap-tagsinput .badge:hover {
    background-color: #16a085;
    color: white;
  }
}

.bootstrap-tagsinput input[type="text"] {
  font-size: 14px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto !important;
  max-width: inherit;
  min-width: 80px;
  vertical-align: top;
  height: 29px;
  color: #34495e;
}

.bootstrap-tagsinput input[type="text"]:first-child {
  height: 23px;
  margin: 3px 0 8px;
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0;
}

.not_valid {
  background: #fbd8db !important;
  color: #90111a !important;
  margin-left: 5px !important;
}

.tagsinput-primary {
  margin-bottom: 18px;
}

.tagsinput-primary .bootstrap-tagsinput {
  border-color: #1abc9c;
  margin-bottom: 0;
}

.tagsinput-primary .badge {
  background-color: #1abc9c;
  color: white;
}

.tagsinput-primary .badge:hover {
  background-color: #16a085;
  color: white;
}

.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
  vertical-align: top;
}

.bootstrap-tagsinput .twitter-typeahead .tt-input {
  min-width: 200px;
}

.bootstrap-tagsinput .twitter-typeahead .tt-dropdown-menu {
  width: auto;
  min-width: 120px;
  margin-top: 11px;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dropdown-menu {
  width: 100%;
  margin-top: 5px;
  border: 2px solid #1abc9c;
  padding: 5px 0;
  background-color: white;
  border-radius: 6px;
}

.twitter-typeahead .tt-suggestion p {
  padding: 6px 14px;
  font-size: 14px;
  line-height: 1.429;
  margin: 0;
}

.twitter-typeahead .tt-suggestion:first-child p, .twitter-typeahead .tt-suggestion:last-child p {
  padding: 6px 14px;
}

.twitter-typeahead .tt-suggestion.tt-is-under-cursor, .twitter-typeahead .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: #16a085;
}

.progress, .ui-slider {
  background: #ebedef;
  border-radius: 32px;
  height: 12px;
  box-shadow: none;
}

.progress-bar {
  background: #1abc9c;
  line-height: 12px;
  box-shadow: none;
}

.progress-bar-success {
  background-color: #2ecc71;
}

.progress-bar-warning {
  background-color: #f1c40f;
}

.progress-bar-danger {
  background-color: #e74c3c;
}

.progress-bar-info {
  background-color: #3498db;
}

.ui-slider {
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.ui-slider-handle {
  background-color: #16a085;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  position: absolute;
  width: 18px;
  z-index: 2;
  transition: background .25s;
}

.ui-slider-handle:hover, .ui-slider-handle:focus {
  background-color: #48c9b0;
  outline: none;
}

.ui-slider-handle:active {
  background-color: #16a085;
}

.ui-slider-range {
  background-color: #1abc9c;
  display: block;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.ui-slider-segment {
  background-color: #d9dbdd;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}

.ui-slider-value {
  float: right;
  font-size: 13px;
  margin-top: 12px;
}

.ui-slider-value.first {
  clear: left;
  float: left;
}

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -9px;
  top: -3px;
}

.ui-slider-horizontal .ui-slider-handle[style*="100"] {
  margin-left: -15px;
}

.ui-slider-horizontal .ui-slider-range {
  border-radius: 30px 0 0 30px;
}

.ui-slider-horizontal .ui-slider-segment {
  float: left;
  margin: 3px -6px 0 0;
}

.ui-slider-vertical {
  width: 12px;
}

.ui-slider-vertical .ui-slider-handle {
  margin-left: -3px;
  margin-bottom: -11px;
  top: auto;
}

.ui-slider-vertical .ui-slider-range {
  width: 100%;
  bottom: 0;
  border-radius: 0 0 30px 30px;
}

.ui-slider-vertical .ui-slider-segment {
  position: absolute;
  right: 3px;
}

.ui-spinner {
  display: inline-block;
  overflow: hidden;
  padding: 0 37px 0 0;
  position: relative;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .ui-spinner {
    display: block;
  }
}

.ui-spinner .ui-button-text {
  display: block;
}

.ui-spinner.focus .ui-spinner-input {
  color: #1abc9c;
  border-color: #1abc9c;
}

.ui-spinner.focus .ui-spinner-button {
  background-color: #1abc9c;
}

.ui-spinner.focus .ui-spinner-button:hover {
  background-color: #16a085;
}

.ui-spinner.focus .ui-spinner-up {
  border-bottom-color: #16a085;
}

.ui-spinner-input {
  width: 55px;
  border-radius: 6px 0 0 6px;
  text-align: center;
  padding: 6px 12px;
  border-right-width: 0 !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
}

.ui-spinner-input::-moz-placeholder {
  color: #718191;
  opacity: 1;
}

.ui-spinner-input:-ms-input-placeholder {
  color: #718191;
}

.ui-spinner-input::-webkit-input-placeholder {
  color: #718191;
}

@media (max-width: 768px) {
  .ui-spinner-input {
    width: 100%;
  }
}

.ui-spinner-input.input-hg + .ui-spinner-button, .form-horizontal .form-group-hg .ui-spinner-input.form-control + .ui-spinner-button, .form-horizontal .form-group-hg .select2-search input.ui-spinner-input[type="text"] + .ui-spinner-button, .select2-search .form-horizontal .form-group-hg input.ui-spinner-input[type="text"] + .ui-spinner-button, .input-group-hg > .ui-spinner-input.form-control + .ui-spinner-button, .select2-search .input-group-hg > input.ui-spinner-input[type="text"] + .ui-spinner-button,
.input-group-hg > .ui-spinner-input.input-group-text + .ui-spinner-button,
.input-group-hg > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button, .input-group-hg .ui-spinner-input.fileinput-filename + .ui-spinner-button {
  height: 28px;
  line-height: 25px;
}

.ui-spinner-input.input-hg + .ui-spinner-button + .ui-spinner-button, .form-horizontal .form-group-hg .ui-spinner-input.form-control + .ui-spinner-button + .ui-spinner-button, .form-horizontal .form-group-hg .select2-search input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button, .select2-search .form-horizontal .form-group-hg input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button, .input-group-hg > .ui-spinner-input.form-control + .ui-spinner-button + .ui-spinner-button, .select2-search .input-group-hg > input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button,
.input-group-hg > .ui-spinner-input.input-group-text + .ui-spinner-button + .ui-spinner-button,
.input-group-hg > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button + .ui-spinner-button, .input-group-hg .ui-spinner-input.fileinput-filename + .ui-spinner-button + .ui-spinner-button {
  height: 25px;
  line-height: 25px;
}

.ui-spinner-input.input-lg + .ui-spinner-button, .input-group-lg > .ui-spinner-input.form-control + .ui-spinner-button, .select2-search .input-group-lg > input.ui-spinner-input[type="text"] + .ui-spinner-button,
.input-group-lg > .ui-spinner-input.input-group-text + .ui-spinner-button,
.input-group-lg > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button, .input-group-lg .ui-spinner-input.fileinput-filename + .ui-spinner-button {
  height: 24px;
  line-height: 21px;
}

.ui-spinner-input.input-lg + .ui-spinner-button + .ui-spinner-button, .input-group-lg > .ui-spinner-input.form-control + .ui-spinner-button + .ui-spinner-button, .select2-search .input-group-lg > input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button,
.input-group-lg > .ui-spinner-input.input-group-text + .ui-spinner-button + .ui-spinner-button,
.input-group-lg > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button + .ui-spinner-button, .input-group-lg .ui-spinner-input.fileinput-filename + .ui-spinner-button + .ui-spinner-button {
  height: 21px;
  line-height: 21px;
}

.ui-spinner-input.input-sm, .input-group-sm > .ui-spinner-input.form-control,
.input-group-sm > .ui-spinner-input.input-group-text,
.input-group-sm > .input-group-btn > .ui-spinner-input.btn, .input-group-sm .ui-spinner-input.fileinput-filename, .select2-search input.ui-spinner-input[type="text"] {
  font-size: 18px;
  line-height: 1;
}

.ui-spinner-input.input-sm + .ui-spinner-button, .input-group-sm > .ui-spinner-input.form-control + .ui-spinner-button, .select2-search .input-group-sm > input.ui-spinner-input[type="text"] + .ui-spinner-button,
.input-group-sm > .ui-spinner-input.input-group-text + .ui-spinner-button,
.input-group-sm > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button, .input-group-sm .ui-spinner-input.fileinput-filename + .ui-spinner-button, .select2-search input.ui-spinner-input[type="text"] + .ui-spinner-button {
  height: 19px;
  line-height: 1;
}

.ui-spinner-input.input-sm + .ui-spinner-button + .ui-spinner-button, .input-group-sm > .ui-spinner-input.form-control + .ui-spinner-button + .ui-spinner-button, .select2-search .input-group-sm > input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button,
.input-group-sm > .ui-spinner-input.input-group-text + .ui-spinner-button + .ui-spinner-button,
.input-group-sm > .input-group-btn > .ui-spinner-input.btn + .ui-spinner-button + .ui-spinner-button, .input-group-sm .ui-spinner-input.fileinput-filename + .ui-spinner-button + .ui-spinner-button, .select2-search input.ui-spinner-input[type="text"] + .ui-spinner-button + .ui-spinner-button {
  height: 17px;
  line-height: 1;
}

.input-group-text + .ui-spinner .ui-spinner-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.ui-spinner-button {
  display: block;
  font-size: .5em;
  line-height: 19px;
  height: 22px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 37px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  background-color: #bdc3c7;
  outline: none;
}

.ui-spinner-button:hover {
  background-color: #d1d5d8;
  color: #e8f8f5;
}

.ui-spinner-button .ui-icon {
  font-size: 0;
}

.ui-spinner-button .ui-icon:before {
  content: "\e600";
  font-family: "Flat-UI-Pro-Icons";
  font-size: 16px;
  line-height: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-spinner-up {
  border-bottom: 2px solid #d1d5d8;
  top: 0;
  border-radius: 0 6px 0 0;
}

.ui-spinner-down {
  bottom: 0;
  height: 20px;
  border-radius: 0 0 6px 0;
}

.ui-spinner-down .ui-icon:before {
  content: "\e601";
}

.ui-datepicker {
  width: 264px;
  background-color: white;
  display: none;
  margin: 10px 0 0 0;
  border: 7px solid #1abc9c;
  border-radius: 6px;
}

.ui-datepicker .ui-datepicker-header {
  background-color: #1abc9c;
  color: white;
  cursor: default;
  margin: -7px -6px 2px;
  position: relative;
  border-radius: 6px 6px 0 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0;
  width: 52px;
  height: 44px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: #48c9b0;
}

.ui-datepicker .ui-datepicker-prev:active,
.ui-datepicker .ui-datepicker-next:active {
  background-color: #16a085;
}

.ui-datepicker .ui-datepicker-prev.ui-state-disabled,
.ui-datepicker .ui-datepicker-next.ui-state-disabled {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.75);
  cursor: default;
  opacity: 0.7;
  filter: "alpha(opacity=70)";
}

.ui-datepicker .ui-datepicker-prev {
  left: 0;
  border-right: 2px solid #5fd0ba;
  border-radius: 6px 0 0 0;
}

.ui-datepicker .ui-datepicker-next {
  right: 0;
  border-left: 2px solid #5fd0ba;
  border-radius: 0 6px 0 0;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 52px;
  line-height: 44px;
  text-align: center;
  font-size: 15px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -6px;
  text-indent: -9999px;
  width: 0;
  height: 0;
  content: "";
}

.ui-datepicker .ui-icon-circle-triangle-w {
  border: solid transparent;
  border-width: 7px 12px 7px 0;
  border-right-color: white;
}

.ui-datepicker .ui-icon-circle-triangle-e {
  border: solid transparent;
  border-width: 7px 0 7px 12px;
  border-left-color: white;
}

.ui-datepicker table {
  font-size: 14px;
  margin: 0 0 3px;
  width: 100%;
}

.ui-datepicker th {
  min-width: 34px;
  height: 30px;
  text-align: center;
  font-weight: bold;
  border: 0;
  padding: 1px;
  vertical-align: middle;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  text-decoration: none;
  color: #34495e;
  height: 30px;
  line-height: 30px;
  min-width: 34px;
}

.ui-datepicker td a {
  border-radius: 6px;
}

.ui-datepicker td a:hover {
  background-color: #d7dbdd;
  color: #34495e;
}

.ui-datepicker .ui-state-active {
  background-color: #1abc9c;
  color: white;
}

.ui-datepicker .ui-datepicker-other-month a {
  color: #c2c8cf;
}

.ui-datepicker select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker .ui-datepicker-month-year {
  width: 100%;
}

.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year {
  width: 40%;
  margin-right: 5%;
  margin-left: 5%;
  background: none;
  border: none;
}

.ui-datepicker .ui-datepicker-month option,
.ui-datepicker .ui-datepicker-year option {
  color: #34495e;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane .ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.hasDatepicker {
  -webkit-appearance: none;
}

.ui-timepicker-wrapper {
  overflow-y: auto;
  height: 200px;
  width: 9em;
  background: #fff;
  border-radius: 6px;
  border: solid 2px #1abc9c;
  outline: none;
  z-index: 1050;
  margin: 5px 0 0;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration {
  width: 15em;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30, .ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
  width: 12em;
}

.ui-timepicker-wrapper.ui-timepicker-positioned-top {
  margin-top: -5px;
}

.ui-timepicker-list {
  margin: 0;
  padding: 4px 0 0;
  list-style: none;
}

.ui-timepicker-list li {
  margin: 0;
  padding: 3px 0 3px 15px;
  list-style: none;
  cursor: pointer;
  color: rgba(52, 73, 94, 0.9);
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
}

.ui-timepicker-list li .ui-timepicker-duration {
  margin-left: 5px;
}

.ui-timepicker-list li:hover, .ui-timepicker-list li.ui-timepicker-selected {
  background: #1abc9c;
  color: white;
}

.ui-timepicker-input {
  -webkit-appearance: none;
}

.pager {
  background-color: #34495e;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li:first-child > a,
.pager li:first-child > span {
  border-left: none;
  border-radius: 6px 0 0 6px;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  background: none;
  border: none;
  border-left: 2px solid #2c3e50;
  color: white;
  padding: 9px 15px 10px;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 0 6px 6px 0;
  line-height: 1.313;
}

.pager li > a:hover, .pager li > a:focus,
.pager li > span:hover,
.pager li > span:focus {
  background-color: #2c3e50;
}

.pager li > a:active,
.pager li > span:active {
  background-color: #2c3e50;
}

.pager li > a [class*="fui-"] + span,
.pager li > span [class*="fui-"] + span {
  margin-left: 8px;
}

.pager li > a span + [class*="fui-"],
.pager li > span span + [class*="fui-"] {
  margin-left: 8px;
}

.pagination, .dataTables_paginate {
  position: relative;
  display: block;
  margin: 20px 0;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .pagination, .dataTables_paginate {
    display: inline-block;
  }
}

.pagination > ul, .dataTables_paginate > ul {
  background: #d6dbdf;
  color: white;
  padding: 0;
  margin: 0;
  display: inline-block;
  border-radius: 6px;
  word-spacing: -0.5px;
}

@media (max-width: 767px) {
  .pagination > ul, .dataTables_paginate > ul {
    height: 41px;
    padding: 0 55px 0 52px;
    overflow: auto;
    white-space: nowrap;
    border-radius: 6px;
  }
}

.pagination li, .dataTables_paginate li {
  display: inline-block;
  margin-right: -3px;
  vertical-align: middle;
  word-spacing: normal;
}

.pagination li.active > a, .dataTables_paginate li.active > a, .pagination li.active > span, .dataTables_paginate li.active > span {
  background-color: #1abc9c;
  color: white;
  border-color: #dee2e5;
}

.pagination li.active.previous > a, .dataTables_paginate li.active.previous > a, .pagination li.active.previous > span, .dataTables_paginate li.active.previous > span, .pagination li.active.next > a, .dataTables_paginate li.active.next > a, .pagination li.active.next > span, .dataTables_paginate li.active.next > span {
  margin: 0;
}

.pagination li.active.previous > a, .dataTables_paginate li.active.previous > a, .pagination li.active.previous > a:hover, .dataTables_paginate li.active.previous > a:hover, .pagination li.active.previous > a:focus, .dataTables_paginate li.active.previous > a:focus, .pagination li.active.previous > span, .dataTables_paginate li.active.previous > span, .pagination li.active.previous > span:hover, .dataTables_paginate li.active.previous > span:hover, .pagination li.active.previous > span:focus, .dataTables_paginate li.active.previous > span:focus, .pagination li.active.next > a, .dataTables_paginate li.active.next > a, .pagination li.active.next > a:hover, .dataTables_paginate li.active.next > a:hover, .pagination li.active.next > a:focus, .dataTables_paginate li.active.next > a:focus, .pagination li.active.next > span, .dataTables_paginate li.active.next > span, .pagination li.active.next > span:hover, .dataTables_paginate li.active.next > span:hover, .pagination li.active.next > span:focus, .dataTables_paginate li.active.next > span:focus {
  background-color: #1abc9c;
  color: white;
}

.pagination li:first-child > a, .dataTables_paginate li:first-child > a,
.pagination li:first-child > span, .dataTables_paginate li:first-child > span {
  border-radius: 6px 0 0 6px;
  border-left: none;
}

.pagination li:first-child.previous + li > a, .dataTables_paginate li:first-child.previous + li > a,
.pagination li:first-child.previous + li > span, .dataTables_paginate li:first-child.previous + li > span {
  border-left-width: 0;
}

.pagination li:last-child, .dataTables_paginate li:last-child {
  margin-right: 0;
}

.pagination li:last-child > a, .dataTables_paginate li:last-child > a, .pagination li:last-child > a:hover, .dataTables_paginate li:last-child > a:hover, .pagination li:last-child > a:focus, .dataTables_paginate li:last-child > a:focus,
.pagination li:last-child > span, .dataTables_paginate li:last-child > span,
.pagination li:last-child > span:hover, .dataTables_paginate li:last-child > span:hover,
.pagination li:last-child > span:focus, .dataTables_paginate li:last-child > span:focus {
  border-radius: 0 6px 6px 0;
}

.pagination li.previous > a, .dataTables_paginate li.previous > a,
.pagination li.previous > span, .dataTables_paginate li.previous > span, .pagination li.next > a, .dataTables_paginate li.next > a,
.pagination li.next > span, .dataTables_paginate li.next > span {
  border-right: 2px solid #e4e7ea;
  font-size: 16px;
  min-width: auto;
  padding: 12px 17px;
  background-color: transparent;
}

.pagination li.next > a, .dataTables_paginate li.next > a,
.pagination li.next > span, .dataTables_paginate li.next > span {
  border-right: none;
}

.pagination li.disabled > a, .dataTables_paginate li.disabled > a,
.pagination li.disabled > span, .dataTables_paginate li.disabled > span {
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
  border-right-color: #dee2e5;
  cursor: not-allowed;
}

.pagination li.disabled > a:hover, .dataTables_paginate li.disabled > a:hover, .pagination li.disabled > a:focus, .dataTables_paginate li.disabled > a:focus, .pagination li.disabled > a:active, .dataTables_paginate li.disabled > a:active,
.pagination li.disabled > span:hover, .dataTables_paginate li.disabled > span:hover,
.pagination li.disabled > span:focus, .dataTables_paginate li.disabled > span:focus,
.pagination li.disabled > span:active, .dataTables_paginate li.disabled > span:active {
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
}

@media (max-width: 767px) {
  .pagination li.next, .dataTables_paginate li.next, .pagination li.previous, .dataTables_paginate li.previous {
    background-color: #d6dbdf;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    border-radius: 0 6px 6px 0;
  }
  .pagination li.previous, .dataTables_paginate li.previous {
    left: 0;
    right: auto;
    border-radius: 6px 0 0 6px;
  }
}

.pagination li > a, .dataTables_paginate li > a,
.pagination li > span, .dataTables_paginate li > span {
  display: inline-block;
  background: transparent;
  border: none;
  border-left: 2px solid #e4e7ea;
  color: white;
  font-size: 14px;
  line-height: 16px;
  min-height: 41px;
  min-width: 41px;
  outline: none;
  padding: 12px 10px;
  text-align: center;
  transition: .25s ease-out;
}

.pagination li > a:hover, .dataTables_paginate li > a:hover, .pagination li > a:focus, .dataTables_paginate li > a:focus,
.pagination li > span:hover, .dataTables_paginate li > span:hover,
.pagination li > span:focus, .dataTables_paginate li > span:focus {
  background-color: #1abc9c;
  color: white;
}

.pagination li > a:active, .dataTables_paginate li > a:active,
.pagination li > span:active, .dataTables_paginate li > span:active {
  background-color: #1abc9c;
  color: white;
}

.pagination > .btn.previous, .dataTables_paginate > .btn.previous, .pagination > .btn.next, .dataTables_paginate > .btn.next {
  margin-right: 8px;
  font-size: 14px;
  line-height: 1.429;
  padding-left: 23px;
  padding-right: 23px;
}

.pagination > .btn.previous [class*="fui-"], .dataTables_paginate > .btn.previous [class*="fui-"], .pagination > .btn.next [class*="fui-"], .dataTables_paginate > .btn.next [class*="fui-"] {
  font-size: 16px;
  margin-left: -2px;
  margin-top: -2px;
}

.pagination > .btn.next, .dataTables_paginate > .btn.next {
  margin-left: 8px;
  margin-right: 0;
}

.pagination > .btn.next [class*="fui-"], .dataTables_paginate > .btn.next [class*="fui-"] {
  margin-right: -2px;
  margin-left: 4px;
}

@media (max-width: 767px) {
  .pagination > .btn, .dataTables_paginate > .btn {
    display: block;
    margin: 0;
    width: 50%;
  }
  .pagination > .btn:first-child, .dataTables_paginate > .btn:first-child {
    border-bottom: 2px solid #dee2e5;
    border-radius: 6px 0 0;
  }
  .pagination > .btn:first-child.btn-primary, .dataTables_paginate > .btn:first-child.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn:first-child.btn-danger, .dataTables_paginate > .btn:first-child.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn:first-child.btn-warning, .dataTables_paginate > .btn:first-child.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn:first-child.btn-success, .dataTables_paginate > .btn:first-child.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn:first-child.btn-info, .dataTables_paginate > .btn:first-child.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn:first-child.btn-inverse, .dataTables_paginate > .btn:first-child.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn:first-child > [class*="fui"], .dataTables_paginate > .btn:first-child > [class*="fui"] {
    margin-left: -20px;
  }
  .pagination > .btn + ul, .dataTables_paginate > .btn + ul {
    padding: 0;
    text-align: center;
    border-radius: 0 0 6px 6px;
  }
  .pagination > .btn + ul + .btn, .dataTables_paginate > .btn + ul + .btn {
    border-bottom: 2px solid #dee2e5;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 6px 0 0;
  }
  .pagination > .btn + ul + .btn.btn-primary, .dataTables_paginate > .btn + ul + .btn.btn-primary {
    border-bottom-color: #48c9b0;
  }
  .pagination > .btn + ul + .btn.btn-danger, .dataTables_paginate > .btn + ul + .btn.btn-danger {
    border-bottom-color: #ec7063;
  }
  .pagination > .btn + ul + .btn.btn-warning, .dataTables_paginate > .btn + ul + .btn.btn-warning {
    border-bottom-color: #f4d03f;
  }
  .pagination > .btn + ul + .btn.btn-success, .dataTables_paginate > .btn + ul + .btn.btn-success {
    border-bottom-color: #58d68d;
  }
  .pagination > .btn + ul + .btn.btn-info, .dataTables_paginate > .btn + ul + .btn.btn-info {
    border-bottom-color: #5dade2;
  }
  .pagination > .btn + ul + .btn.btn-inverse, .dataTables_paginate > .btn + ul + .btn.btn-inverse {
    border-bottom-color: #5d6d7e;
  }
  .pagination > .btn + ul + .btn > [class*="fui"], .dataTables_paginate > .btn + ul + .btn > [class*="fui"] {
    margin-right: -20px;
  }
  .pagination ul, .dataTables_paginate ul {
    display: block;
  }
  .pagination ul > li > a, .dataTables_paginate ul > li > a {
    border-radius: 0;
  }
}

.pagination-danger ul {
  background-color: #e74c3c;
}

.pagination-danger ul li.previous > a {
  border-right-color: #ef897e;
}

.pagination-danger ul li > a, .pagination-danger ul li > span {
  border-left-color: #ef897e;
}

.pagination-danger ul li > a:hover, .pagination-danger ul li > a:focus, .pagination-danger ul li > span:hover, .pagination-danger ul li > span:focus {
  background-color: #ec7063;
}

.pagination-danger ul li > a:active, .pagination-danger ul li > span:active {
  background-color: #c44133;
}

.pagination-danger ul li.active > a, .pagination-danger ul li.active > span {
  background-color: #c44133;
}

.pagination-success ul {
  background-color: #2ecc71;
}

.pagination-success ul li.previous > a {
  border-right-color: #75dda1;
}

.pagination-success ul li > a, .pagination-success ul li > span {
  border-left-color: #75dda1;
}

.pagination-success ul li > a:hover, .pagination-success ul li > a:focus, .pagination-success ul li > span:hover, .pagination-success ul li > span:focus {
  background-color: #58d68d;
}

.pagination-success ul li > a:active, .pagination-success ul li > span:active {
  background-color: #27ad60;
}

.pagination-success ul li.active > a, .pagination-success ul li.active > span {
  background-color: #27ad60;
}

.pagination-warning ul {
  background-color: #f1c40f;
}

.pagination-warning ul li.previous > a {
  border-right-color: #f6d861;
}

.pagination-warning ul li > a, .pagination-warning ul li > span {
  border-left-color: #f6d861;
}

.pagination-warning ul li > a:hover, .pagination-warning ul li > a:focus, .pagination-warning ul li > span:hover, .pagination-warning ul li > span:focus {
  background-color: #f4d313;
}

.pagination-warning ul li > a:active, .pagination-warning ul li > span:active {
  background-color: #cda70d;
}

.pagination-warning ul li.active > a, .pagination-warning ul li.active > span {
  background-color: #cda70d;
}

.pagination-info ul {
  background-color: #3498db;
}

.pagination-info ul li.previous > a {
  border-right-color: #79bbe7;
}

.pagination-info ul li > a, .pagination-info ul li > span {
  border-left-color: #79bbe7;
}

.pagination-info ul li > a:hover, .pagination-info ul li > a:focus, .pagination-info ul li > span:hover, .pagination-info ul li > span:focus {
  background-color: #5dade2;
}

.pagination-info ul li > a:active, .pagination-info ul li > span:active {
  background-color: #2c81ba;
}

.pagination-info ul li.active > a, .pagination-info ul li.active > span {
  background-color: #2c81ba;
}

.pagination-inverse ul {
  background-color: #34495e;
}

.pagination-inverse ul li.previous > a {
  border-right-color: #798795;
}

.pagination-inverse ul li > a, .pagination-inverse ul li > span {
  border-left-color: #798795;
}

.pagination-inverse ul li > a:hover, .pagination-inverse ul li > a:focus, .pagination-inverse ul li > span:hover, .pagination-inverse ul li > span:focus {
  background-color: #415b76;
}

.pagination-inverse ul li > a:active, .pagination-inverse ul li > span:active {
  background-color: #2c3e50;
}

.pagination-inverse ul li.active > a, .pagination-inverse ul li.active > span {
  background-color: #2c3e50;
}

.pagination-minimal > ul > li:first-child {
  border-radius: 6px 0 0 6px;
}

.pagination-minimal > ul > li:first-child.previous + li > a,
.pagination-minimal > ul > li:first-child.previous + li > span {
  border-left-width: 5px;
}

.pagination-minimal > ul > li:last-child {
  border-radius: 0 6px 6px 0;
}

.pagination-minimal > ul > li.previous > a,
.pagination-minimal > ul > li.previous > span, .pagination-minimal > ul > li.next > a,
.pagination-minimal > ul > li.next > span {
  background: transparent;
  border: none;
  border-right: 2px solid #e4e7ea;
  margin: 0 9px 0 0;
  padding: 12px 17px;
  border-radius: 6px 0 0 6px;
}

.pagination-minimal > ul > li.previous > a, .pagination-minimal > ul > li.previous > a:hover, .pagination-minimal > ul > li.previous > a:focus,
.pagination-minimal > ul > li.previous > span,
.pagination-minimal > ul > li.previous > span:hover,
.pagination-minimal > ul > li.previous > span:focus, .pagination-minimal > ul > li.next > a, .pagination-minimal > ul > li.next > a:hover, .pagination-minimal > ul > li.next > a:focus,
.pagination-minimal > ul > li.next > span,
.pagination-minimal > ul > li.next > span:hover,
.pagination-minimal > ul > li.next > span:focus {
  border-color: #e4e7ea !important;
}

@media (max-width: 767px) {
  .pagination-minimal > ul > li.previous > a,
  .pagination-minimal > ul > li.previous > span, .pagination-minimal > ul > li.next > a,
  .pagination-minimal > ul > li.next > span {
    margin-right: 0;
  }
}

.pagination-minimal > ul > li.next {
  margin-left: 9px;
}

.pagination-minimal > ul > li.next > a,
.pagination-minimal > ul > li.next > span {
  border-left: 2px solid #e4e7ea;
  border-right: none;
  margin: 0;
  border-radius: 0 6px 6px 0;
}

.pagination-minimal > ul > li.active > a,
.pagination-minimal > ul > li.active > span {
  background-color: white;
  border-color: white;
  border-width: 2px !important;
  color: #d6dbdf;
  margin: 10px 5px 9px;
}

.pagination-minimal > ul > li.active > a:hover, .pagination-minimal > ul > li.active > a:focus,
.pagination-minimal > ul > li.active > span:hover,
.pagination-minimal > ul > li.active > span:focus {
  background-color: white;
  border-color: white;
  color: #d6dbdf;
}

.pagination-minimal > ul > li.active.previous, .pagination-minimal > ul > li.active.next {
  border-color: #e4e7ea;
}

.pagination-minimal > ul > li.active.previous {
  margin-right: 6px;
}

.pagination-minimal > ul > li > a,
.pagination-minimal > ul > li > span {
  background: white;
  border: 5px solid #d6dbdf;
  color: white;
  line-height: 16px;
  margin: 7px 2px 6px;
  min-width: 0;
  min-height: 16px;
  padding: 0 4px;
  border-radius: 50px;
  background-clip: padding-box;
  transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
}

.pagination-minimal > ul > li > a:hover, .pagination-minimal > ul > li > a:focus,
.pagination-minimal > ul > li > span:hover,
.pagination-minimal > ul > li > span:focus {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: white;
  transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
}

.pagination-minimal > ul > li > a:active,
.pagination-minimal > ul > li > span:active {
  background-color: #16a085;
  border-color: #16a085;
}

.pagination-plain {
  font-size: 16px;
  font-weight: 700;
  list-style-type: none;
  margin: 0 0 20px;
  padding: 0;
  height: 57px;
}

.pagination-plain > li {
  display: inline;
}

.pagination-plain > li.previous {
  padding-right: 23px;
}

.pagination-plain > li.next {
  padding-left: 20px;
}

.pagination-plain > li.active > a {
  color: #d3d7da;
}

.pagination-plain > li > a {
  padding: 0 5px;
}

@media (max-width: 480px) {
  .pagination-plain {
    overflow: hidden;
    text-align: center;
  }
  .pagination-plain > li.previous {
    display: block;
    margin-bottom: 10px;
    text-align: left;
    width: 50%;
  }
  .pagination-plain > li.next {
    float: right;
    margin-top: -64px;
    text-align: right;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .pagination-plain {
    height: auto;
  }
}

.pagination-dropdown ul {
  min-width: 67px;
  width: auto;
  left: 50%;
  margin-left: -34px;
}

.pagination-dropdown ul li {
  display: block;
  margin-right: 0;
}

.pagination-dropdown ul li:first-child > a,
.pagination-dropdown ul li:first-child > span {
  border-radius: 6px 6px 0 0;
}

.pagination-dropdown ul li:last-child > a,
.pagination-dropdown ul li:last-child > span {
  border-radius: 0 0 6px 6px !important;
}

.pagination-dropdown ul li > a,
.pagination-dropdown ul li > span {
  border-left: none;
  display: block;
  float: none;
  padding: 8px 10px 7px;
  text-align: center;
  min-height: 0;
}

.pagination-dropdown.dropup {
  position: relative;
}

.pagination-dropdown.place-in-row .dropdown-toggle {
  display: none;
}

.pagination-dropdown.place-in-row ul.dropdown-menu, .pagination-dropdown.place-in-row ul.select2-drop {
  background: transparent;
  border: none;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  position: relative;
  max-width: auto;
  border-radius: 0;
  display: inline-block;
  margin-left: auto;
  margin-bottom: auto;
  vertical-align: middle;
  word-spacing: normal;
  z-index: 1;
}

.pagination-dropdown.place-in-row ul.dropdown-menu > li, .pagination-dropdown.place-in-row ul.select2-drop > li {
  display: inline-block;
  margin-right: -3px;
}

.pagination-dropdown.place-in-row ul.dropdown-menu > li > a, .pagination-dropdown.place-in-row ul.select2-drop > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-left: 2px solid #e4e7ea;
  color: white;
}

.pagination-dropdown.place-in-row ul.dropdown-menu > li > a:hover, .pagination-dropdown.place-in-row ul.select2-drop > li > a:hover, .pagination-dropdown.place-in-row ul.dropdown-menu > li > a:focus, .pagination-dropdown.place-in-row ul.select2-drop > li > a:focus {
  background-color: #1abc9c;
  color: white;
}

.tooltip {
  font-size: 14px;
  line-height: 1.286;
  z-index: 1070;
}

.tooltip.in {
  opacity: 1;
  filter: "alpha(opacity=100)";
}

.tooltip.top {
  margin-top: -5px;
  padding: 9px 0;
}

.tooltip.right {
  margin-left: 5px;
  padding: 0 9px;
}

.tooltip.bottom {
  margin-top: 5px;
  padding: 9px 0;
}

.tooltip.left {
  margin-left: -5px;
  padding: 0 9px;
}

.tooltip-inner {
  max-width: 183px;
  line-height: 1.286;
  padding: 12px 12px;
  color: white;
  background-color: #34495e;
  border-radius: 6px;
}

.tooltip.bs-tooltip-top .arrow:before {
  margin-left: -9px;
  border-width: 9px 9px 0;
  border-top-color: #34495e;
}

.tooltip.bs-tooltip-right .arrow:before {
  margin-top: -9px;
  border-width: 9px 9px 9px 0;
  border-right-color: #34495e;
}

.tooltip.bs-tooltip-left .arrow:before {
  margin-top: -9px;
  border-width: 9px 0 9px 9px;
  border-left-color: #34495e;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  margin-left: -9px;
  border-width: 0 9px 9px;
  border-bottom-color: #34495e;
}

.dropdown-toggle:after {
  margin-left: 8px;
  vertical-align: middle;
  content: "";
  border-top: 8px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 0;
  transition: border-color .25s, color .25s;
}

.dropup .dropdown-toggle:after {
  border-top: 0;
  border-right: 6px solid transparent;
  border-bottom: 8px solid;
  border-left: 6px solid transparent;
  margin: 0;
  vertical-align: middle;
}

.dropdown-menu, .select2-drop {
  z-index: 1000;
  background-color: #f3f4f5;
  min-width: 220px;
  border: none;
  margin-top: 9px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
}

.dropdown-menu .divider, .select2-drop .divider {
  height: 2px;
  margin: 3px 0;
  overflow: hidden;
  background-color: rgba(202, 206, 209, 0.5);
}

.dropdown-menu > li > a, .select2-drop > li > a {
  padding: 8px 16px;
  line-height: 1.429;
  color: #606d7a;
  display: block;
}

.dropdown-menu > li > a:hover, .select2-drop > li > a:hover, .dropdown-menu > li > a:focus, .select2-drop > li > a:focus {
  color: #55606c;
  background-color: rgba(202, 206, 209, 0.5);
}

.dropdown-menu > li:first-child > a:first-child, .select2-drop > li:first-child > a:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.dropdown-menu > li:last-child > a:first-child, .select2-drop > li:last-child > a:first-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dropdown-menu.typeahead, .typeahead.select2-drop {
  display: none;
  width: auto;
  margin-top: 5px;
  border: 2px solid #1abc9c;
  padding: 5px 0;
  background-color: white;
  border-radius: 6px;
}

.dropdown-menu.typeahead li a, .typeahead.select2-drop li a {
  padding: 6px 14px;
}

.dropdown-menu.typeahead li:first-child a, .typeahead.select2-drop li:first-child a, .dropdown-menu.typeahead li:last-child a, .typeahead.select2-drop li:last-child a {
  padding: 6px 14px;
  border-radius: 0;
}

.dropdown-menu > .active > a, .select2-drop > .active > a, .dropdown-menu > .active > a:hover, .select2-drop > .active > a:hover, .dropdown-menu > .active > a:focus, .select2-drop > .active > a:focus {
  color: white;
  background-color: #1abc9c;
}

.dropdown-menu > .disabled > a, .select2-drop > .disabled > a, .dropdown-menu > .disabled > a:hover, .select2-drop > .disabled > a:hover, .dropdown-menu > .disabled > a:focus, .select2-drop > .disabled > a:focus {
  color: #bdc3c7;
  background-color: transparent;
  cursor: not-allowed;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  padding: 8px 16px;
  line-height: 1.538;
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(52, 73, 94, 0.6);
}

.dropdown-header:first-child {
  margin-top: 3px;
}

.dropdown-backdrop {
  z-index: 990;
}

.dropup .dropdown-menu, .dropup .select2-drop,
.navbar-fixed-bottom .dropdown .dropdown-menu,
.navbar-fixed-bottom .dropdown .select2-drop {
  margin-top: 0;
  margin-bottom: 9px;
}

.dropdown-menu-inverse {
  background-color: #34495e;
}

.dropdown-menu-inverse .divider {
  height: 2px;
  margin: 3px 0;
  overflow: hidden;
  background-color: rgba(43, 60, 78, 0.5);
}

.dropdown-menu-inverse > li > a {
  color: rgba(255, 255, 255, 0.85);
}

.dropdown-menu-inverse > li > a:hover, .dropdown-menu-inverse > li > a:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(43, 60, 78, 0.5);
}

.dropdown-menu-inverse > .active > a, .dropdown-menu-inverse > .active > a:hover, .dropdown-menu-inverse > .active > a:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: #1abc9c;
}

.dropdown-menu-inverse > .disabled > a, .dropdown-menu-inverse > .disabled > a:hover, .dropdown-menu-inverse > .disabled > a:focus {
  color: rgba(255, 255, 255, 0.5);
}

.dropdown-menu-inverse > .disabled > a:hover, .dropdown-menu-inverse > .disabled > a:focus {
  background-color: transparent;
}

.dropdown-menu-inverse .dropdown-header {
  color: rgba(255, 255, 255, 0.4);
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu, .navbar-right .select2-drop {
    left: auto;
    right: 0;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 220px;
  width: auto;
}

.form-group .select {
  width: 100%;
}

.form-group .select > .select2-choice {
  width: 100%;
}

.select.form-control, .select2-search input.select[type="text"] {
  border: none;
  padding: 0;
  height: auto;
}

.select2-choice {
  width: 100%;
  display: inline-block;
  position: relative;
  border: none;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.4;
  border-radius: 4px;
  padding: 10px 39px 10px 15px;
  min-height: 41px;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.select2-choice:hover, .select2-choice:focus {
  outline: none;
}

.select2-choice:active {
  outline: none;
  box-shadow: none;
}

.select2-container-disabled .select2-choice {
  cursor: default;
  opacity: 0.7;
  filter: "alpha(opacity=70)";
}

.select2-chosen {
  overflow: hidden;
  text-align: left;
}

.select2-arrow {
  display: inline-block;
  border-width: 8px 6px;
  border-color: #34495e transparent;
  border-style: solid;
  border-bottom-style: none;
  position: absolute;
  right: 16px;
  top: 42%;
  transform: scale(1.001);
}

.select2-arrow b {
  display: none;
}

.btn-lg .select2-arrow, .btn-group-lg > .btn .select2-arrow {
  border-top-width: 8px;
  border-right-width: 6px;
  border-left-width: 6px;
}

.select-default .select2-choice {
  color: white;
  background-color: #bdc3c7;
}

.select-default .select2-choice:hover, .select-default .select2-choice.hover, .select-default .select2-choice:focus, .select-default .select2-choice:active {
  color: white;
  background-color: #cacfd2;
  border-color: #cacfd2;
}

.select-default .select2-choice:active {
  background: #a1a6a9;
  border-color: #a1a6a9;
}

.select2-container-disabled.select-default .select2-choice, .select2-container-disabled.select-default .select2-choice:hover, .select2-container-disabled.select-default .select2-choice:focus, .select2-container-disabled.select-default .select2-choice:active {
  background-color: white;
  border-color: #bdc3c7;
}

.select-default .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-primary .select2-choice {
  color: white;
  background-color: #1abc9c;
}

.select-primary .select2-choice:hover, .select-primary .select2-choice.hover, .select-primary .select2-choice:focus, .select-primary .select2-choice:active {
  color: white;
  background-color: #48c9b0;
  border-color: #48c9b0;
}

.select-primary .select2-choice:active {
  background: #16a085;
  border-color: #16a085;
}

.select2-container-disabled.select-primary .select2-choice, .select2-container-disabled.select-primary .select2-choice:hover, .select2-container-disabled.select-primary .select2-choice:focus, .select2-container-disabled.select-primary .select2-choice:active {
  background-color: white;
  border-color: #1abc9c;
}

.select-primary .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-info .select2-choice {
  color: white;
  background-color: #3498db;
}

.select-info .select2-choice:hover, .select-info .select2-choice.hover, .select-info .select2-choice:focus, .select-info .select2-choice:active {
  color: white;
  background-color: #5dade2;
  border-color: #5dade2;
}

.select-info .select2-choice:active {
  background: #2c81ba;
  border-color: #2c81ba;
}

.select2-container-disabled.select-info .select2-choice, .select2-container-disabled.select-info .select2-choice:hover, .select2-container-disabled.select-info .select2-choice:focus, .select2-container-disabled.select-info .select2-choice:active {
  background-color: white;
  border-color: #3498db;
}

.select-info .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-danger .select2-choice {
  color: white;
  background-color: #e74c3c;
}

.select-danger .select2-choice:hover, .select-danger .select2-choice.hover, .select-danger .select2-choice:focus, .select-danger .select2-choice:active {
  color: white;
  background-color: #ec7063;
  border-color: #ec7063;
}

.select-danger .select2-choice:active {
  background: #c44133;
  border-color: #c44133;
}

.select2-container-disabled.select-danger .select2-choice, .select2-container-disabled.select-danger .select2-choice:hover, .select2-container-disabled.select-danger .select2-choice:focus, .select2-container-disabled.select-danger .select2-choice:active {
  background-color: white;
  border-color: #e74c3c;
}

.select-danger .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-success .select2-choice {
  color: white;
  background-color: #2ecc71;
}

.select-success .select2-choice:hover, .select-success .select2-choice.hover, .select-success .select2-choice:focus, .select-success .select2-choice:active {
  color: white;
  background-color: #58d68d;
  border-color: #58d68d;
}

.select-success .select2-choice:active {
  background: #27ad60;
  border-color: #27ad60;
}

.select2-container-disabled.select-success .select2-choice, .select2-container-disabled.select-success .select2-choice:hover, .select2-container-disabled.select-success .select2-choice:focus, .select2-container-disabled.select-success .select2-choice:active {
  background-color: white;
  border-color: #2ecc71;
}

.select-success .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-warning .select2-choice {
  color: white;
  background-color: #f1c40f;
}

.select-warning .select2-choice:hover, .select-warning .select2-choice.hover, .select-warning .select2-choice:focus, .select-warning .select2-choice:active {
  color: white;
  background-color: #f4d313;
  border-color: #f4d313;
}

.select-warning .select2-choice:active {
  background: #cda70d;
  border-color: #cda70d;
}

.select2-container-disabled.select-warning .select2-choice, .select2-container-disabled.select-warning .select2-choice:hover, .select2-container-disabled.select-warning .select2-choice:focus, .select2-container-disabled.select-warning .select2-choice:active {
  background-color: white;
  border-color: #f1c40f;
}

.select-warning .select2-choice .select2-arrow {
  border-top-color: white;
}

.select-inverse .select2-choice {
  color: white;
  background-color: #34495e;
}

.select-inverse .select2-choice:hover, .select-inverse .select2-choice.hover, .select-inverse .select2-choice:focus, .select-inverse .select2-choice:active {
  color: white;
  background-color: #415b76;
  border-color: #415b76;
}

.select-inverse .select2-choice:active {
  background: #2c3e50;
  border-color: #2c3e50;
}

.select2-container-disabled.select-inverse .select2-choice, .select2-container-disabled.select-inverse .select2-choice:hover, .select2-container-disabled.select-inverse .select2-choice:focus, .select2-container-disabled.select-inverse .select2-choice:active {
  background-color: white;
  border-color: #34495e;
}

.select-inverse .select2-choice .select2-arrow {
  border-top-color: white;
}

.select2-container.select-hg > .select2-choice {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
  border-radius: 6px;
  padding-right: 49px;
  min-height: 53px;
}

.select2-container.select-hg > .select2-choice .filter-option {
  left: 20px;
  right: 40px;
  top: 13px;
}

.select2-container.select-hg > .select2-choice .select2-arrow {
  right: 20px;
}

.select2-container.select-hg > .select2-choice > [class^="fui-"] {
  top: 2px;
}

.select2-container.select-lg > .select2-choice {
  padding: 10px 19px;
  font-size: 17px;
  line-height: 1.471;
  border-radius: 6px;
  padding-right: 47px;
  min-height: 45px;
}

.select2-container.select-lg > .select2-choice .filter-option {
  left: 18px;
  right: 38px;
}

.select2-container.select-sm > .select2-choice {
  padding: 9px 13px;
  font-size: 13px;
  line-height: 1.385;
  border-radius: 4px;
  padding-right: 35px;
  min-height: 36px;
}

.select2-container.select-sm > .select2-choice .filter-option {
  left: 13px;
  right: 33px;
}

.select2-container.select-sm > .select2-choice .select2-arrow {
  right: 13px;
}

.multiselect {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 220px;
  width: auto;
  background-color: white;
  border-radius: 6px;
  text-align: left;
  font-size: 0;
  width: auto;
  max-width: none;
}

.form-group .multiselect {
  width: 100%;
}

.form-group .multiselect > .select2-choice {
  width: 100%;
}

.multiselect.form-control, .select2-search input.multiselect[type="text"] {
  height: auto;
  padding: 6px 1px 1px 6px;
  border: 2px solid #ebedef;
}

.select2-choices {
  margin: 0;
  padding: 0;
  position: relative;
  cursor: text;
  overflow: hidden;
  min-height: 26px;
}

.select2-choices:before, .select2-choices:after {
  content: " ";
  display: table;
}

.select2-choices:after {
  clear: both;
}

.select2-choices li {
  float: left;
  list-style: none;
}

.select2-search-choice {
  border-radius: 4px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 5px 4px 0;
  line-height: 15px;
  height: 27px;
  padding: 6px 21px;
  transition: .25s linear;
}

.select2-search-choice:hover {
  padding-right: 28px;
  padding-left: 14px;
  color: white;
}

.select2-search-choice:hover .select2-search-choice-close {
  opacity: 1;
  filter: none;
  color: inherit;
}

.select2-container-disabled .select2-search-choice {
  cursor: default;
}

.select2-container-disabled .select2-search-choice:hover {
  padding-right: 21px;
  padding-left: 21px;
  cursor: default;
}

.select2-search-choice .select2-search-choice-close {
  color: white;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0;
  text-align: right;
  text-decoration: none;
  top: 0;
  width: 100%;
  bottom: 0;
  padding-right: 10px;
  z-index: 2;
  opacity: 0;
  filter: "alpha(opacity=0)";
  transition: opacity .25s linear;
}

.select2-search-choice .select2-search-choice-close:after {
  content: "\e609";
  font-family: "Flat-UI-Pro-Icons";
  line-height: 27px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select2-container-disabled .select2-search-choice .select2-search-choice-close {
  display: none;
}

.select2-search-field input[type="text"] {
  color: #34495e;
  font-size: 14px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: auto;
  max-width: inherit;
  min-width: 80px;
  vertical-align: top;
  height: 29px;
}

.select2-search-field:first-child input[type="text"] {
  height: 23px;
  margin: 3px 0 5px;
}

.select2-container-multi.multiselect-default {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-default .select2-search-choice {
  background-color: #bdc3c7;
}

.select2-container-multi.multiselect-default .select2-search-choice:hover {
  background-color: #cacfd2;
}

.select2-container-disabled.select2-container-multi.multiselect-default .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-default .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-default .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-default .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-default {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-primary {
  border-color: #1abc9c;
}

.select2-container-multi.multiselect-primary .select2-search-choice {
  background-color: #1abc9c;
}

.select2-container-multi.multiselect-primary .select2-search-choice:hover {
  background-color: #48c9b0;
}

.select2-container-disabled.select2-container-multi.multiselect-primary .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-primary .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-primary .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-primary .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-primary {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-info {
  border-color: #3498db;
}

.select2-container-multi.multiselect-info .select2-search-choice {
  background-color: #3498db;
}

.select2-container-multi.multiselect-info .select2-search-choice:hover {
  background-color: #5dade2;
}

.select2-container-disabled.select2-container-multi.multiselect-info .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-info .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-info .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-info .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-info {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-danger {
  border-color: #e74c3c;
}

.select2-container-multi.multiselect-danger .select2-search-choice {
  background-color: #e74c3c;
}

.select2-container-multi.multiselect-danger .select2-search-choice:hover {
  background-color: #ec7063;
}

.select2-container-disabled.select2-container-multi.multiselect-danger .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-danger .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-danger .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-danger .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-danger {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-success {
  border-color: #2ecc71;
}

.select2-container-multi.multiselect-success .select2-search-choice {
  background-color: #2ecc71;
}

.select2-container-multi.multiselect-success .select2-search-choice:hover {
  background-color: #58d68d;
}

.select2-container-disabled.select2-container-multi.multiselect-success .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-success .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-success .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-success .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-success {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-warning {
  border-color: #f1c40f;
}

.select2-container-multi.multiselect-warning .select2-search-choice {
  background-color: #f1c40f;
}

.select2-container-multi.multiselect-warning .select2-search-choice:hover {
  background-color: #f4d313;
}

.select2-container-disabled.select2-container-multi.multiselect-warning .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-warning .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-warning .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-warning .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-warning {
  border-color: #bdc3c7;
}

.select2-container-multi.multiselect-inverse {
  border-color: #34495e;
}

.select2-container-multi.multiselect-inverse .select2-search-choice {
  background-color: #34495e;
}

.select2-container-multi.multiselect-inverse .select2-search-choice:hover {
  background-color: #415b76;
}

.select2-container-disabled.select2-container-multi.multiselect-inverse .select2-search-choice, .select2-container-disabled.select2-container-multi.multiselect-inverse .select2-search-choice:hover, .select2-container-disabled.select2-container-multi.multiselect-inverse .select2-search-choice:focus, .select2-container-disabled.select2-container-multi.multiselect-inverse .select2-search-choice:active {
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.select2-container-disabled.select2-container-multi.multiselect-inverse {
  border-color: #bdc3c7;
}

.select2-drop {
  min-width: 220px;
  margin-top: 9px;
  visibility: visible;
  opacity: 1;
  filter: none;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  transition: none;
}

.select2-drop.select2-drop-above {
  margin-top: -9px;
}

.select2-drop.select2-drop-auto-width {
  width: auto;
}

.select2-drop.show-select-search .select2-search {
  display: block;
}

.select2-drop.show-select-search .select2-search + .select2-results > li:first-child .select2-result-label {
  border-radius: 0;
}

.select2-drop .select2-results {
  padding: 0;
  margin: 0;
  list-style: none;
}

.select2-drop .select2-results > li:first-child > .select2-result-label {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.select2-drop .select2-results > li:last-child > .select2-result-label {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select2-drop .select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  user-select: none;
}

.select2-drop .select2-results .select2-result-label img {
  height: auto;
  max-width: 100%;
}

.select2-drop .select2-result-sub {
  padding: 0;
  margin: 0;
  list-style: none;
}

.select2-drop .select2-result-sub > li:last-child > .select2-result-label {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select2-drop .select2-no-results {
  padding: 8px 15px;
}

.select2-drop .select2-result-label {
  line-height: 1.429;
  padding: 8px 16px;
  user-select: none;
  transition: background-color .25s, color .25s;
}

.select2-drop .select2-result-selectable .select2-result-label {
  color: rgba(52, 73, 94, 0.85);
  cursor: pointer;
}

.select2-drop .select2-result-selectable .select2-result-label:focus, .select2-drop .select2-result-selectable .select2-result-label:hover, .select2-drop .select2-result-selectable .select2-result-label:active {
  background-color: #e1e4e7;
  color: inherit;
  outline: none;
}

.select2-drop .select2-disabled {
  cursor: default;
  color: rgba(52, 73, 94, 0.95);
  opacity: 0.4;
  filter: "alpha(opacity=40)";
}

.select2-drop .select2-disabled:focus, .select2-drop .select2-disabled:hover, .select2-drop .select2-disabled:active {
  background: none !important;
}

.select2-drop .select2-highlighted > .select2-result-label {
  background: #1abc9c;
  color: white;
}

.select2-drop .select2-result-with-children > .select2-result-label {
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(52, 73, 94, 0.6);
  margin-top: 5px;
}

.select2-drop .select2-result-with-children + .select2-result-with-children > .select2-result-label {
  margin-top: 11px;
}

.select2-results {
  max-height: 200px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-search {
  padding: 8px 6px;
  width: 100%;
  display: none;
  display: inline-block;
  white-space: nowrap;
}

.select2-search input[type="text"] {
  width: 100%;
  height: auto !important;
}

.select-inverse-dropdown {
  background-color: #34495e;
  color: rgba(255, 255, 255, 0.75);
}

.select-inverse-dropdown .select2-results .select2-result-label {
  color: white;
}

.select-inverse-dropdown .select2-results .select2-result-label:focus, .select-inverse-dropdown .select2-results .select2-result-label:hover, .select-inverse-dropdown .select2-results .select2-result-label:active {
  background: #2c3e50;
}

.select-inverse-dropdown .select2-results.select2-disabled .select2-result-label:hover {
  color: white;
}

.select-inverse-dropdown .select2-result-with-children > .select2-result-label {
  color: rgba(255, 255, 255, 0.6);
}

.select-inverse-dropdown .select2-result-with-children > .select2-result-label:hover {
  color: white;
  background: none !important;
}

.select2-drop-multi {
  border-radius: 6px;
}

.select2-drop-multi .select2-results {
  padding: 2px 0;
}

.select2-drop-multi .select2-result {
  padding: 2px 4px;
}

.select2-drop-multi .select2-result-label {
  border-radius: 4px;
}

.select2-drop-multi .select2-selected {
  display: none;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  padding: 10px 0 5px 10px;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.table {
  font-size: 14px;
  margin-bottom: 30px;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 9px;
  line-height: 1.407;
  border-top: 2px solid #eef0f0;
}

@media (min-width: 768px) {
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 9px 25px;
  }
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  color: #526476;
  border-bottom: 2px solid #eef0f0;
}

.table > thead > tr > th {
  border-bottom: 2px solid #eef0f0;
}

.table > tbody + tbody {
  border-top: 2px solid #eef0f0;
}

.table .table {
  background-color: #fff;
}

.table [class*="fui-"] {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: -42.5px 0 0;
  position: relative;
  top: 1em;
}

.table .checkbox,
.table .radio {
  display: inline-block;
  width: 20px;
  margin: 0;
  top: 0.25em;
}

.table .checkbox.no-label,
.table .radio.no-label {
  padding-left: 0;
}

.table .selected-row > td {
  background-color: #f2fbfa;
}

.table img {
  vertical-align: top;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px 14px;
}

.table-bordered {
  border: 2px solid #eef0f0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 2px solid #eef0f0;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
}

.table-striped > tbody > tr:nth-child(odd), .table-striped > tbody > tr.selected-row {
  background-color: #f9fafb;
}

.table-striped > tbody > tr:nth-child(even) {
  background-color: transparent;
}

.table-hover > tbody > tr:hover {
  background-color: #f9fafb;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f9fafb;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #f2f5f8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #ecfaf2;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #e4f8ec;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #edf6fc;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #e4f2fb;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fefae9;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #fef8df;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #fdefed;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #fce7e4;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    margin-bottom: 22.5px;
    border: 2px solid #eef0f0;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.badge {
  font-size: 76%;
  font-weight: normal;
  line-height: 1.15;
  padding: .25em .6em .29em;
  border-radius: .25em;
}

a.badge:hover, a.badge:focus {
  color: white;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge [class*="fui-"]:before {
  position: relative;
  top: 2px;
  font-size: 16px;
}

.badge-default {
  background-color: #ebedef;
  color: #7b8996;
}

.badge-default[href] {
  color: #7b8996;
}

.badge-default[href]:hover, .badge-default[href]:focus {
  color: #7b8996;
  background-color: #e1e4e7;
}

.badge-inverse {
  background-color: #485b6e;
  color: white;
}

.badge-inverse[href] {
  color: white;
}

.badge-inverse[href]:hover, .badge-inverse[href]:focus {
  color: white;
  background-color: #a4adb7;
}

.badge-success {
  background-color: #2ecc71;
  color: white;
}

.badge-success[href] {
  color: white;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: white;
  background-color: #27ad60;
}

.badge-important {
  background-color: #e74c3c;
  color: white;
}

.badge-important[href] {
  color: white;
}

.badge-important[href]:hover, .badge-important[href]:focus {
  color: white;
  background-color: #c44133;
}

.badge-warning {
  background-color: #f1c40f;
  color: white;
}

.badge-warning[href] {
  color: white;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: white;
  background-color: #cda70d;
}

.badge-info {
  background-color: #3498db;
  color: white;
}

.badge-info[href] {
  color: white;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: white;
  background-color: #2c81ba;
}

.badge-primary {
  background-color: #1abc9c;
  color: white;
}

.badge-primary[href] {
  color: white;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: white;
  background-color: #16a085;
}

.badge-light {
  font-size: 13px;
  font-weight: normal;
  color: white;
  line-height: 1.615;
  background-color: #d8dce0;
  border-radius: 4px;
  padding: 0 8px;
}

.btn .badge-light {
  position: relative;
  top: -1px;
}

a.badge-light:hover, a.badge-light:focus {
  color: white;
}

a.list-group-item > .badge-light,
.nav-pills > li > a > .badge-light {
  color: #9aa4af;
  background-color: white;
}

.alert {
  color: #34495e;
  padding: 18px 45px 14px 30px;
  border: 2px solid transparent;
  font-size: 16px;
  line-height: 1.625;
  border-radius: 6px;
}

.alert .alert-link {
  font-weight: bold;
}

.alert .alert-link:hover {
  text-decoration: underline;
}

.alert .alert-link.btn {
  color: white;
  text-decoration: none;
}

.alert .alert-link.btn:active, .alert .alert-link.btn.active {
  color: rgba(255, 255, 255, 0.75);
}

.alert h4 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 4px;
}

.alert p {
  font-size: inherit;
  line-height: inherit;
}

.alert .close {
  position: relative;
  top: 3px;
  right: -31px;
  line-height: 18px;
  font-size: 18px;
  color: #34495e;
}

.alert .btn {
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .alert .btn {
    display: block;
    min-width: auto;
    margin-bottom: 10px;
    margin-right: -15px;
  }
}

.alert-success {
  background-color: #eff8f3;
  border-color: #e2ebe9;
}

.alert-success .alert-link {
  color: #16a085;
}

.alert-info {
  background-color: #f7f9fa;
  border-color: #e9ecef;
}

.alert-info .alert-link {
  color: #3498db;
}

.alert-warning {
  background-color: #f9f8f3;
  border-color: #ebece8;
}

.alert-warning .alert-link {
  color: #f1c40f;
}

.alert-danger {
  background-color: #f9f6f5;
  border-color: #ebe9eb;
}

.alert-danger .alert-link {
  color: #e74c3c;
}

.bottom-menu {
  background-color: #ebedef;
  color: #bcc3ca;
  padding-top: 42px;
  padding-bottom: 42px;
  line-height: 1;
}

.bottom-menu .bottom-menu-brand {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  font-weight: 900;
  margin: 0 15px 15px;
  transition: none;
}

@media (min-width: 768px) {
  .bottom-menu .bottom-menu-brand {
    margin: 0;
  }
}

.bottom-menu .title {
  font-size: 13px;
  font-weight: 700;
  margin: 0 15px 10px;
  padding-top: 10px;
}

@media (min-width: 768px) {
  .bottom-menu .title {
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
  }
}

.bottom-menu a {
  color: inherit;
}

.active .bottom-menu a, .bottom-menu a:hover, .bottom-menu a:focus {
  color: #1abc9c;
}

.bottom-menu .label,
.bottom-menu .badge {
  vertical-align: middle;
}

.bottom-menu-inverse {
  background-color: #34495e;
  color: #677786;
}

.bottom-menu-inverse a {
  color: inherit;
}

.active .bottom-menu-inverse a, .bottom-menu-inverse a:hover, .bottom-menu-inverse a:focus {
  color: #1abc9c;
}

.bottom-menu-large {
  padding-bottom: 41px;
  padding-top: 56px;
}

@media (min-width: 768px) {
  .bottom-menu-large {
    padding-bottom: 52px;
  }
}

.bottom-menu-list,
.bottom-menu-iconic-list {
  margin: 0 15px;
  padding: 0;
  list-style: none;
}

.bottom-menu-list:before, .bottom-menu-list:after,
.bottom-menu-iconic-list:before,
.bottom-menu-iconic-list:after {
  content: " ";
  display: table;
}

.bottom-menu-list:after,
.bottom-menu-iconic-list:after {
  clear: both;
}

.bottom-menu-list li,
.bottom-menu-iconic-list li {
  display: block;
}

.bottom-menu-list a,
.bottom-menu-iconic-list a {
  display: inline-block;
  line-height: 24px;
}

.bottom-menu-list {
  font-size: 14px;
  line-height: 1.286;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .bottom-menu-list {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
  .bottom-menu-list > li {
    display: inline-block;
    margin-right: 23px;
  }
  .title + .bottom-menu-list li {
    display: block;
    margin-right: 0;
  }
}

.bottom-menu-iconic-list {
  font-size: 16px;
  line-height: 1;
}

.bottom-menu-iconic-list li {
  display: inline-block;
  margin-right: 18px;
}

.bottom-menu-iconic-list li:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .bottom-menu-iconic-list {
    margin-right: 0;
    margin-left: 0;
  }
}

.nav-pills > li:first-child > a {
  border-left: none;
  border-radius: 6px 0 0 6px;
}

.nav-pills > li:last-child > a {
  border-radius: 0 6px 6px 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover, .nav-pills > li.show > a,
.nav-pills > li.show > a:hover {
  background-color: #16a085;
}

.nav-pills > li.show > a, .nav-pills > li.show > a:hover, .nav-pills > li.show > a:focus {
  background-color: #16a085;
  border-color: #16a085;
}

@media (max-width: 767px) {
  .nav-pills > li {
    float: none;
    display: block;
  }
  .nav-pills > li:first-child > a {
    border-radius: 6px 6px 0 0;
  }
  .nav-pills > li:last-child > a {
    border-radius: 0 0 6px 6px;
  }
}

.nav-pills > li > a {
  background-color: #1abc9c;
  border-left: 2px solid #16a085;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 12px 21px 13px;
  border-radius: 0;
  display: block;
}

@media (max-width: 767px) {
  .nav-pills > li > a {
    border-left: none;
    display: block;
  }
}

.nav-pills > li > a:hover, .nav-pills > li > a:focus {
  background-color: #48c9b0;
}

.nav-pills > li > a:active {
  background-color: #16a085;
}

.nav-pills > li > a > .badge {
  margin: -4px 0 -4px 3px;
  position: relative;
  top: -1px;
  background-color: white;
}

.nav-pills > li + li {
  margin-left: 0;
}

.nav-stacked > li > a,
.nav-stacked > li:first-child > a,
.nav-stacked > li:last-child > a {
  border-left: none;
  border-radius: 6px;
}

.nav-stacked > li > a > .badge,
.nav-stacked > li:first-child > a > .badge,
.nav-stacked > li:last-child > a > .badge {
  top: 2px;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs > li {
  margin-bottom: -2px;
}

.nav-tabs > li > li:first-child a.active:before {
  display: block;
  background-color: white;
  border-left: 2px solid #e1e4e7;
  content: "";
  height: 6px;
  left: -2px;
  position: absolute;
  bottom: -4px;
  width: 6px;
  z-index: 2;
}

.nav-tabs > li > li:first-child.dropdown.active, .nav-tabs > li > li:first-child.dropdown.active.dropdown-toggle, .nav-tabs > li > li:first-child.dropdown.active:hover {
  color: #7f8c9a;
}

.nav-tabs > li > li:first-child.dropdown.active:after, .nav-tabs > li > li:first-child.dropdown.active.dropdown-toggle:after, .nav-tabs > li > li:first-child.dropdown.active:hover:after {
  border-bottom-color: #7f8c9a;
  border-top-color: #7f8c9a;
}

.nav-tabs > li > a {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 7px 21px 8px;
  color: #7f8c9a;
  border: 2px solid transparent;
  border-radius: 6px 6px 0 0;
  transition: none;
  line-height: 1.42857143;
  position: relative;
  display: block;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  color: #1abc9c;
}

.nav-tabs > li > a.active {
  z-index: 2;
}

.nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
  background-color: inherit;
  border-color: #e1e4e7;
  border-bottom-color: white;
  border-width: 2px;
  color: #7f8c9a;
}

.nav-tabs > li > a.active:before {
  display: none;
}

.nav-tabs > li > a.active.dropdown-toggle:after, .nav-tabs > li > a.active.dropdown-toggle:hover:after, .nav-tabs > li > a.active.dropdown-toggle:focus:after {
  border-bottom-color: #7f8c9a;
  border-top-color: #7f8c9a;
}

.nav-tabs > li.dropdown.show a.active {
  z-index: 1004;
}

.nav-tabs > li.dropdown.show a.active.dropdown-toggle, .nav-tabs > li.dropdown.show a.active.dropdown-toggle:hover {
  background-color: #f3f4f5;
  border-color: #e1e4e7;
  color: #7f8c9a;
}

@media (min-width: 768px) {
  .nav-tabs > li.dropdown.show a.active.dropdown-toggle, .nav-tabs > li.dropdown.show a.active.dropdown-toggle:hover {
    border-bottom-color: transparent;
  }
}

.nav-tabs > li.dropdown.show a.active.dropdown-toggle:after, .nav-tabs > li.dropdown.show a.active.dropdown-toggle:hover:after {
  border-bottom-color: #7f8c9a;
  border-top-color: #7f8c9a;
}

.nav-tabs > li.dropdown.show .dropdown-menu, .nav-tabs > li.dropdown.show .select2-drop {
  border: 2px solid #e1e4e7;
  border-radius: 0 6px 6px 6px;
  margin-top: -2px;
  z-index: 1003;
}

.nav-tabs > li.dropdown.show .dropdown-menu li:first-child > a, .nav-tabs > li.dropdown.show .select2-drop li:first-child > a {
  border-radius: 0 4px 0 0;
}

.nav-tabs > li.dropdown.show .dropdown-menu li:last-child > a, .nav-tabs > li.dropdown.show .select2-drop li:last-child > a {
  border-radius: 0 0 4px 4px;
}

.nav-tabs > li.dropdown.show .dropdown-menu li a, .nav-tabs > li.dropdown.show .select2-drop li a {
  border-radius: 0;
}

.nav-tabs > li .dropdown-toggle {
  position: relative;
  z-index: 1002;
}

.nav-tabs > li .dropdown-toggle:hover:after, .nav-tabs > li .dropdown-toggle:focus:after {
  border-bottom-color: #1abc9c;
  border-top-color: #1abc9c;
}

.tab-content.tab-side-content {
  border: none;
}

.nav-tabs.tabs-left, .nav-tabs.tabs-right {
  background: #f5f7f8;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-tabs.tabs-left > li, .nav-tabs.tabs-right > li {
  float: none;
}

.nav-tabs.tabs-left > li > a, .nav-tabs.tabs-right > li > a {
  padding: 13px 14px;
  background-color: transparent;
}

.nav-tabs.tabs-left > li.active, .nav-tabs.tabs-left > li.active:hover, .nav-tabs.tabs-left > li.active:focus, .nav-tabs.tabs-right > li.active, .nav-tabs.tabs-right > li.active:hover, .nav-tabs.tabs-right > li.active:focus {
  background-color: #fff;
  border-top: 2px solid #ebedef;
  border-bottom: 2px solid #ebedef;
}

.nav-tabs.tabs-left {
  padding-left: 15px;
  border-right: 2px solid #ebedef;
}

.nav-tabs.tabs-left > li > a {
  margin-right: -2px;
}

.nav-tabs.tabs-left > li.active, .nav-tabs.tabs-left > li.active:hover, .nav-tabs.tabs-left > li.active:focus {
  border-radius: 6px 0 0 6px;
  border-left: 2px solid #ebedef;
  border-right: none;
}

.nav-tabs.tabs-right {
  padding-right: 15px;
  border-left: 2px solid #ebedef;
}

.nav-tabs.tabs-right > li > a {
  margin-left: -2px;
}

.nav-tabs.tabs-right > li.active, .nav-tabs.tabs-right > li.active:hover, .nav-tabs.tabs-right > li.active:focus {
  border-radius: 0 6px 6px 0;
  border-right: 2px solid #ebedef;
  border-left: none;
}

.tab-content {
  border: 2px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 20px 20px 5px;
  position: relative;
  z-index: 1;
}

.nav.flex-column {
  padding-left: 13px;
  padding-right: 13px;
}

.nav.flex-column .nav-header {
  margin-left: -13px;
  margin-right: -13px;
  padding: 0 13px;
  font-weight: 700;
  line-height: 1.538;
}

.nav.flex-column > li {
  margin-bottom: 3px;
}

.nav.flex-column > li.active > a,
.nav.flex-column > li.active > a:hover,
.nav.flex-column > li.active > a:focus {
  background-color: #edeff1;
  color: #526476;
}

.nav.flex-column > li.disabled > a,
.nav.flex-column > li.disabled > a:hover,
.nav.flex-column > li.disabled > a:focus {
  opacity: 0.6;
  cursor: not-allowed;
}

.nav.flex-column > li.divider {
  height: 2px;
  border-bottom: none;
  margin: 13px -13px 17px 0;
  background-color: #edeff1;
}

.nav.flex-column > li > a {
  border-radius: 4px;
  font-size: 15px;
  line-height: 1.333;
  font-weight: 700;
  margin-left: -13px;
  margin-right: -13px;
  padding: 6px 13px 8px;
  text-shadow: none;
  display: block;
}

.nav.flex-column .badge.pull-right {
  margin: 1px -4px -1px 0;
}

.nav.flex-column.nav-list-vivid {
  background-color: #edeff1;
  border-radius: 4px;
  padding-bottom: 2px;
}

.nav.flex-column.nav-list-vivid > li {
  margin-bottom: 2px;
}

.nav.flex-column.nav-list-vivid > li.divider {
  background-color: transparent;
  margin: 3px 0 0;
}

.nav.flex-column.nav-list-vivid > li.active > a,
.nav.flex-column.nav-list-vivid > li.active > a:hover,
.nav.flex-column.nav-list-vivid > li.active > a:focus {
  background-color: #1abc9c;
  color: white;
}

.nav.flex-column.nav-list-vivid > li > a {
  border-radius: 0;
  color: #526476;
  font-size: 14px;
}

.nav.flex-column.nav-list-vivid > li > a:hover, .nav.flex-column.nav-list-vivid > li > a:focus {
  color: #16a085;
}

.nav.flex-column.nav-list-vivid .nav-header:first-child {
  padding-top: 10px;
}

.nav.flex-column.nav-list-bricks > li {
  margin-bottom: 7px;
}

.nav.flex-column.nav-list-bricks > li.active > a,
.nav.flex-column.nav-list-bricks > li.active > a:hover,
.nav.flex-column.nav-list-bricks > li.active > a:focus {
  background-color: #1abc9c;
  color: white;
}

.nav.flex-column.nav-list-bricks > li.disabled > a,
.nav.flex-column.nav-list-bricks > li.disabled > a:hover,
.nav.flex-column.nav-list-bricks > li.disabled > a:focus {
  background-color: #edeff1;
}

.nav.flex-column.nav-list-bricks > li > a {
  padding: 8px 10px 8px 14px;
  border-radius: 4px;
  background-color: #edeff1;
  color: #526476;
  font-size: 14px;
  line-height: 1.333;
  font-weight: 700;
}

.nav.flex-column.nav-list-bricks > li > a:hover, .nav.flex-column.nav-list-bricks > li > a:focus {
  color: #16a085;
}

.nav.flex-column.nav-list-bricks > li > a [class^="fui-"], .nav.flex-column.nav-list-bricks > li > a [class*=" fui-"] {
  color: white;
}

.nav-header {
  color: #aeb6bf;
  font-size: 13px;
  text-transform: uppercase;
}

.navbar {
  font-size: 16px;
  min-height: 53px;
  margin-bottom: 30px;
  border: none;
  border-radius: 6px;
}

@media (min-width: 992px) {
  .navbar {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  box-shadow: none;
}

.navbar-collapse .navbar-form:first-child {
  border: none;
}

@media (min-width: 768px) {
  .navbar-collapse {
    padding-right: 21px;
  }
  .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -21px;
  }
  .navbar-collapse .navbar-nav.navbar-left:first-child > li:first-child a {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -21px;
  }
  .navbar-collapse .navbar-nav.navbar-right:last-child > .dropdown:last-child > a {
    border-radius: 0 6px 6px 0;
  }
  .navbar-fixed-top .navbar-collapse .navbar-form.navbar-right:last-child,
  .navbar-fixed-bottom .navbar-collapse .navbar-form.navbar-right:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-bottom: 3px;
  }
}

.navbar .container,
.navbar .container-fluid {
  padding-left: 21px;
  padding-right: 21px;
}

.navbar .container > .navbar-header,
.navbar .container > .navbar-collapse,
.navbar .container-fluid > .navbar-header,
.navbar .container-fluid > .navbar-collapse {
  margin-right: -21px;
  margin-left: -21px;
}

@media (min-width: 768px) {
  .navbar .container > .navbar-header,
  .navbar .container > .navbar-collapse,
  .navbar .container-fluid > .navbar-header,
  .navbar .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0;
  border-radius: 0;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: 1030;
  border-radius: 0;
}

.navbar-fixed-top {
  border-width: 0;
}

.navbar-fixed-bottom {
  margin-bottom: 0;
  border-width: 0;
}

.navbar-brand {
  font-size: 24px;
  height: 53px;
  font-weight: 700;
  margin-right: 0;
}

@media (min-width: 768px) {
  .navbar-brand {
    line-height: 1.042;
    padding: 14px 21px;
  }
}

.navbar-brand > [class*="fui-"] {
  font-size: 19px;
  line-height: 1.263;
  vertical-align: top;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -21px;
  }
}

.navbar-toggler {
  border: none;
  color: #34495e;
  margin: 0 0 0 21px;
  padding: 0 21px;
  height: 53px;
  line-height: 53px;
  background: none;
}

.navbar-toggler:before {
  color: #16a085;
  content: "\e61a";
  font-family: "Flat-UI-Pro-Icons";
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .25s linear;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  outline: none;
}

.navbar-toggler:hover:before, .navbar-toggler:focus:before {
  color: #1abc9c;
}

.navbar-toggler .icon-bar {
  display: none;
}

.navbar-nav {
  margin: 0;
}

.navbar-nav > li > a {
  font-size: 16px;
  padding: 15px 0;
  line-height: 23px;
  font-weight: 700;
  position: relative;
  display: block;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-left: 21px;
    padding-right: 21px;
  }
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a:focus,
.navbar-nav .open > a:hover {
  background-color: transparent;
}

.navbar-nav [class^="fui-"] {
  line-height: 20px;
  position: relative;
  top: 1px;
}

@media (max-width: 1199.98px) {
  .navbar-nav [class^="fui-"] {
    margin-left: 5px;
  }
}

.navbar-nav .visible-sm > [class^="fui-"],
.navbar-nav .visible-xs > [class^="fui-"] {
  margin-left: 12px;
}

.navbar-input, .navbar-form .form-control, .navbar-form .select2-search input[type="text"], .select2-search .navbar-form input[type="text"],
.navbar-form .input-group-text,
.navbar-form .btn {
  height: 35px;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.4;
  border-radius: 6px;
}

.navbar-form .btn {
  margin: 0;
}

.navbar-form .input-group .form-control:first-child, .navbar-form .input-group .select2-search input[type="text"]:first-child, .select2-search .navbar-form .input-group input[type="text"]:first-child,
.navbar-form .input-group-text:first-child,
.navbar-form .input-group-btn:first-child > .btn,
.navbar-form .input-group-btn:first-child > .dropdown-toggle,
.navbar-form .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.navbar-form .input-group .form-control:last-child, .navbar-form .input-group .select2-search input[type="text"]:last-child, .select2-search .navbar-form .input-group input[type="text"]:last-child,
.navbar-form .input-group-text:last-child,
.navbar-form .input-group-btn:last-child > .btn,
.navbar-form .input-group-btn:last-child > .dropdown-toggle,
.navbar-form .input-group-btn:first-child > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.navbar-form .form-control, .navbar-form .select2-search input[type="text"], .select2-search .navbar-form input[type="text"] {
  font-size: 15px;
  border-radius: 5px;
  display: table-cell;
}

.navbar-form .form-group ~ .btn {
  font-size: 15px;
  border-radius: 5px;
  margin-left: 5px;
}

.navbar-form .form-group + .btn {
  margin-right: 5px;
}

@media (min-width: 768px) {
  .navbar-form .input-group {
    width: 195px;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 7px;
  }
  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
  .navbar-form .form-group + .btn {
    margin-left: 0;
  }
}

.navbar-nav > li > .dropdown-menu, .navbar-nav > li > .select2-drop {
  min-width: 100%;
  border-radius: 4px;
}

@media (max-width: 1199.98px) {
  .navbar-nav > li > .dropdown-menu, .navbar-nav > li > .select2-drop {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-nav > li.open > .dropdown-menu, .navbar-nav > li.open > .select2-drop {
    margin-top: 0 !important;
  }
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu, .navbar-fixed-bottom .navbar-nav > li > .select2-drop {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
  background-color: transparent;
}

.navbar-text {
  font-size: 16px;
  line-height: 1.438;
  color: #34495e;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .navbar-text {
    margin-left: 21px;
    margin-right: 21px;
  }
  .navbar-text.navbar-right:last-child {
    margin-right: 0;
  }
}

.navbar-btn {
  margin-top: 6px;
  margin-bottom: 6px;
}

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 9px;
  margin-bottom: 8px;
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-unread,
.navbar-new {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  background-color: #1abc9c;
  border-radius: 50%;
  color: white;
  font-size: 0;
  font-weight: 700;
  min-height: 6px;
  min-width: 6px;
  line-height: 1;
  text-align: center;
  z-index: 10;
  position: absolute;
  top: 35%;
  margin-left: 5px;
}

.active .navbar-unread, .active
.navbar-new {
  background-color: white;
  display: none;
}

.navbar-new {
  background-color: #e74c3c;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  min-width: 18px;
  padding: 0 1px;
  width: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translatey(-50%);
}

.navbar-default {
  background-color: #edf0f1;
}

.navbar-default .navbar-brand {
  color: #34495e;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}

.navbar-default .navbar-toggler:before {
  color: #34495e;
}

.navbar-default .navbar-toggler:hover, .navbar-default .navbar-toggler:focus {
  background-color: transparent;
}

.navbar-default .navbar-toggler:hover:before, .navbar-default .navbar-toggler:focus:before {
  color: #1abc9c;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e5e9ea;
  border-width: 2px;
}

.navbar-default .navbar-nav > li > a {
  color: #34495e;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #1abc9c;
  background-color: transparent;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #1abc9c;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a, .navbar-default .navbar-nav .open .select2-drop > li > a {
    color: #34495e;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .select2-drop > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .open .select2-drop > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .select2-drop > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .select2-drop > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-default .navbar-nav .open .select2-drop > .active > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .select2-drop > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .select2-drop > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus, .navbar-default .navbar-nav .open .select2-drop > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}

.navbar-default .navbar-form .form-control, .navbar-default .navbar-form .select2-search input[type="text"], .select2-search .navbar-default .navbar-form input[type="text"] {
  border-color: #fff;
}

.navbar-default .navbar-form .form-control::-moz-placeholder, .navbar-default .navbar-form .select2-search input[type="text"]::-moz-placeholder, .select2-search .navbar-default .navbar-form input[type="text"]::-moz-placeholder {
  color: #aeb5bf;
  opacity: 1;
}

.navbar-default .navbar-form .form-control:-ms-input-placeholder, .navbar-default .navbar-form .select2-search input[type="text"]:-ms-input-placeholder, .select2-search .navbar-default .navbar-form input[type="text"]:-ms-input-placeholder {
  color: #aeb5bf;
}

.navbar-default .navbar-form .form-control::-webkit-input-placeholder, .navbar-default .navbar-form .select2-search input[type="text"]::-webkit-input-placeholder, .select2-search .navbar-default .navbar-form input[type="text"]::-webkit-input-placeholder {
  color: #aeb5bf;
}

.navbar-default .navbar-form .form-control:focus, .navbar-default .navbar-form .select2-search input[type="text"]:focus, .select2-search .navbar-default .navbar-form input[type="text"]:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}

.navbar-default .navbar-form .form-control:first-child, .navbar-default .navbar-form .select2-search input[type="text"]:first-child, .select2-search .navbar-default .navbar-form input[type="text"]:first-child {
  border-right-width: 0;
}

.navbar-default .navbar-form .input-group-btn .btn {
  border-color: transparent;
  color: #afb6be;
}

.navbar-default .navbar-form .input-group.focus .form-control, .navbar-default .navbar-form .input-group.focus .select2-search input[type="text"], .select2-search .navbar-default .navbar-form .input-group.focus input[type="text"],
.navbar-default .navbar-form .input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  color: #1abc9c;
}

.navbar-default .navbar-text {
  color: #34495e;
}

.navbar-default .navbar-link {
  color: #34495e;
}

.navbar-default .navbar-link:hover {
  color: #1abc9c;
}

.navbar-default .btn-link {
  color: #34495e;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #1abc9c;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:hover,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #34495e;
}

.navbar-inverse .navbar-brand {
  color: white;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #1abc9c;
  background-color: transparent;
}

.navbar-inverse .navbar-toggler:before {
  color: white;
}

.navbar-inverse .navbar-toggler:hover, .navbar-inverse .navbar-toggler:focus {
  background-color: transparent;
}

.navbar-inverse .navbar-toggler:hover:before, .navbar-inverse .navbar-toggler:focus:before {
  color: #1abc9c;
}

.navbar-inverse .navbar-collapse {
  border-color: #2f4154;
  border-width: 2px;
}

.navbar-inverse .navbar-nav > li > a {
  color: white;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #1abc9c;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: white;
  background-color: #1abc9c;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #1abc9c;
  color: white;
  border-left-color: transparent;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu, .navbar-inverse .navbar-nav > .open > .select2-drop {
  background-color: #34495e;
  padding: 3px 4px;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a, .navbar-inverse .navbar-nav > .open > .select2-drop > li > a {
  color: #e1e4e7;
  border-radius: 4px;
  padding: 6px 9px;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav > .open > .select2-drop > li > a:hover, .navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav > .open > .select2-drop > li > a:focus {
  color: white;
  background-color: #1abc9c;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > .divider, .navbar-inverse .navbar-nav > .open > .select2-drop > .divider {
  background-color: #2f4154;
  height: 2px;
  margin-left: -4px;
  margin-right: -4px;
}

.navbar-inverse .navbar-nav .dropdown-toggle:after {
  border-top-color: #4b6075;
  border-bottom-color: #4b6075;
}

.navbar-inverse .navbar-nav .dropdown-toggle:hover:after, .navbar-inverse .navbar-nav .dropdown-toggle:focus:after {
  border-top-color: #1abc9c;
  border-bottom-color: #1abc9c;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav > li > a {
    border-left-width: 0;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a, .navbar-inverse .navbar-nav .open .select2-drop > li > a {
    color: white;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .select2-drop > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-inverse .navbar-nav .open .select2-drop > li > a:focus {
    color: #1abc9c;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .select2-drop > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .select2-drop > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-inverse .navbar-nav .open .select2-drop > .active > a:focus {
    color: white;
    background-color: #1abc9c;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .select2-drop > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .select2-drop > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus, .navbar-inverse .navbar-nav .open .select2-drop > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .dropdown-menu .divider, .navbar-inverse .navbar-nav .select2-drop .divider {
    background-color: #2f4154;
  }
}

.navbar-inverse .navbar-form .form-control, .navbar-inverse .navbar-form .select2-search input[type="text"], .select2-search .navbar-inverse .navbar-form input[type="text"] {
  color: #536a81;
  border-color: #293a4a;
  background-color: #293a4a;
}

.navbar-inverse .navbar-form .form-control::-moz-placeholder, .navbar-inverse .navbar-form .select2-search input[type="text"]::-moz-placeholder, .select2-search .navbar-inverse .navbar-form input[type="text"]::-moz-placeholder {
  color: #536a81;
  opacity: 1;
}

.navbar-inverse .navbar-form .form-control:-ms-input-placeholder, .navbar-inverse .navbar-form .select2-search input[type="text"]:-ms-input-placeholder, .select2-search .navbar-inverse .navbar-form input[type="text"]:-ms-input-placeholder {
  color: #536a81;
}

.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder, .navbar-inverse .navbar-form .select2-search input[type="text"]::-webkit-input-placeholder, .select2-search .navbar-inverse .navbar-form input[type="text"]::-webkit-input-placeholder {
  color: #536a81;
}

.navbar-inverse .navbar-form .form-control:focus, .navbar-inverse .navbar-form .select2-search input[type="text"]:focus, .select2-search .navbar-inverse .navbar-form input[type="text"]:focus {
  border-color: #1abc9c;
  color: #1abc9c;
}

.navbar-inverse .navbar-form .form-control:first-child, .navbar-inverse .navbar-form .select2-search input[type="text"]:first-child, .select2-search .navbar-inverse .navbar-form input[type="text"]:first-child {
  border-right-width: 0;
}

.navbar-inverse .navbar-form .btn {
  color: white;
  background-color: #1abc9c;
}

.show > .dropdown-toggle.navbar-inverse .navbar-form .btn, .navbar-inverse .navbar-form .btn:hover, .navbar-inverse .navbar-form .btn.hover, .navbar-inverse .navbar-form .btn:focus, .navbar-inverse .navbar-form .btn:active, .navbar-inverse .navbar-form .btn.active {
  color: white;
  background-color: #48c9b0;
  border-color: #48c9b0;
}

.show > .dropdown-toggle.navbar-inverse .navbar-form .btn, .navbar-inverse .navbar-form .btn:not(:disabled):not(.disabled):active, .navbar-inverse .navbar-form .btn:not(:disabled):not(.disabled).active {
  background: #16a085;
  border-color: #16a085;
}

.navbar-inverse .navbar-form .btn.disabled, .navbar-inverse .navbar-form .btn.disabled:hover, .navbar-inverse .navbar-form .btn.disabled.hover, .navbar-inverse .navbar-form .btn.disabled:focus, .navbar-inverse .navbar-form .btn.disabled:active, .navbar-inverse .navbar-form .btn.disabled.active, .navbar-inverse .navbar-form .btn[disabled], .navbar-inverse .navbar-form .btn[disabled]:hover, .navbar-inverse .navbar-form .btn[disabled].hover, .navbar-inverse .navbar-form .btn[disabled]:focus, .navbar-inverse .navbar-form .btn[disabled]:active, .navbar-inverse .navbar-form .btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-form .btn,
fieldset[disabled] .navbar-inverse .navbar-form .btn:hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn.hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn:focus,
fieldset[disabled] .navbar-inverse .navbar-form .btn:active,
fieldset[disabled] .navbar-inverse .navbar-form .btn.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}

.navbar-inverse .navbar-form .btn .badge {
  color: #1abc9c;
  background-color: white;
}

.navbar-inverse .navbar-form .input-group-btn .btn {
  border-color: transparent;
  background-color: #293a4a;
  color: #526a82;
}

.navbar-inverse .navbar-form .input-group.focus .form-control, .navbar-inverse .navbar-form .input-group.focus .select2-search input[type="text"], .select2-search .navbar-inverse .navbar-form .input-group.focus input[type="text"],
.navbar-inverse .navbar-form .input-group.focus .input-group-btn .btn {
  border-color: #1abc9c;
  color: #1abc9c;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-form {
    border-color: #2f4154;
    border-width: 2px 0;
  }
}

.navbar-inverse .navbar-text {
  color: white;
}

.navbar-inverse .navbar-text a {
  color: white;
}

.navbar-inverse .navbar-text a:hover, .navbar-inverse .navbar-text a:focus {
  color: #1abc9c;
}

.navbar-inverse .navbar-btn {
  color: white;
  background-color: #1abc9c;
}

.show > .dropdown-toggle.navbar-inverse .navbar-btn, .navbar-inverse .navbar-btn:hover, .navbar-inverse .navbar-btn.hover, .navbar-inverse .navbar-btn:focus, .navbar-inverse .navbar-btn:active, .navbar-inverse .navbar-btn.active {
  color: white;
  background-color: #48c9b0;
  border-color: #48c9b0;
}

.show > .dropdown-toggle.navbar-inverse .navbar-btn, .navbar-inverse .navbar-btn:not(:disabled):not(.disabled):active, .navbar-inverse .navbar-btn:not(:disabled):not(.disabled).active {
  background: #16a085;
  border-color: #16a085;
}

.navbar-inverse .navbar-btn.disabled, .navbar-inverse .navbar-btn.disabled:hover, .navbar-inverse .navbar-btn.disabled.hover, .navbar-inverse .navbar-btn.disabled:focus, .navbar-inverse .navbar-btn.disabled:active, .navbar-inverse .navbar-btn.disabled.active, .navbar-inverse .navbar-btn[disabled], .navbar-inverse .navbar-btn[disabled]:hover, .navbar-inverse .navbar-btn[disabled].hover, .navbar-inverse .navbar-btn[disabled]:focus, .navbar-inverse .navbar-btn[disabled]:active, .navbar-inverse .navbar-btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-btn,
fieldset[disabled] .navbar-inverse .navbar-btn:hover,
fieldset[disabled] .navbar-inverse .navbar-btn.hover,
fieldset[disabled] .navbar-inverse .navbar-btn:focus,
fieldset[disabled] .navbar-inverse .navbar-btn:active,
fieldset[disabled] .navbar-inverse .navbar-btn.active {
  background-color: #bdc3c7;
  border-color: #1abc9c;
}

.navbar-inverse .navbar-btn .badge {
  color: #1abc9c;
  background-color: white;
}

@media (min-width: 768px) {
  .navbar-embossed > .navbar-collapse {
    border-radius: 6px;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
  .navbar-embossed.navbar-inverse .navbar-nav .active > a,
  .navbar-embossed.navbar-inverse .navbar-nav .open > a {
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  }
}

.navbar-lg {
  min-height: 76px;
}

@media (min-width: 768px) {
  .navbar-lg .navbar-brand {
    line-height: 1;
    height: 76px;
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.navbar-lg .navbar-brand > [class*="fui-"] {
  font-size: 24px;
  line-height: 1;
}

.navbar-lg .navbar-nav > li > a {
  font-size: 15px;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .navbar-lg .navbar-nav > li > a {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.navbar-lg .navbar-toggler {
  height: 76px;
  line-height: 76px;
}

.navbar-lg .navbar-form {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}

.navbar-lg .navbar-text {
  padding-top: 26.5px;
  padding-bottom: 26.5px;
}

.navbar-lg .navbar-btn {
  margin-top: 17.5px;
  margin-bottom: 17.5px;
}

.navbar-lg .navbar-btn.btn-sm, .navbar-lg .btn-group-sm > .navbar-btn.btn {
  margin-top: 20.5px;
  margin-bottom: 20.5px;
}

.navbar-lg .navbar-btn.btn-xs, .navbar-lg .btn-group-xs > .navbar-btn.btn {
  margin-top: 25.5px;
  margin-bottom: 25.5px;
}

.iconbar {
  background-color: #2c3e50;
  border-radius: 6px;
  float: left;
  margin: 0 20px 20px 0;
  -webkit-backface-visibility: hidden;
}

.iconbar:before, .iconbar:after {
  content: " ";
  display: table;
}

.iconbar:after {
  clear: both;
}

.iconbar > ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.iconbar > ul > li {
  margin: 0;
  padding: 0;
}

.iconbar > ul > li.active > a {
  color: #1abc9c;
}

.iconbar > ul > li > a {
  color: white;
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  padding: 19px 30px;
  position: relative;
  text-decoration: none;
}

.iconbar > ul > li > a:hover, .iconbar > ul > li > a:focus {
  color: #1abc9c;
}

.iconbar > ul > li:first-child > a {
  padding-top: 29px;
}

.iconbar > ul > li:last-child > a {
  padding-bottom: 29px;
}

.iconbar .iconbar-unread {
  top: 9px;
  right: 21px;
}

.iconbar .iconbar-unread {
  background-color: #2a7aaf;
  border-radius: 50%;
  color: white;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.667;
  min-width: 20px;
  padding: 0 2px;
  position: absolute;
  text-align: center;
  -webkit-font-smoothing: auto;
}

[class*="iconbar-stick-"] {
  border-radius: 0;
  margin: 0;
  position: fixed;
  z-index: 1001;
}

[class*="iconbar-stick-"] > ul {
  margin: 0;
}

.iconbar-stick-left {
  bottom: 0;
  left: 0;
  top: 0;
}

.iconbar-success {
  background-color: #2ecc71;
}

.iconbar-success > ul > li.active > a {
  color: #1e874b;
}

.iconbar-success > ul > li > a:hover, .iconbar-success > ul > li > a:focus {
  color: #1e874b;
}

.iconbar-success .iconbar-unread {
  background-color: #1e874b;
}

.iconbar-danger {
  background-color: #e74c3c;
}

.iconbar-danger > ul > li.active > a {
  color: #983228;
}

.iconbar-danger > ul > li > a:hover, .iconbar-danger > ul > li > a:focus {
  color: #983228;
}

.iconbar-danger .iconbar-unread {
  background-color: #983228;
}

.iconbar-warning {
  background-color: #f1c40f;
}

.iconbar-warning > ul > li.active > a {
  color: #9f810a;
}

.iconbar-warning > ul > li > a:hover, .iconbar-warning > ul > li > a:focus {
  color: #9f810a;
}

.iconbar-warning .iconbar-unread {
  background-color: #9f810a;
}

.iconbar-info {
  background-color: #3498db;
}

.iconbar-info > ul > li.active > a {
  color: #226491;
}

.iconbar-info > ul > li > a:hover, .iconbar-info > ul > li > a:focus {
  color: #226491;
}

.iconbar-info .iconbar-unread {
  background-color: #226491;
}

.iconbar-horizontal {
  clear: both;
  overflow: auto;
  width: 100%;
}

.iconbar-horizontal > ul > li {
  display: inline;
  float: none;
}

.iconbar-horizontal .iconbar-unread {
  right: 23px;
  top: 13px;
}

.iconbar-horizontal > ul > li {
  float: left;
}

.iconbar-horizontal > ul > li > a {
  padding: 23px 19px;
}

.iconbar-horizontal > ul > li:first-child > a {
  padding: 23px 19px 23px 32px;
}

.iconbar-horizontal > ul > li:last-child > a {
  padding: 23px 32px 23px 19px;
}

.iconbar-horizontal > ul > li:last-child .iconbar-unread {
  right: 23px;
}

@media (min-width: 768px) {
  .iconbar-horizontal {
    width: auto;
    height: auto;
  }
}

.iconic-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.iconic-list > li > a {
  color: #526476;
  font-size: 14px;
  display: block;
  line-height: 24px;
  font-weight: 700;
}

.iconic-list > li > a:hover, .iconic-list > li > a:focus {
  color: #16a085;
}

.iconic-list > li > a > [class^="fui-"],
.iconic-list > li > a > [class*=" fui-"] {
  margin-right: 10px;
  font-size: 17px;
  vertical-align: middle;
}

.tile {
  background-color: #eff0f2;
  border-radius: 6px;
  padding: 14px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.tile .tile-hot-ribbon {
  display: block;
  position: absolute;
  right: -4px;
  top: -4px;
  width: 82px;
}

.tile p {
  font-size: 15px;
  margin-bottom: 33px;
}

.tile-image {
  height: 100px;
  margin: 31px 0 27px;
  vertical-align: bottom;
}

.tile-image.big-illustration {
  height: 111px;
  margin-top: 20px;
  width: 112px;
}

.tile-title {
  font-size: 20px;
  margin: 0;
}

.breadcrumb {
  background-color: #f3f4f5;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.55;
  padding: 9px 20px;
  border-radius: 6px;
}

.breadcrumb > li {
  position: relative;
  text-shadow: none;
}

.breadcrumb > li:after {
  color: #bdc3c7;
  content: "\e605";
  display: inline-block;
  font-family: 'Flat-UI-Pro-Icons';
  font-size: 9.75px;
  margin: -4px 9px 0 13px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb .active {
  color: #bdc3c7;
  cursor: default;
}

.breadcrumb .active:after {
  display: none;
}

.breadcrumb .active > a {
  color: inherit;
  cursor: inherit;
}

.breadcrumb > li + li:before {
  content: "";
  padding: 0;
}

.breadcrumb-text {
  color: #bdc3c7;
  margin-bottom: 20px;
}

.breadcrumb-text .caption {
  font-weight: 700;
  margin: 0 0 3px;
  font-size: 18px;
}

.breadcrumb-text .caption:after {
  content: "\e607";
  display: inline-block;
  font-family: 'Flat-UI-Pro-Icons';
  font-weight: normal;
  font-variant: normal;
  margin-left: 5px;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb-text ul {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  list-style: none;
  padding: 0;
}

.breadcrumb-text ul li {
  display: inline-block;
}

.breadcrumb-text ul li a:after {
  color: #bdc3c7;
  content: "/";
  font-weight: normal;
  margin: 0 4px 0 9px;
}

.popover {
  background-color: #edeff1;
  border: none;
  padding: 0;
  z-index: 1060;
  max-width: 276px;
  border-radius: 6px;
  box-shadow: none;
}

.popover.top {
  margin-top: -14px;
}

.popover.right {
  margin-left: 14px;
}

.popover.bottom {
  margin-top: 14px;
}

.popover.left {
  margin-left: -14px;
}

.popover-title {
  margin: 0;
  background-color: #bcc3ca;
  color: white;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 700;
  padding: 10px 20px 11px;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.popover-title:not(:empty) + .popover-content > p {
  margin-bottom: 0;
}

.popover-content {
  padding: 9px 20px;
  font-size: 14px;
  line-height: 1.286;
}

.popover-content p {
  font-size: inherit;
  line-height: inherit;
}

.popover > .arrow {
  border-width: 0;
  margin: 0;
}

.popover > .arrow, .popover > .arrow:after {
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover > .arrow:after {
  border-width: 9px;
  position: static;
}

.popover > .arrow:before {
  content: none;
}

.popover.top > .arrow {
  left: 50%;
  margin-left: -9px;
  border-top-color: transparent;
  bottom: 0;
}

.popover.top > .arrow:after {
  margin-left: -9px;
  border-top-color: #edeff1;
  border-bottom-width: 0;
}

.popover.right > .arrow {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-right-color: transparent;
}

.popover.right > .arrow:after {
  bottom: -9px;
  border-right-color: #edeff1;
  border-left-width: 0;
}

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -9px;
  border-bottom-color: transparent;
  top: -9px;
}

.popover.bottom > .arrow:after {
  margin-left: -9px;
  border-bottom-color: #edeff1;
  border-top-width: 0;
}

.popover.left > .arrow {
  top: 50%;
  right: 0;
  margin-top: -9px;
  border-left-color: transparent;
}

.popover.left > .arrow:after {
  border-left-color: #edeff1;
  bottom: -9px;
  border-right-width: 0;
}

.dialog {
  border-top-width: 2px;
  border-top-style: solid;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 18px 15px 20px;
  position: relative;
  text-align: center;
}

.dialog .btn {
  vertical-align: baseline;
  display: inline-block;
}

@media (max-width: 767px) {
  .dialog .btn {
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .dialog {
    padding-left: 0;
    padding-right: 0;
  }
  .dialog input, .dialog .btn {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dialog .form-group {
    margin-bottom: 10px;
  }
}

.dialog.dialog-default {
  background-color: #edeff1;
  border-top-color: #d6dbdf;
}

.dialog.dialog-success {
  background-color: #ecfaf2;
  border-top-color: #d5f5e3;
}

.dialog.dialog-danger {
  background-color: #fdefed;
  border-top-color: #fadbd8;
}

.dialog.dialog-warning {
  background-color: #fefae9;
  border-top-color: #fcf3cf;
}

.dialog.dialog-info {
  background-color: #edf6fc;
  border-top-color: #d6eaf8;
}

.dialog.dialog-inverse {
  background-color: #34495e;
  border-top-color: #2a3a4b;
  color: white;
}

.modal {
  z-index: 1050;
}

.modal-content {
  background-color: white;
  border: 2px solid #ebedef;
  border-radius: 7px;
  background-clip: border-box;
  box-shadow: none;
}

.modal-backdrop {
  background-color: #243342;
}

.modal-backdrop.in {
  opacity: 0.95;
  filter: "alpha(opacity=95)";
}

.modal-header {
  padding: 17px 19px 15px 24px;
  border-bottom: 2px solid #ebedef;
}

.modal-header .close {
  margin: 5px 0 0;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  color: #34495e;
}

.modal-title {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
}

.modal-body {
  padding: 20px 24px 20px;
}

.modal-body p {
  font-size: 16px;
  line-height: 1.625;
}

.modal-footer {
  padding: 19px 22px 20px;
  background-color: rgba(236, 240, 241, 0.5);
  border-top: none;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.modal-footer .btn + .btn {
  margin-left: 12px;
}

@media (max-width: 767px) {
  .modal-footer .btn {
    min-width: auto;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 624px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: none;
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.bootstrap-switch {
  font-size: 15px;
  line-height: 29px;
  display: inline-block;
  cursor: pointer;
  border-radius: 30px;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  width: 80px;
  height: 29px;
  -webkit-mask-box-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgODAgMjkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDgwIDI5IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik04MCwxNC41YzAsOC02LjUsMTQuNS0xNC41LDE0LjVoLTUxQzYuNSwyOSwwLDIyLjUsMCwxNC41bDAsMEMwLDYuNSw2LjUsMCwxNC41LDBoNTFDNzMuNSwwLDgwLDYuNSw4MCwxNC41TDgwLDE0LjV6Ii8+DQo8L3N2Zz4NCg==) 0 0 stretch;
  user-select: none;
}

.bootstrap-switch > div {
  display: inline-block;
  width: 132px;
  border-radius: 30px;
  transform: translate3d(0, 0, 0);
}

.bootstrap-switch > div > span {
  font-weight: 700;
  line-height: 19px;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
  z-index: 1;
  width: 66px;
  transition: box-shadow .25s ease-out;
}

.bootstrap-switch > div > span > [class^="fui-"] {
  text-indent: 0;
}

.bootstrap-switch > div > label {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  font-size: 0;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 200;
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
  position: absolute !important;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: "alpha(opacity=0)";
}

.bootstrap-switch-handle-on {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-default {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #bdc3c7";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-default:before {
  border-color: #bdc3c7;
  background-color: #7f8c9a;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-primary {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #34495e";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-primary:before {
  border-color: #34495e;
  background-color: #1abc9c;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-success {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #2ecc71";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-success:before {
  border-color: #2ecc71;
  background-color: white;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-warning {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #f1c40f";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-warning:before {
  border-color: #f1c40f;
  background-color: white;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-info {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #3498db";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-info:before {
  border-color: #3498db;
  background-color: white;
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-danger {
  box-shadow: "inset 0 0 transparent, -16px 0 0 #e74c3c";
}

.bootstrap-switch-off .bootstrap-switch-handle-on ~ .bootstrap-switch-handle-off.bootstrap-switch-danger:before {
  border-color: #e74c3c;
  background-color: white;
}

.bootstrap-switch-handle-off {
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.bootstrap-switch-handle-off:before {
  display: inline-block;
  content: " ";
  border: 4px solid transparent;
  border-radius: 50%;
  text-align: center;
  vertical-align: top;
  padding: 0;
  height: 29px;
  width: 29px;
  position: absolute;
  top: 0;
  left: 51px;
  z-index: 100;
  background-clip: padding-box;
  transition: border-color .25s ease-out, background-color .25s ease-out;
}

.bootstrap-switch-animate > div {
  transition: margin-left .25s ease-out;
}

.bootstrap-switch-on > div {
  margin-left: 0;
}

.bootstrap-switch-off > div {
  margin-left: -51px;
}

.bootstrap-switch-disabled,
.bootstrap-switch-readonly {
  opacity: 0.5;
  filter: "alpha(opacity=50)";
  cursor: default;
}

.bootstrap-switch-disabled > div > span,
.bootstrap-switch-disabled > div > label,
.bootstrap-switch-readonly > div > span,
.bootstrap-switch-readonly > div > label {
  cursor: default !important;
}

.bootstrap-switch-focused {
  outline: 0;
}

.bootstrap-switch-default {
  color: white;
  background-color: #bdc3c7;
}

.bootstrap-switch-default ~ .bootstrap-switch-handle-off:before {
  background-color: #7f8c9a;
  border-color: #bdc3c7;
}

.bootstrap-switch-on .bootstrap-switch-default ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #bdc3c7;
}

.bootstrap-switch-primary {
  color: #1abc9c;
  background-color: #34495e;
}

.bootstrap-switch-primary ~ .bootstrap-switch-handle-off:before {
  background-color: #1abc9c;
  border-color: #34495e;
}

.bootstrap-switch-on .bootstrap-switch-primary ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #34495e;
}

.bootstrap-switch-info {
  color: white;
  background-color: #3498db;
}

.bootstrap-switch-info ~ .bootstrap-switch-handle-off:before {
  background-color: white;
  border-color: #3498db;
}

.bootstrap-switch-on .bootstrap-switch-info ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #3498db;
}

.bootstrap-switch-success {
  color: white;
  background-color: #2ecc71;
}

.bootstrap-switch-success ~ .bootstrap-switch-handle-off:before {
  background-color: white;
  border-color: #2ecc71;
}

.bootstrap-switch-on .bootstrap-switch-success ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #2ecc71;
}

.bootstrap-switch-warning {
  color: white;
  background-color: #f1c40f;
}

.bootstrap-switch-warning ~ .bootstrap-switch-handle-off:before {
  background-color: white;
  border-color: #f1c40f;
}

.bootstrap-switch-on .bootstrap-switch-warning ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #f1c40f;
}

.bootstrap-switch-danger {
  color: white;
  background-color: #e74c3c;
}

.bootstrap-switch-danger ~ .bootstrap-switch-handle-off:before {
  background-color: white;
  border-color: #e74c3c;
}

.bootstrap-switch-on .bootstrap-switch-danger ~ .bootstrap-switch-handle-off {
  box-shadow: inset 16px 0 0 #e74c3c;
}

.bootstrap-switch-square .bootstrap-switch {
  -webkit-mask-box-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgODAgMjkiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDgwIDI5IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik04MCwyNWMwLDIuMi0xLjgsNC00LDRINGMtMi4yLDAtNC0xLjgtNC00VjRjMC0yLjIsMS44LTQsNC00aDcyYzIuMiwwLDQsMS44LDQsNFYyNXoiLz4NCjwvc3ZnPg0K) 0 0 stretch;
  border-radius: 4px;
}

.bootstrap-switch-square .bootstrap-switch > div {
  border-radius: 4px;
}

.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-on {
  text-indent: -15px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 15px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.bootstrap-switch-square .bootstrap-switch .bootstrap-switch-handle-off:before {
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.bootstrap-switch-square .bootstrap-switch-off .bootstrap-switch-handle-off:before {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.carousel-inner {
  border-radius: 6px;
}

.carousel-inner > .carousel-item img {
  max-width: 100%;
}

.carousel-control {
  position: absolute;
  top: 45%;
  font-size: 20px;
  line-height: 35px;
  color: white;
  text-indent: 3px;
  border: 3px solid white;
  height: 40px;
  right: 15px;
  margin-top: -20px;
  text-align: center;
  width: 40px;
  outline: none !important;
  border-radius: 23px;
  opacity: 0.5;
  filter: "alpha(opacity=50)";
}

.carousel-control.left, .carousel-control.right {
  background: none #2c3e50;
}

.carousel-control.left {
  text-indent: -3px;
  left: 15px;
}

.carousel-control.right {
  left: auto;
  right: 15px;
}

.carousel-indicators {
  bottom: auto;
  left: auto;
  margin: 0;
  right: 16px;
  top: 16px;
  width: auto;
  line-height: 8px;
  user-select: none;
}

.carousel-indicators li {
  vertical-align: top;
  border: none;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
  background-color: #2c3e50;
  background-color: rgba(44, 62, 80, 0.5);
}

.carousel-indicators li.active {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
}

.carousel-caption {
  padding: 20px 19px 22px;
  background: #243342;
  background: rgba(36, 51, 66, 0.7);
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  border-radius: 0 0 6px 6px;
  text-shadow: none;
}

.carousel-caption h3,
.carousel-caption p {
  color: white;
}

.carousel-caption h3 {
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 5px;
}

.carousel-caption p {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .carousel-caption {
    position: static;
  }
}

.video-js * {
  font-family: "Flat-UI-Pro-Icons";
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-button > .vjs-icon-placeholder:before, .video-js .vjs-modal-dialog, .vjs-modal-dialog .vjs-modal-dialog-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-button > .vjs-icon-placeholder:before {
  text-align: center;
}

.vjs-icon-play:before {
  content: "\e616";
  color: #1abc9c;
  font-size: 16px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before {
  content: "\e616";
  color: #1abc9c;
  font-size: 16px;
  line-height: 47px;
}

.vjs-icon-play-circle:before {
  content: "\f102";
}

.vjs-icon-pause:before, .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  content: "\e615";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-volume-mute:before, .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  content: "\e618";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-volume-low:before, .video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
  content: "\f105";
}

.vjs-icon-volume-mid:before, .video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
  content: "\f106";
}

.vjs-icon-volume-high:before, .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  content: "\e617";
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\e619";
  color: #475d72;
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-fullscreen-exit:before, .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\e619";
  color: #475d72;
  line-height: 47px;
  font-size: 16px;
}

.vjs-icon-square:before {
  content: "\f10a";
}

.vjs-icon-spinner:before {
  content: "\f10b";
}

.vjs-icon-subtitles:before {
  content: "\f10c";
}

.vjs-icon-captions:before {
  content: "\f10d";
}

.video-js .vjs-captions-button .vjs-icon-placeholder:before, .video-js:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder:before, .video-js:lang(fr-CA) .vjs-subs-caps-button .vjs-icon-placeholder:before {
  content: "\f10d";
}

.vjs-icon-chapters:before, .video-js .vjs-chapters-button .vjs-icon-placeholder:before {
  content: "\f10e";
}

.vjs-icon-share:before {
  content: "\f10f";
}

.vjs-icon-cog:before {
  content: "\f110";
}

.vjs-icon-circle:before {
  content: "\f111";
}

.video-js .vjs-play-progress:before, .video-js .vjs-volume-level:before {
  content: "";
  background-color: #16a085;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.vjs-icon-circle-outline:before {
  content: "\f112";
}

.vjs-icon-circle-inner-circle:before {
  content: "\f113";
}

.vjs-icon-hd:before {
  content: "\f114";
}

.vjs-icon-cancel:before, .video-js .vjs-control.vjs-close-button .vjs-icon-placeholder:before {
  content: "\f115";
}

.vjs-icon-replay:before, .video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
  content: "\f116";
}

.vjs-icon-facebook:before {
  content: "\f117";
}

.vjs-icon-gplus:before {
  content: "\f118";
}

.vjs-icon-linkedin:before {
  content: "\f119";
}

.vjs-icon-twitter:before {
  content: "\f11a";
}

.vjs-icon-tumblr:before {
  content: "\f11b";
}

.vjs-icon-pinterest:before {
  content: "\f11c";
}

.vjs-icon-audio-description:before, .video-js .vjs-descriptions-button .vjs-icon-placeholder:before {
  content: "\f11d";
}

.vjs-icon-audio:before {
  content: "\f11e";
}

.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.video-js .vjs-audio-button .vjs-icon-placeholder:before {
  content: "\f11e";
}

.video-js:-moz-full-screen {
  position: absolute;
}

.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}

.video-js[tabindex="-1"] {
  outline: none;
}

.video-js * {
  box-sizing: inherit;
}

.video-js *:before, .video-js *:after {
  box-sizing: inherit;
}

.video-js ul {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  list-style-position: outside;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
  width: 100%;
  max-width: 100%;
  height: 0;
}

.video-js.vjs-16-9 {
  padding-top: 56.25%;
}

.video-js.vjs-4-3 {
  padding-top: 75%;
}

.video-js.vjs-fill {
  width: 100%;
  height: 100%;
}

.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.vjs-full-window {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

.vjs-full-window .video-js.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.video-js.vjs-fullscreen {
  width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
}

.video-js.vjs-fullscreen.vjs-user-inactive {
  cursor: none;
}

.vjs-hidden {
  display: none !important;
}

.vjs-disabled {
  opacity: 0.5;
  cursor: default;
}

.video-js .vjs-offscreen {
  height: 1px;
  left: -9999px;
  position: absolute;
  top: 0;
  width: 1px;
}

.vjs-lock-showing {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.vjs-no-js {
  padding: 20px;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 300px;
  height: 150px;
  margin: 0px auto;
}

.vjs-no-js a {
  color: #66A8CC;
}

.vjs-no-js a:visited {
  color: #66A8CC;
}

.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -1.5em;
}

.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
  border-color: #fff;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  transition: all 0s;
}

.vjs-controls-disabled .vjs-big-play-button, .vjs-has-started .vjs-big-play-button, .vjs-using-native-controls .vjs-big-play-button, .vjs-error .vjs-big-play-button {
  display: none;
}

.vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause .vjs-big-play-button {
  display: block;
}

.video-js button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  overflow: visible;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  appearance: none;
}

.vjs-control .vjs-button {
  width: 100%;
  height: 100%;
}

.video-js .vjs-control.vjs-close-button {
  cursor: pointer;
  height: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
  z-index: 2;
}

.video-js .vjs-modal-dialog {
  background: rgba(0, 0, 0, 0.8);
  background: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
  overflow: auto;
  box-sizing: content-box;
}

.video-js .vjs-modal-dialog > * {
  box-sizing: border-box;
}

.vjs-modal-dialog .vjs-modal-dialog-content {
  font-size: 1.2em;
  line-height: 1.5;
  padding: 20px 24px;
  z-index: 1;
}

.vjs-menu-button {
  cursor: pointer;
}

.vjs-menu-button.vjs-disabled {
  cursor: default;
}

.vjs-workinghover .vjs-menu-button.vjs-disabled:hover .vjs-menu {
  display: none;
}

.vjs-menu .vjs-menu-content {
  display: block;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
  box-sizing: content-box;
}

.vjs-menu .vjs-menu-content > * {
  box-sizing: border-box;
}

.vjs-scrubbing .vjs-menu-button:hover .vjs-menu {
  display: none;
}

.vjs-menu li {
  list-style: none;
  margin: 0;
  padding: 0.2em 0;
  line-height: 1.4em;
  font-size: 1.2em;
  text-align: center;
  text-transform: lowercase;
}

.vjs-menu li.vjs-menu-item:focus, .vjs-menu li.vjs-menu-item:hover {
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
}

.vjs-menu li.vjs-selected {
  background-color: #fff;
  color: #2B333F;
}

.vjs-menu li.vjs-selected:focus, .vjs-menu li.vjs-selected:hover {
  background-color: #fff;
  color: #2B333F;
}

.vjs-menu li.vjs-menu-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 2em;
  padding: 0;
  margin: 0 0 0.3em 0;
  font-weight: bold;
  cursor: default;
}

.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0em;
  margin-bottom: 1.5em;
  border-top-color: rgba(43, 51, 63, 0.7);
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  width: 100%;
  bottom: 1.5em;
  max-height: 15em;
}

.vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu, .vjs-menu-button-popup .vjs-menu.vjs-lock-showing {
  display: block;
}

.video-js .vjs-menu-button-inline {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
}

.video-js .vjs-menu-button-inline:before {
  width: 2.222222222em;
}

.video-js .vjs-menu-button-inline:hover, .video-js .vjs-menu-button-inline:focus, .video-js .vjs-menu-button-inline.vjs-slider-active {
  width: 12em;
}

.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 12em;
}

.vjs-menu-button-inline .vjs-menu {
  opacity: 0;
  height: 100%;
  width: auto;
  position: absolute;
  left: 4em;
  top: 0;
  padding: 0;
  margin: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.vjs-menu-button-inline:hover .vjs-menu, .vjs-menu-button-inline:focus .vjs-menu, .vjs-menu-button-inline.vjs-slider-active .vjs-menu {
  display: block;
  opacity: 1;
}

.vjs-no-flex .vjs-menu-button-inline .vjs-menu {
  display: block;
  opacity: 1;
  position: relative;
  width: auto;
}

.vjs-no-flex .vjs-menu-button-inline:hover .vjs-menu, .vjs-no-flex .vjs-menu-button-inline:focus .vjs-menu, .vjs-no-flex .vjs-menu-button-inline.vjs-slider-active .vjs-menu {
  width: auto;
}

.vjs-menu-button-inline .vjs-menu-content {
  width: auto;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.video-js .vjs-control-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 47px;
  color: #ffffff;
  background: #2c3e50;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.vjs-has-started .vjs-control-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.1s, opacity 0.1s;
  -moz-transition: visibility 0.1s, opacity 0.1s;
  -ms-transition: visibility 0.1s, opacity 0.1s;
  -o-transition: visibility 0.1s, opacity 0.1s;
  transition: visibility 0.1s, opacity 0.1s;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  visibility: visible;
  opacity: 0;
  -webkit-transition: visibility 1s, opacity 1s;
  -moz-transition: visibility 1s, opacity 1s;
  -ms-transition: visibility 1s, opacity 1s;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
}

.vjs-controls-disabled .vjs-control-bar, .vjs-using-native-controls .vjs-control-bar, .vjs-error .vjs-control-bar {
  display: none !important;
}

.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible;
}

.vjs-has-started.vjs-no-flex .vjs-control-bar {
  display: table;
}

.video-js .vjs-control {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 18px;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 1.8em;
  line-height: 1.67;
}

.video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus {
  text-shadow: 0em 0em 1em white;
}

.video-js .vjs-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.vjs-no-flex .vjs-control {
  display: table-cell;
  vertical-align: middle;
}

.video-js .vjs-custom-control-spacer {
  display: none;
}

.video-js .vjs-progress-control {
  cursor: pointer;
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 4em;
}

.vjs-live .vjs-progress-control {
  display: none;
}

.vjs-no-flex .vjs-progress-control {
  width: auto;
}

.video-js .vjs-progress-holder {
  flex: auto;
  transition: all 0.2s;
  height: 12px;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
  left: 0;
  top: 0;
}

.video-js .vjs-progress-holder .vjs-load-progress {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
  left: 0;
  top: 0;
}

.video-js .vjs-progress-holder .vjs-load-progress div {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
  left: 0;
  top: 0;
}

.video-js .vjs-play-progress {
  background: #1abc9c;
  border-radius: 32px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.video-js .vjs-play-progress:before {
  font-size: 0.9em;
  position: absolute;
  top: -0.333333333333333em;
  z-index: 1;
}

.video-js .vjs-load-progress {
  background: #d6dbdf;
  border-radius: 32px;
}

.video-js .vjs-load-progress div {
  background: #d6dbdf;
  border-radius: 32px;
}

.video-js .vjs-time-tooltip {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.3em;
  color: #000;
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  padding: 6px 8px 8px 8px;
  pointer-events: none;
  position: relative;
  top: -3.4em;
  visibility: hidden;
  z-index: 1;
}

.video-js .vjs-progress-holder:focus .vjs-time-tooltip {
  display: none;
}

.video-js .vjs-progress-control:hover .vjs-time-tooltip, .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
  display: block;
  font-size: 0.6em;
  visibility: visible;
}

.video-js .vjs-progress-control .vjs-mouse-display {
  display: none;
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #000;
  z-index: 1;
}

.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
  z-index: 0;
}

.video-js .vjs-progress-control:hover .vjs-mouse-display {
  display: block;
}

.video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 1s, opacity 1s;
  -moz-transition: visibility 1s, opacity 1s;
  -ms-transition: visibility 1s, opacity 1s;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
}

.video-js.vjs-user-inactive.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
  display: none;
}

.vjs-mouse-display .vjs-time-tooltip {
  color: #fff;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
}

.video-js .vjs-slider {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 0.45em 0 0.45em;
  user-select: none;
  background-color: #425669;
  border-radius: 32px;
}

.video-js .vjs-slider:focus {
  text-shadow: 0em 0em 1em white;
  box-shadow: 0 0 1em #fff;
}

.video-js .vjs-mute-control {
  cursor: pointer;
  flex: none;
  height: 47px;
  color: #475d72;
}

.video-js .vjs-volume-control {
  cursor: pointer;
  margin-right: 1em;
  display: flex;
}

.video-js .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
}

.video-js .vjs-volume-panel .vjs-volume-control {
  display: none;
}

.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.video-js .vjs-volume-panel {
  transition: width 1s;
}

.video-js .vjs-volume-panel:hover .vjs-volume-control, .video-js .vjs-volume-panel:active .vjs-volume-control, .video-js .vjs-volume-panel:focus .vjs-volume-control {
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
}

.video-js .vjs-volume-panel .vjs-volume-control:hover, .video-js .vjs-volume-panel .vjs-volume-control:active, .video-js .vjs-volume-panel .vjs-volume-control:focus {
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
}

.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control, .video-js .vjs-volume-panel .vjs-mute-control:active ~ .vjs-volume-control, .video-js .vjs-volume-panel .vjs-mute-control:focus ~ .vjs-volume-control {
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active {
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
}

.video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
  height: 47px;
}

.video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control:focus.vjs-volume-horizontal {
  width: 5em;
  height: 47px;
}

.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-mute-control:active ~ .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-mute-control:focus ~ .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
  height: 47px;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
  width: 5em;
  height: 47px;
}

.video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-vertical, .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical, .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-vertical, .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical, .video-js .vjs-volume-panel .vjs-volume-control:focus.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-vertical, .video-js .vjs-volume-panel .vjs-mute-control:active ~ .vjs-volume-control.vjs-volume-vertical, .video-js .vjs-volume-panel .vjs-mute-control:focus ~ .vjs-volume-control.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control:focus.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-volume-control:focus.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-mute-control:active ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-mute-control:active ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-mute-control:focus ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-mute-control:focus ~ .vjs-volume-control.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-vertical .vjs-volume-bar, .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-vertical .vjs-volume-level {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  height: 8em;
  width: 3em;
  left: -3.5em;
  -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
}

.video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
  height: 3em;
  visibility: visible;
  opacity: 1;
  position: relative;
  transition: none;
}

.video-js.vjs-no-flex .vjs-volume-control.vjs-volume-vertical, .video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  position: absolute;
  bottom: 3em;
  left: 0.5em;
}

.video-js .vjs-volume-panel {
  display: flex;
  order: 2;
}

.video-js .vjs-volume-bar {
  margin: 1.35em 0.45em;
}

.vjs-volume-bar.vjs-slider-horizontal {
  width: 5em;
  height: 0.3em;
  position: absolute;
  top: 50%;
  margin: 0;
  transform: translatey(-50%);
}

.vjs-volume-bar.vjs-slider-vertical {
  width: 0.3em;
  height: 5em;
  margin: 1.35em auto;
}

.video-js .vjs-volume-level {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.video-js .vjs-volume-level:before {
  position: absolute;
  font-size: 0.9em;
}

.vjs-slider-vertical .vjs-volume-level {
  width: 0.3em;
}

.vjs-slider-vertical .vjs-volume-level:before {
  top: -0.5em;
  left: -0.3em;
}

.vjs-slider-horizontal .vjs-volume-level {
  height: 0.3em;
}

.vjs-slider-horizontal .vjs-volume-level:before {
  top: 50%;
  right: -0.5em;
  transform: translatey(-50%);
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: 4em;
}

.vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
  height: 100%;
}

.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
  width: 100%;
}

.video-js .vjs-volume-vertical {
  width: 3em;
  height: 8em;
  bottom: 8em;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
}

.video-js .vjs-volume-horizontal .vjs-menu {
  left: -2em;
}

.vjs-poster {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

.vjs-poster img {
  display: block;
  vertical-align: middle;
  margin: 0 auto;
  max-height: 100%;
  padding: 0;
  width: 100%;
}

.vjs-has-started .vjs-poster {
  display: none;
}

.vjs-audio.vjs-has-started .vjs-poster {
  display: block;
}

.vjs-using-native-controls .vjs-poster {
  display: none;
}

.video-js .vjs-live-control {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-size: 1em;
  line-height: 3em;
}

.vjs-no-flex .vjs-live-control {
  display: table-cell;
  width: auto;
  text-align: left;
}

.video-js .vjs-time-control {
  flex: none;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;
  order: 1;
}

.video-js .vjs-time-control * {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 47px;
}

.vjs-live .vjs-time-control, .video-js .vjs-remaining-time {
  display: none;
}

.vjs-no-flex .vjs-current-time {
  display: none;
}

.vjs-no-flex .vjs-remaining-time.vjs-time-control.vjs-control {
  width: 0px !important;
  white-space: nowrap;
}

.video-js .vjs-time-divider, .video-js .vjs-duration {
  color: #5d6d7e;
}

.video-js .vjs-duration {
  margin-right: 20px;
}

.vjs-live .vjs-time-divider {
  display: none;
}

.video-js .vjs-play-control {
  display: block;
  height: 47px;
  width: 58px;
}

.video-js .vjs-play-control .vjs-icon-placeholder {
  cursor: pointer;
  flex: none;
}

.vjs-text-track-display {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
}

.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 1em;
}

.video-js .vjs-text-track {
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.vjs-subtitles {
  color: #fff;
}

.vjs-captions {
  color: #fc6;
}

.vjs-tt-cue {
  display: block;
}

video::-webkit-media-text-track-display {
  transform: translateY(-3em);
}

.video-js.vjs-user-inactive.vjs-playing video::-webkit-media-text-track-display {
  transform: translateY(-1.5em);
}

.video-js .vjs-fullscreen-control {
  cursor: pointer;
  flex: none;
  order: 2;
  width: 48px;
}

.vjs-playback-rate > .vjs-menu-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vjs-playback-rate .vjs-playback-rate-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: 1.5em;
  line-height: 2;
  text-align: center;
}

.vjs-playback-rate .vjs-menu {
  width: 4em;
  left: 0em;
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
  font-size: 1.4em;
  text-align: center;
}

.vjs-error .vjs-error-display:before {
  color: #fff;
  content: 'X';
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4em;
  left: 0;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  top: 50%;
  vertical-align: middle;
  width: 100%;
}

.vjs-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid rgba(43, 51, 63, 0.7);
  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.vjs-seeking .vjs-loading-spinner, .vjs-waiting .vjs-loading-spinner {
  display: block;
}

.vjs-loading-spinner:before, .vjs-loading-spinner:after {
  content: "";
  position: absolute;
  margin: -6px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 1;
  border: inherit;
  border-color: transparent;
  border-top-color: white;
}

.vjs-seeking .vjs-loading-spinner:before, .vjs-seeking .vjs-loading-spinner:after {
  -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
  animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
}

.vjs-waiting .vjs-loading-spinner:before, .vjs-waiting .vjs-loading-spinner:after {
  -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
  animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
}

.vjs-seeking .vjs-loading-spinner:before, .vjs-waiting .vjs-loading-spinner:before {
  border-top-color: white;
}

.vjs-seeking .vjs-loading-spinner:after, .vjs-waiting .vjs-loading-spinner:after {
  border-top-color: white;
  -webkit-animation-delay: 0.44s;
  animation-delay: 0.44s;
}

@keyframes vjs-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes vjs-spinner-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes vjs-spinner-fade {
  0% {
    border-top-color: #73859f;
  }
  20% {
    border-top-color: #73859f;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: #73859f;
  }
  100% {
    border-top-color: #73859f;
  }
}

@-webkit-keyframes vjs-spinner-fade {
  0% {
    border-top-color: #73859f;
  }
  20% {
    border-top-color: #73859f;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: #73859f;
  }
  100% {
    border-top-color: #73859f;
  }
}

.vjs-chapters-button .vjs-menu ul {
  width: 24em;
}

.video-js .vjs-subs-caps-button + .vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
  position: absolute;
}

.video-js .vjs-subs-caps-button + .vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder:before {
  content: "\f10d";
  font-size: 1.5em;
  line-height: inherit;
}

.video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-custom-control-spacer {
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.video-js.vjs-layout-tiny:not(.vjs-fullscreen).vjs-no-flex .vjs-custom-control-spacer {
  width: auto;
}

.video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-current-time, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-time-divider, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-duration, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-remaining-time, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-playback-rate, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-progress-control, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-mute-control, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-volume-control, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-chapters-button, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-descriptions-button, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-captions-button, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-subtitles-button, .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-audio-button {
  display: none;
}

.video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-current-time, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-time-divider, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-duration, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-remaining-time, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-playback-rate, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-mute-control, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-volume-control, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-chapters-button, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-descriptions-button, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-captions-button, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-subtitles-button, .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-audio-button {
  display: none;
}

.video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-current-time, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-time-divider, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-duration, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-remaining-time, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-playback-rate, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-mute-control, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-volume-control, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-chapters-button, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-descriptions-button, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-captions-button, .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-subtitles-button .vjs-audio-button {
  display: none;
}

.vjs-modal-dialog.vjs-text-track-settings {
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.75);
  color: #fff;
  height: 70%;
}

.vjs-text-track-settings .vjs-modal-dialog-content {
  display: table;
}

.vjs-text-track-settings .vjs-track-settings-colors, .vjs-text-track-settings .vjs-track-settings-font {
  display: table-cell;
}

.vjs-text-track-settings .vjs-track-settings-controls {
  display: table-cell;
  text-align: right;
  vertical-align: bottom;
}

.vjs-text-track-settings fieldset {
  margin: 5px;
  padding: 3px;
  border: none;
}

.vjs-text-track-settings fieldset span {
  display: inline-block;
  margin-left: 5px;
}

.vjs-text-track-settings legend {
  color: #fff;
  margin: 0 0 5px 0;
}

.vjs-text-track-settings .vjs-label {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.vjs-track-settings-controls button {
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff 88%, #73859f 100%);
  color: #2B333F;
  cursor: pointer;
  border-radius: 2px;
}

.vjs-track-settings-controls button:focus, .vjs-track-settings-controls button:active {
  outline-style: solid;
  outline-width: medium;
  background-image: linear-gradient(0deg, #fff 88%, #73859f 100%);
}

.vjs-track-settings-controls button:hover {
  color: rgba(43, 51, 63, 0.75);
}

.vjs-track-settings-controls .vjs-default-button {
  margin-right: 1em;
}

@media print {
  .video-js > *:not(.vjs-tech):not(.vjs-poster) {
    visibility: hidden;
  }
}

@media \0screen {
  .vjs-user-inactive.vjs-playing .vjs-control-bar :before {
    content: "";
  }
}

@media \0screen {
  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    visibility: hidden;
  }
}

.panel {
  margin-bottom: 30px;
  background-color: white;
  border: 2px solid transparent;
  border-radius: 6px;
  box-shadow: none;
}

.panel-heading {
  border-bottom: 2px solid transparent;
  border-top-radius: 3px;
}

.panel-title {
  font-size: 24px;
  line-height: 1.292;
  font-weight: normal;
}

.panel-footer {
  background-color: #bdc3c7;
  border-top: 2px solid #bdc3c7;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .list-group .list-group-item {
  border-width: 2px 0;
}

.panel > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-radius: 3px;
}

.panel > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
  border-top: 2px solid #eef0f0;
}

.panel-group {
  margin-bottom: 30px;
}

.panel-group .panel {
  border-radius: 6px;
}

.panel-group .panel + .panel {
  margin-top: 8px;
}

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 2px solid #bdc3c7;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 2px solid #bdc3c7;
}

.panel-default {
  border-color: #bdc3c7;
}

.panel-default > .panel-heading {
  color: white;
  background-color: #bdc3c7;
  border-color: #bdc3c7;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bdc3c7;
}

.panel-default > .panel-heading .badge {
  color: #bdc3c7;
  background-color: white;
}

.panel-default > .panel-footer {
  color: white;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bdc3c7;
}

.panel-primary {
  border-color: #34495e;
}

.panel-primary > .panel-heading {
  color: white;
  background-color: #34495e;
  border-color: #34495e;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #34495e;
}

.panel-primary > .panel-heading .badge {
  color: #34495e;
  background-color: white;
}

.panel-primary > .panel-footer {
  color: white;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #34495e;
}

.panel-success {
  border-color: #e2ebe9;
}

.panel-success > .panel-heading {
  color: #16a085;
  background-color: #eff8f3;
  border-color: #e2ebe9;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e2ebe9;
}

.panel-success > .panel-heading .badge {
  color: #eff8f3;
  background-color: #16a085;
}

.panel-success > .panel-footer {
  color: #16a085;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e2ebe9;
}

.panel-info {
  border-color: #e9ecef;
}

.panel-info > .panel-heading {
  color: #3498db;
  background-color: #f7f9fa;
  border-color: #e9ecef;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e9ecef;
}

.panel-info > .panel-heading .badge {
  color: #f7f9fa;
  background-color: #3498db;
}

.panel-info > .panel-footer {
  color: #3498db;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e9ecef;
}

.panel-warning {
  border-color: #ebece8;
}

.panel-warning > .panel-heading {
  color: #f1c40f;
  background-color: #f9f8f3;
  border-color: #ebece8;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebece8;
}

.panel-warning > .panel-heading .badge {
  color: #f9f8f3;
  background-color: #f1c40f;
}

.panel-warning > .panel-footer {
  color: #f1c40f;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebece8;
}

.panel-danger {
  border-color: #ebe9eb;
}

.panel-danger > .panel-heading {
  color: #e74c3c;
  background-color: #f9f6f5;
  border-color: #ebe9eb;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebe9eb;
}

.panel-danger > .panel-heading .badge {
  color: #f9f6f5;
  background-color: #e74c3c;
}

.panel-danger > .panel-footer {
  color: #e74c3c;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebe9eb;
}

.todo {
  color: #798795;
  margin-bottom: 20px;
  border-radius: 6px;
}

.todo ul {
  background-color: #2c3e50;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 0 0 6px 6px;
}

.todo li {
  background: #34495e;
  background-size: 20px 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.214;
  margin-top: 2px;
  padding: 18px 42px 21px 25px;
  position: relative;
  transition: .25s;
}

.todo li:first-child {
  margin-top: 0;
}

.todo li:last-child {
  border-radius: 0 0 6px 6px;
  padding-bottom: 21px;
}

.todo li.todo-done {
  background: transparent;
  color: #1abc9c;
}

.todo li.todo-done .todo-name {
  color: #1abc9c;
}

.todo li:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 22px;
  margin-top: -10px;
  background: white;
  border-radius: 50%;
}

.todo li.todo-done:after {
  content: "\e60a";
  font-family: 'Flat-UI-Pro-Icons';
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1abc9c;
  color: #2c3e50;
}

.todo-search {
  position: relative;
  background: #1abc9c;
  background-size: 16px 16px;
  border-radius: 6px 6px 0 0;
  color: #34495e;
  padding: 19px 25px 20px;
}

.todo-search:before {
  position: absolute;
  font-family: 'Flat-UI-Pro-Icons';
  content: "\e630";
  font-size: 16px;
  line-height: 17px;
  display: inline-block;
  top: 50%;
  left: 92%;
  margin: -0.5em 0 0 -1em;
}

input.todo-search-field {
  background: none;
  border: none;
  color: #34495e;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  line-height: 23px;
  padding: 5px 0;
  text-indent: 0;
  box-shadow: none;
  outline: none;
}

input.todo-search-field::-moz-placeholder {
  color: #34495e;
  opacity: 1;
}

input.todo-search-field:-ms-input-placeholder {
  color: #34495e;
}

input.todo-search-field::-webkit-input-placeholder {
  color: #34495e;
}

.todo-icon {
  float: left;
  font-size: 24px;
  padding: 11px 22px 0 0;
}

.todo-content {
  padding-top: 1px;
  overflow: hidden;
}

.todo-name {
  color: white;
  font-size: 17px;
  margin: 1px 0 3px;
}

.dataTables_length label {
  font-weight: normal;
  float: left;
  text-align: left;
}

.dataTables_length select {
  width: 75px;
  margin-right: 5px;
  margin-left: 5px;
}

.dataTables_filter label {
  font-weight: normal;
  float: right;
}

.dataTables_filter input {
  width: 16em;
  margin-left: 15px;
}

.dataTables_info {
  padding-top: 8px;
}

.dataTables_paginate {
  float: right;
  margin: 0;
}

.dataTables_paginate ul li {
  margin-right: 0;
}

.dataTable {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
  max-width: none !important;
}

.dataTable td,
.dataTable th {
  position: relative;
  box-sizing: content-box;
}

.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  padding-right: 21px;
}

.dataTable thead .sorting:after, .dataTable thead .sorting:before,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_asc:before,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_desc:before,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_asc_disabled:before,
.dataTable thead .sorting_desc_disabled:after,
.dataTable thead .sorting_desc_disabled:before {
  content: " ";
  display: block;
  position: absolute;
  right: 8px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

@media (min-width: 768px) {
  .dataTable thead .sorting,
  .dataTable thead .sorting_asc,
  .dataTable thead .sorting_desc,
  .dataTable thead .sorting_asc_disabled,
  .dataTable thead .sorting_desc_disabled {
    padding-right: 30px;
  }
  .dataTable thead .sorting:after, .dataTable thead .sorting:before,
  .dataTable thead .sorting_asc:after,
  .dataTable thead .sorting_asc:before,
  .dataTable thead .sorting_desc:after,
  .dataTable thead .sorting_desc:before,
  .dataTable thead .sorting_asc_disabled:after,
  .dataTable thead .sorting_asc_disabled:before,
  .dataTable thead .sorting_desc_disabled:after,
  .dataTable thead .sorting_desc_disabled:before {
    right: 18px;
  }
}

.dataTable thead .sorting:before,
.dataTable thead .sorting_asc:before,
.dataTable thead .sorting_asc_disabled:before {
  top: 10px;
}

.dataTable thead .sorting:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_desc_disabled:after {
  bottom: 10px;
}

.dataTable thead .sorting:before {
  border-bottom: 5px solid #d5dbdb;
}

.dataTable thead .sorting:after {
  border-top: 5px solid #d5dbdb;
}

.dataTable thead .sorting_asc:before {
  border-bottom: 5px solid #aab7b8;
}

.dataTable thead .sorting_asc_disabled:before {
  border-bottom: 5px solid #d5dbdb;
}

.dataTable thead .sorting_desc:after {
  border-top: 5px solid #aab7b8;
}

.dataTable thead .sorting_desc_disabled:after {
  border-top: 5px solid #d5dbdb;
}

.dataTable th:active {
  outline: none;
}

.dataTables_scrollHead table {
  margin-bottom: 0 !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dataTables_scrollHead table thead tr:last-child th:first-child,
.dataTables_scrollHead table thead tr:last-child td:first-child {
  border-bottom-right-radius: "0 !important";
  border-bottom-left-radius: "0 !important";
}

.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.dataTables_scrollBody table tbody tr:last-child th,
.dataTables_scrollBody table tbody tr:last-child td {
  border-top: none;
}

.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

/* Frustratingly the border-collapse:collapse used by Bootstrap makes the column
   width calculations when using scrolling impossible to align columns. We have
   to use separate
 */
.table-bordered tr th {
  border-bottom-width: 0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-left-width: 0;
  border-top-width: 0;
}

.table-bordered tbody th,
.table-bordered tbody td {
  border-left-width: 0;
  border-bottom-width: 0;
}

.dataTables_scrollHead .table-bordered {
  border-bottom-width: 0;
}

.table tbody tr.active td,
.table tbody tr.active th {
  background-color: #34495e;
  color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #2f4255 !important;
}

.table tbody tr.active a {
  color: white;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #34495e;
}

.DTTT_selectable tbody tr {
  cursor: pointer;
}

.DTTT .btn {
  font-size: 12px;
}

.DTTT .btn:hover {
  text-decoration: none !important;
}

.DTTT_dropdown.dropdown-menu, .DTTT_dropdown.select2-drop {
  z-index: 2003;
}

.DTTT_dropdown.dropdown-menu li, .DTTT_dropdown.select2-drop li {
  position: relative;
}

.DTTT_collection_background {
  z-index: 2002;
}

.DTTT_print_info.modal {
  height: 150px;
  margin-top: -75px;
  text-align: center;
}

.DTTT_print_info h6 {
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  margin: 1em;
}

.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -5px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}

.FixedHeader_Cloned table {
  margin: 0 !important;
}

@media (max-width: 767px) {
  .dataTables_paginate ul.pagination, .dataTables_paginate ul.dataTables_paginate {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .dataTables_paginate ul.pagination .paginate_button.previous, .dataTables_paginate ul.dataTables_paginate .paginate_button.previous, .dataTables_paginate ul.pagination .paginate_button.next, .dataTables_paginate ul.dataTables_paginate .paginate_button.next {
    display: none;
  }
}

@media (max-width: 480px) {
  .dataTables_paginate {
    width: 100%;
  }
}

.last-col {
  overflow: hidden;
}

.ptn, .pvn, .pan {
  padding-top: 0 !important;
}

.ptx, .pvx, .pax {
  padding-top: 3px !important;
}

.pts, .pvs, .pas {
  padding-top: 5px !important;
}

.ptm, .pvm, .pam {
  padding-top: 10px !important;
}

.ptl, .pvl, .pal {
  padding-top: 20px !important;
}

.pth, .pvh, .pah {
  padding-top: 40px !important;
}

.prn, .phn, .pan {
  padding-right: 0 !important;
}

.prx, .phx, .pax {
  padding-right: 3px !important;
}

.prs, .phs, .pas {
  padding-right: 5px !important;
}

.prm, .phm, .pam {
  padding-right: 10px !important;
}

.prl, .phl, .pal {
  padding-right: 20px !important;
}

.prh, .phh, .pah {
  padding-right: 40px !important;
}

.pbn, .pvn, .pan {
  padding-bottom: 0 !important;
}

.pbx, .pvx, .pax {
  padding-bottom: 3px !important;
}

.pbs, .pvs, .pas {
  padding-bottom: 5px !important;
}

.pbm, .pvm, .pam {
  padding-bottom: 10px !important;
}

.pbl, .pvl, .pal {
  padding-bottom: 20px !important;
}

.pbh, .pvh, .pah {
  padding-bottom: 40px !important;
}

.pln, .phn, .pan {
  padding-left: 0 !important;
}

.plx, .phx, .pax {
  padding-left: 3px !important;
}

.pls, .phs, .pas {
  padding-left: 5px !important;
}

.plm, .phm, .pam {
  padding-left: 10px !important;
}

.pll, .phl, .pal {
  padding-left: 20px !important;
}

.plh, .phh, .pah {
  padding-left: 40px !important;
}

.mtn, .mvn, .man {
  margin-top: 0 !important;
}

.mtx, .mvx, .max {
  margin-top: 3px !important;
}

.mts, .mvs, .mas {
  margin-top: 5px !important;
}

.mtm, .mvm, .mam {
  margin-top: 10px !important;
}

.mtl, .mvl, .mal {
  margin-top: 20px !important;
}

.mth, .mvh, .mah {
  margin-top: 40px !important;
}

.mrn, .mhn, .man {
  margin-right: 0 !important;
}

.mrx, .mhx, .max {
  margin-right: 3px !important;
}

.mrs, .mhs, .mas {
  margin-right: 5px !important;
}

.mrm, .mhm, .mam {
  margin-right: 10px !important;
}

.mrl, .mhl, .mal {
  margin-right: 20px !important;
}

.mrh, .mhh, .mah {
  margin-right: 40px !important;
}

.mbn, .mvn, .man {
  margin-bottom: 0 !important;
}

.mbx, .mvx, .max {
  margin-bottom: 3px !important;
}

.mbs, .mvs, .mas {
  margin-bottom: 5px !important;
}

.mbm, .mvm, .mam {
  margin-bottom: 10px !important;
}

.mbl, .mvl, .mal {
  margin-bottom: 20px !important;
}

.mbh, .mvh, .mah {
  margin-bottom: 40px !important;
}

.mln, .mhn, .man {
  margin-left: 0 !important;
}

.mlx, .mhx, .max {
  margin-left: 3px !important;
}

.mls, .mhs, .mas {
  margin-left: 5px !important;
}

.mlm, .mhm, .mam {
  margin-left: 10px !important;
}

.mll, .mhl, .mal {
  margin-left: 20px !important;
}

.mlh, .mhh, .mah {
  margin-left: 40px !important;
}

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  .btn {
    border-style: solid;
    border-width: 2px;
  }
  .dropdown-menu, .select2-drop,
  .ui-datepicker,
  .ui-timepicker-wrapper,
  .tt-dropdown-menu {
    background: #fff !important;
    border: 2px solid #ddd;
  }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    display: none;
  }
  .input-group-rounded .input-group-btn + .form-control, .input-group-rounded .select2-search .input-group-btn + input[type="text"], .select2-search .input-group-rounded .input-group-btn + input[type="text"],
  .input-group-rounded .input-group-btn + .select2-search input[type="text"] {
    padding-left: 10px;
  }
  .form-control, .select2-search input[type="text"] {
    border: 2px solid #ddd !important;
  }
  .popover, .nav-pills {
    border: 2px solid #ddd;
  }
  .popover .popover-title {
    border-bottom: 2px solid #ddd;
  }
  .bootstrap-switch {
    height: 33px;
    width: 84px;
    border: 2px solid #bdc3c7;
  }
  .ui-spinner-button, .tooltip {
    border: 2px solid #bdc3c7;
  }
  .carousel-caption {
    background: rgba(255, 255, 255, 0.8) !important;
  }
  .progress, .ui-slider, .ui-slider {
    background: #ddd !important;
  }
  .progress-bar, .ui-slider-range, .ui-slider-handle {
    background: #bdc3c7 !important;
  }
  .tile a:after {
    content: "";
  }
}
