//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  background-color: $breadcrubms-bg;
  font-size: $component-font-size-base;
  font-weight: 700;
  line-height: 1.55;
  padding: 9px 20px;
  border-radius: 6px;

  > li {
    position: relative;
    text-shadow: none;

    &:after {
      color: $breadcrubms-text-color;
      content: "\e605";
      display: inline-block;
      font-family: 'Flat-UI-Pro-Icons';
      font-size: ($component-font-size-base * 0.65);
      margin: -4px 9px 0 13px;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .active {
    color: $breadcrubms-text-color;
    cursor: default;

    &:after {
      display: none;
    }
    > a {
      color: inherit;
      cursor: inherit;
    }
  }
  > li + li:before {
    content: "";
    padding: 0;
  }
}

// Plain text breadcrumb
.breadcrumb-text {
  color: $breadcrubms-text-color;
  margin-bottom: 20px;

  .caption {
    font-weight: 700;
    margin: 0 0 3px;
    font-size: ceil(($component-font-size-base * 1.2)); // ~18px

    &:after {
      content: "\e607";
      display: inline-block;
      font-family: 'Flat-UI-Pro-Icons';
      font-weight: normal;
      font-variant: normal;
      margin-left: 5px;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  ul {
    font-size: ceil(($component-font-size-base * 1.6)); // ~24px
    font-weight: 700;
    margin-bottom: 20px;
    list-style: none;
    padding: 0;

    li {
      display: inline-block;

      a {
        &:after {
          color: $breadcrubms-text-color;
          content: "/";
          font-weight: normal;
          margin: 0 4px 0 9px;
        }
      }
    }
  }
}
