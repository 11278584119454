// Datepicker
// --------------------------------------------------

.ui-datepicker {
  width: 264px;
  background-color: $datepicker-bg;
  display: none;
  margin: 10px 0 0 0;
  border: 7px solid $datepicker-border;
  border-radius: $datepicker-border-radius;

  // Navigation pad
  .ui-datepicker-header {
    background-color: $datepicker-header-bg;
    color: $datepicker-nav-color;
    cursor: default;
    margin: -7px -6px 2px;
    position: relative;
    border-radius: $datepicker-border-radius $datepicker-border-radius 0 0;
  }
  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
    top: 0;
    width: 52px;
    height: 44px;
    color: $datepicker-nav-color;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: mix($datepicker-main, white, 80%);
    }
    &:active {
      background-color: mix($datepicker-main, black, 85%);
    }
    &.ui-state-disabled {
      background-color: transparent;
      color: rgba($datepicker-nav-color, .75);
      cursor: default;
      @include opacity(.7);
    }
  }
  .ui-datepicker-prev {
    left: 0;
    border-right: 2px solid mix(white, $datepicker-main, 30%);
    border-radius: $datepicker-border-radius 0 0 0;
  }
  .ui-datepicker-next {
    right: 0;
    border-left: 2px solid mix(white, $datepicker-main, 30%);
    border-radius: 0 $datepicker-border-radius 0 0;
  }
  .ui-datepicker-title {
    margin: 0 52px;
    line-height: 44px;
    text-align: center;
    font-size: $component-font-size-base;
  }
  .ui-datepicker-prev span,
  .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -6px;
    text-indent: -9999px;
    width: 0;
    height: 0;
    content: "";
  }
  .ui-icon-circle-triangle-w {
    border: solid transparent;
    border-width: 7px 12px 7px 0;
    border-right-color: $datepicker-nav-color;
  }
  .ui-icon-circle-triangle-e {
    border: solid transparent;
    border-width: 7px 0 7px 12px;
    border-left-color: $datepicker-nav-color;
  }

  // General styling
  table {
    font-size: ceil(($component-font-size-base * 0.933)); // ~14px
    margin: 0 0 3px;
    width: 100%;
  }
  th {
    min-width: 34px;
    height: 30px;
    text-align: center;
    font-weight: bold;
    border: 0;
    padding: 1px;
    vertical-align: middle;
  }
  td {
    border: 0;
    padding: 1px;
    text-align: center;
    vertical-align: middle;

    span,
    a {
      display: block;
      text-decoration: none;
      color: $datepicker-color;
      height: 30px;
      line-height: 30px;
      min-width: 34px;
    }
    a {
      border-radius: $datepicker-border-radius;

      &:hover {
        background-color: mix($gray-light, white, 60%);
        color: $datepicker-color;
      }
    }
  }
  .ui-state-active {
    background-color: $datepicker-main;
    color: $datepicker-nav-color;
  }
  .ui-datepicker-other-month a {
    color: mix($datepicker-color, white, 30%);
  }

  // Aditional elements
  select {
    font-size: 1em;
    margin: 1px 0;
  }
  .ui-datepicker-month-year {
    width: 100%;
  }
  .ui-datepicker-month,
  .ui-datepicker-year {
    width: 40%;
    margin-right: 5%;
    margin-left: 5%;
    background: none;
    border:none;
    option {
      color: $brand-primary;
    }
  }
  .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible;
  }
   .ui-datepicker-buttonpane .ui-datepicker-current {
    float: left;
  }
  &.ui-datepicker-multi {
    width: auto;
  }
}

// Multiple calendars
.ui-datepicker-multi {
  .ui-datepicker-group {
    float: left;
  }
  .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em;
  }
  .ui-datepicker-group-last,
  .ui-datepicker-group-middle {
    .ui-datepicker-header {
      border-left-width: 0;
    }
  }
  .ui-datepicker-buttonpane {
    clear: left;
  }
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

//remove shadow on mobile devices
.hasDatepicker {
  -webkit-appearance: none;
}
